import React from "react";

const ContactUsBlockMobile = () => {
  return (
    <>
      <div className="shadow-xl grid grid-cols-1 rounded-xl -mt-40 bg-white">
        <div className=" bg-green-50 rounded-l-xl relative pb-20 px-2">
          <div className="pt-4 px-2">
            <p className="text-lg md:text-xl font-bold text-center">
              Contact Information
            </p>
            <p className="pt-2 font-medium text-sm md:text-base text-gray-700 md:text-center">
              Fill up the form and our team will get back to you shortly.
            </p>
          </div>
          <div className="flex flex-col gap-5 mt-10">
            <div className="ml-4 flex flex-row gap-3">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="text-primary w-4 h-4 mb-3 inline-block"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </span>
              <span className="font-medium text-xs md:text-sm">
                +91 78729 69664, +91 85973 55169
              </span>
            </div>
            <div className="ml-4 flex flex-row gap-3">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="text-primary w-4 h-4 mb-3 inline-block"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                  />
                </svg>
              </span>
              <span className="font-medium text-xs md:text-sm">
                kripa@bytesberry.com
              </span>
            </div>
            <div className="ml-4 flex flex-row gap-3">
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="text-primary w-4 h-4 mb-3 inline-block"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                  />
                </svg>
              </span>
              <span className="font-medium text-xs md:text-sm">
                Basnett Building, D.P.H Road, Gangtok, Sikkim.
              </span>
            </div>
          </div>
          <div className="block md:hidden absolute right-0 bottom-0">
            <svg
              width="150"
              height="120"
              viewBox="0 0 610 529"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_5)">
                <ellipse
                  cx="596.5"
                  cy="512"
                  rx="271.5"
                  ry="223"
                  fill="#E75124"
                />
                <circle
                  cx="425"
                  cy="337"
                  r="100"
                  fill="#252744"
                  fill-opacity="0.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_5">
                  <rect width="610" height="529" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className="hidden md:block absolute right-0 bottom-0">
            <svg
              width="330"
              height="270"
              viewBox="0 0 610 529"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_1_5)">
                <ellipse
                  cx="596.5"
                  cy="512"
                  rx="271.5"
                  ry="223"
                  fill="#E75124"
                />
                <circle
                  cx="425"
                  cy="337"
                  r="100"
                  fill="#252744"
                  fill-opacity="0.95"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_5">
                  <rect width="610" height="529" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        {/* Contact Form */}
        <div className="bg-white">
          <div className="pt-10 px-4">
            <form
              action="https://formspree.io/f/mzbqdzwv"
              method="POST"
              autocomplete="off"
              className="p-0 flex flex-col justify-center"
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <div className="flex flex-col">
                    <label for="firstname" className="text-sm font-semibold">
                      First Name
                    </label>
                    <input
                      type="name"
                      name="fname"
                      id="fname"
                      className="w-full py-1 px-3 bg-white  border-b border-gray-400 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <label for="secondname" className="text-sm font-semibold">
                      Last Name
                    </label>
                    <input
                      type="name"
                      name="lname"
                      id="lname"
                      className="w-full py-1 px-3  bg-white  border-b border-gray-400 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>
                </div>
              </div>

              <div className="grid grod-cols-1 md:grid-cols-2 gap-6 mt-8">
                <div>
                  <div className="flex flex-col">
                    <label for="email" className="text-sm font-semibold">
                      Mail
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="w-full py-1 px-3 bg-white border-b border-gray-400  text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>
                </div>
                <div>
                  <div className="flex flex-col">
                    <label for="tel" className="text-sm font-semibold">
                      Phone
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      id="tel"
                      className="w-full py-1 px-3  bg-white border-b border-gray-400 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>
                </div>
              </div>

              <div class="flex flex-col mt-2">
                <label for="message" className="text-sm font-semibold mt-8">
                  Message
                </label>
                <textarea
                  //rows={10}
                  type="text"
                  name="message"
                  id="message"
                  className="w-100 resize-none py-1 px-3 bg-white border-b border-gray-400 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                />
              </div>
              <div className="flex justify-end mt-8">
                <button
                  type="submit"
                  className="mb-10 md:mb-16 lg:mb-0  bg-[#252744] bg-opacity-90 text-white text-sm font-semibold py-2 px-4 mr-1 rounded-lg mt-3 hover:bg-opacity-95 transition ease-in-out duration-300"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsBlockMobile;

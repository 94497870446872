import React from "react";
import { NavLink } from "react-router-dom";
import MegaMenuCardServices from "../navbarReusableComponents/MegaMenuCardServices";
import "./ourservices.css";

import { AiOutlineMobile } from "react-icons/ai";
import { AiOutlineDesktop } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineRobot } from "react-icons/ai";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { RiUserVoiceLine } from "react-icons/ri";

import { Link } from "react-router-dom";

const OurServices = ({ isNavBarTransparent }) => {
  return (
    <section>
      <div
        className={`text-gray-700 w-full mx-auto p-10 mt-6 rounded-lg bg-white shadow-md ${
          isNavBarTransparent
            ? "scale-95 transition-all ease-in-out duration-"
            : "scale-100 transition-all ease-in-out duration-500"
        }
                         
             `}
      >
        <p className="uppercase text-sm md:text-base text-gray-500 font-medium pb-2 border-b border-[#E75124] mb-8">
          Key Services
        </p>

        <div className="grid grid-cols-3">
          <Link to="/our-services#service1">
            <MegaMenuCardServices
              id="1"
              title="Websites / Web Apps / Portals"
              description="Static | Dynamic | Portals | Responsive Design | CMS | SEO"
              icon={<AiOutlineDesktop size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service2">
            <MegaMenuCardServices
              id="2"
              title="In-Premise Applications"
              description="Windows | Mac | Linux | Offline apps"
              icon={<AiOutlineGlobal size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service3">
            <MegaMenuCardServices
              id="3"
              title="Mobile Solutions"
              description="Native | Hybrid | Andriod | IOS | Cross
                                    Platform"
              icon={<AiOutlineMobile size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service4">
            <MegaMenuCardServices
              id="4"
              title="e-Governance Solutions"
              description="Resource Planning | Records Digitization | Decision support system | Single window"
              icon={<HiOutlineBuildingLibrary size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service5">
            <MegaMenuCardServices
              id="5"
              title="e-Commerce Solutions"
              description="Product Catalog | Shopping Cart | Payment Gateway |
Order Management | Analytics & Reporting"
              icon={<AiOutlineShoppingCart size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service6">
            <MegaMenuCardServices
              id="6"
              title="Software Consultancy"
              description="UI/UX | Data Visualization | Database |
                                    Technology Adoption | IT Infrastructure"
              icon={<RiUserVoiceLine size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service7">
            <MegaMenuCardServices
              id="7"
              title="Search Engine Optimization (SEO) Consultancy"
              description="Keyword Research | On-Page Optimization | Technical SEO | Link Building | Content Marketing"
              icon={<AiOutlineSearch size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service8">
            <MegaMenuCardServices
              id="8"
              title="Chatbots"
              description="Messenger bots | Realtime & Group chat | NLP
                                    | AI | Website Chatbots"
              icon={<AiOutlineRobot size={30} color={"#E75124"} />}
            />
          </Link>

          <Link to="/our-services#service9">
            <MegaMenuCardServices
              id="9"
              title="Soft Skills Training"
              description="Communication | Time management | Interpersonal Skills | Teamwork | People management etc."
              icon={
                <HiOutlinePresentationChartBar size={30} color={"#E75124"} />
              }
            />
          </Link>

          <Link to="/our-services">
            <MegaMenuCardServices title="See all services" description="" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OurServices;

import React, { useState, useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import mitra_app from "../Assets/mitra_app.png";
import googleplay from "../Assets/googleplay.png";
import mitraBanner from "../Assets/milk.jpg";
import mitra_Banner from "../Assets/MitraWebBanner.PNG";
import login from "../Assets/login_mitraApp.png";
import billing from "../Assets/billing_mitraApp.png";
import orders from "../Assets/orders_mitraApp.png";

import appImage from "../Assets/app-imag-1.png";
import appImage2 from "../Assets/app-imag-4.png";
import appImage4 from "../Assets/app-imag-2.png";
import appImage3 from "../Assets/app-imag-5.png";

import GenericModal from "../reusable-components/modal/GenericModal";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

export default function ProjectSikkimMilkMitraApp() {
  const bannerSmuMitraPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSmuMitraPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSmuMitraPage?.current?.offsetHeight);
    }
  }, [bannerSmuMitraPage?.current?.offsetHeight]);

  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState("");
  return (
    <>
      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        isAdd={true}
        isLarge={true}
      >
        <div className="my-10 ">
          {clickedImage === "mitra_app_image1" ? (
            <div
              className="flex  flex-col justify-center items-center "
              onClick={() => setShowModal(false)}
            >
              <div className="flex justify-center w-full  gap-4 my-3">
                <p className="text-slate-100 text-center font-medium ">
                  Sikkim Milk Mitra App Login
                </p>
                <p
                  className=" cursor-pointer text-white   border border-white px-1.5 py-0.5 text-xs  rounded-full   "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={appImage}
                alt="login"
                className=" w-auto h-full object-cover pt-6"
              />
            </div>
          ) : null}

          {clickedImage === "mitra_app_image2" ? (
            <div
              className="flex  flex-col justify-center items-center "
              onClick={() => setShowModal(false)}
            >
              <div className="flex justify-center w-full gap-4  my-3 ">
                <p className="text-white text-center">
                  Sikkim Milk Mitra App - Billing
                </p>
                <p
                  className="cursor-pointer text-white   border border-white px-1.5 py-0.5 text-xs  rounded-full  "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={appImage2} alt="billing" className="h-full pt-6" />
            </div>
          ) : null}

          {clickedImage === "mitra_app_image3" ? (
            <div
              className="flex  flex-col justify-center items-center"
              onClick={() => setShowModal(false)}
            >
              <div className="flex justify-center w-full gap-4  my-3 ">
                <p className="text-white text-center">
                  Sikkim Milk Mitra App - Orders
                </p>
                <p
                  className=" cursor-pointer text-white   border border-white px-1.5 py-0.5 text-xs  rounded-full  "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={appImage3} alt="orders" className="h-full pt-6" />
            </div>
          ) : null}

          {clickedImage === "mitra_app_image4" ? (
            <div
              className="flex  flex-col justify-center items-center"
              onClick={() => setShowModal(false)}
            >
              <div className="flex justify-center w-full gap-4  my-3">
                <p className="text-white text-center">
                  Sikkim Milk Mitra App - Registration
                </p>
                <p
                  className=" cursor-pointer text-white   border border-white px-1.5 py-0.5 text-xs  rounded-full  "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={appImage4} alt="orders" className="h-full pt-6" />
            </div>
          ) : null}
        </div>
      </GenericModal>

      <div className="relative" ref={bannerSmuMitraPage}>
        <div className="bg-black opacity-80 h-72 lg:h-[500px] w-full object-cover absolute "></div>
        <img
          src={mitraBanner}
          alt="mitraBanner"
          className="h-72 lg:h-[500px] w-full object-cover"
        />
        <p className="absolute top-1/2 -translate-y-2/4 left-10 text-white">
          <Heading>Sikkim Milk Union</Heading>
          <SubHeading>Mitra App</SubHeading>
        </p>
        <div>
          <span className="px-4  py-1 md:px-12  absolute bottom-[30%] translate-y-1/2 right-[50%] translate-x-1/2  lg:bottom-10 lg:right-[20%] cursor-pointer ">
            <a
              href="https://play.google.com/store/apps/details?id=com.pdmsapp"
              target="_blank"
            >
              <img
                src={googleplay}
                alt="googleplay"
                className="h-8 rounded-lg  "
              />
            </a>
          </span>
        </div>
      </div>
      <div className="flex justify-end">
        <a
          href="/our-projects"
          className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
        >
          View All Projects
        </a>
      </div>

      <div className="w-[75%] mx-auto  ">
        <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
          Sikkim Milk Union Mitra App
        </Heading>

        <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
          <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
            <p className="leading-relaxed text-gray-700">
              An Internet-Based Product Distribution Management solution (Web
              Portal + Mobile App) encompassing all aspects of the organization
              related to Plant operation, Distribution & Marketing. The solution
              combination of a web application typically accessed using a
              desktop browser and a mobile app.
            </p>
          </div>
          <div className="flex flex-col">
            <div className="relative flex justify-end shadow-2xl">
              <img
                src={mitra_Banner}
                className="rounded-md h-full w-auto "
                alt="mitra_app_image"
              />
              <a
                href="https://play.google.com/store/search?q=sikkim+milk+mitra&c=apps"
                target="_blank"
                className="absolute bottom-4 right-6 bg-[#E75124] text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
              >
                Visit App
              </a>
            </div>
          </div>
        </div>

        <SubHeading classes={` pt-5 mb-5 text-gray-800`}>Features</SubHeading>

        <p className="pt-4 text-justify leading-relaxed  md:text-justify text-base 2xl:text-[22px] my-8 mx-4 font-medium text-gray-700">
          An Internet-Based Product Distribution Management solution (Web Portal
          + Mobile App) encompassing all aspects of the organization related to
          Plant operation, Distribution & Marketing. The solution is a
          combination of a web application typically accessed using a desktop
          browser and a mobile app.
          <p className="pt-6 text-base 2xl:text-[22px] font-medium text-gray-800">
            The app will have features such as:
          </p>
        </p>
        <ul className=" list-disc pl-12 text-sm 2xl:text-[20px] text-justify text-gray-700 ">
          <li className="leading-7">
            <span className="font-semibold text-sm 2xl:text-[20px] text-gray-800">
              Online Ordering :
            </span>{" "}
            All Sikkim Milk Union registered agents can order products on a
            daily basis as per their requirement.
          </li>
          <li className="pt-4 leading-7">
            <span className="font-semibold text-sm 2xl:text-[20px] text-gray-800">
              Order history :{" "}
            </span>{" "}
            Agents can view details regarding all the orders they have placed
          </li>
          <li className="pt-4 leading-7">
            <span className="font-semibold text-sm 2xl:text-[20px] text-gray-800">
              Online Payment :{" "}
            </span>{" "}
            Agents after placing the order for Sikkim Milk Products will have a
            provision to make payment online through a dedicated payment
            gateway.
          </li>
          <li className="pt-4 leading-7">
            <span className="font-semibold text-sm 2xl:text-[20px] text-gray-800">
              Wallet Option :{" "}
            </span>
            All registered users now have access to a wallet option, wherein
            refunds or reimbursements from the Sikkim Milk Union will be
            credited, and the wallet's balance will automatically be utilized
            when they place their subsequent orders.
          </li>
          {/* <li className="pt-4">
            <span className="font-semibold text-sm 2xl:text-[20px] text-gray-800">
              Notification
            </span>{" "}
            Pages will be notified regarding orders made (to be decided)
          </li> */}
        </ul>

        <p className="font-medium pt-6 italic text-base 2xl:text-[20px] text-gray-800">
          Images for Sikkim Milk Mitra App
        </p>

        <section>
          <div className="my-5 flex flex-col md:flex-row items-center justify-evenly pt-5 ">
            <div
              className=" flex flex-col gap-4 cursor-pointer  "
              onClick={() => {
                setShowModal(true);
                setClickedImage("mitra_app_image1");
              }}
            >
              <img
                src={appImage}
                alt="login"
                className="w-[300px]  rounded-lg md:h-60 md:w-auto shadow-xl"
              />
              <span className="text-xs italic text-center ">
                Sikkim Milk Mitra App Login
              </span>
            </div>

            <div
              className="flex flex-col gap-4 cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setClickedImage("mitra_app_image2");
              }}
            >
              <img
                src={appImage2}
                alt="billing"
                className="w-[300px] rounded-lg md:h-60 md:w-auto shadow-xl"
              />
              <span className="text-xs italic text-center ">
                Sikkim Milk Mitra App - Billing
              </span>
            </div>
            <div
              className="flex flex-col gap-4 cursor-pointer my-3"
              onClick={() => {
                setShowModal(true);
                setClickedImage("mitra_app_image3");
              }}
            >
              <img
                src={appImage3}
                alt="orders"
                className="w-[300px]  rounded-lg md:h-60 md:w-auto shadow-xl"
              />
              <span className="text-xs italic text-center ">
                Sikkim Milk Mitra App - orders
              </span>
            </div>

            <div
              className="flex flex-col gap-4 cursor-pointer my-4  "
              onClick={() => {
                setShowModal(true);
                setClickedImage("mitra_app_image4");
              }}
            >
              <img
                src={appImage4}
                alt="orders"
                className="w-[300px]  rounded-lg md:h-60 md:w-[150px] shadow-xl"
              />
              <span className="text-xs italic text-center ">
                Sikkim Milk Mitra App - Registration
              </span>
            </div>
          </div>
        </section>

        <SubHeading classes={`  mb-5 text-gray-800]`}>
          Technology Used
        </SubHeading>

        <div className="flex flex-row my-10">
          <>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
            >
              React Native
            </div>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
            >
              Node
            </div>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white "
            >
              Postgres
            </div>
          </>
        </div>
      </div>
    </>
  );
}

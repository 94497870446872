import React from "react";

const SectorTab = ({
  sectorName,
  sectorID,
  setActiveSectorTab,
  activeSectorTab,
}) => {
  const onSectorClickHandler = (id) => {
    setActiveSectorTab(id);
  };

  return (
    <>
      <div
        className={`font-medium cursor-pointer py-1 text-center text-[10px] lg:text-sm xl:text-lg rounded-md ${
          activeSectorTab === sectorID
            ? "bg-[#252744] text-white"
            : "bg-[#D9D9D9]"
        }`}
        onClick={() => onSectorClickHandler(sectorID)}
      >
        {sectorName}
      </div>
    </>
  );
};

export default SectorTab;
//px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44

import React, { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AiOutlineClose } from "react-icons/ai";

export default function GenericModal(props) {
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-30 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={() => {
          props?.setOpen(false);
          props?.clearData(false);
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-100"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-100"
          >
            <div
              className={`inline-block align-bottom  bg-gray-500 bg-opacity-0 rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle ${
                props.isLarge ? "md:max-w-3xl" : "md:max-w-lg"
              } w-full`}
            >
              <div className="bg-white-300 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex justify-center items-center">
                  {/* {props.isAdd && (
                    <div
                      className="mx-auto flex-shrink-0 flex items-center justify-center 
                    h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10 cursor-pointer "
                    >
                      <AiOutlineClose />
                    </div>
                  )} */}
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium pl-3 text-white"
                  >
                    {props.title}
                  </Dialog.Title>
                </div>
                <div className="mt-2">{props.children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React from "react";
import SubHeading from "../reusable-components/fonts/SubHeading";
import moment from "moment";
import comming_soon from "../Assets/comming_soon.jpg";

// const { convert } = require("html-to-text");

export default function BlogPage({ blogData, setIsDetailsActive, data }) {
  // console.log("data", data);

  const parse = require("html-react-parser");
  const MoreBlogData = data.filter(
    (moreblogObj) => moreblogObj?.blog_id !== blogData?.blog_id
  );

  // console.log(MoreBlogData.length);

  // console.log("MoreBlogData", MoreBlogData);
  return (
    <div className="mx-auto lg:mx-12">
      <div className="grid lg:grid-cols-3">
        <div className="grid col-span-2 px-8 ">
          <div className="flex justify-center">
            <img
              className="h-96 object-cover flex   "
              src={blogData?.blog_img}
              alt="Blog Images"
            />
          </div>

          <p className="text-sm py-2.5 text-center">
            {moment(blogData.created_date).format("MMM Do, YYYY")}
          </p>
          <div className="text-center">
            <SubHeading classes={`flex flex-col pb-2`}>
              {blogData?.blog_title}
            </SubHeading>

            <p className="text-gray-700 text-opacity-90 text-sm 2xl:text-base  text-justify py-2">
              {parse(`${blogData?.blog_description}`)}
            </p>

            <button
              className="text-blue-600 bg-white rounded pt-6 font-semibold py-4 "
              onClick={() => setIsDetailsActive(false)}
            >
              &lt; Back to Blogs
            </button>
          </div>
        </div>
        <div className="hidden lg:block pb-4">
          {MoreBlogData?.length == 0 ? (
            <>
              <p>More Blogs Arriving Soon...</p>

              <img
                src={comming_soon}
                alt="comming_soon"
                className="object-cover w-full h-auto"
              />
            </>
          ) : (
            <SubHeading classes={`mb-7 underline`}>More Blogs</SubHeading>
          )}

          <Card MoreBlogData={MoreBlogData} />
        </div>
      </div>
    </div>
  );
}

export const Card = ({ MoreBlogData }) => {
  return (
    <section className=" cursor-pointer ">
      {MoreBlogData.map((moreBlog) => (
        <div className="flex  gap-4 mb-14  ">
          <img
            src={moreBlog?.blog_img}
            alt="Blog Card Images"
            className="w-[33%] h-32 object-cover"
          />
          <div className="flex flex-col text-sm my-3 ">
            <p className="font-medium">{moreBlog?.blog_title}</p>

            <div className="flex justify-start py-3 ">
              <span>
                {moment(moreBlog.created_date).format("MMM Do, YYYY")}
              </span>
              <p className="px-4 text-4xl align-middle relative bottom-5">.</p>
              <p>{moreBlog?.blog_timetoread} mins read</p>
            </div>
            <p>{moreBlog?.blog_shortdescription}</p>
          </div>
        </div>
      ))}
    </section>
  );
};

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import FadeInOnScroll from "../reusable-components/animations/FadeInOnScroll";

// import servicephoto from "../../src/Assets/sample_service.png";

const ServicesCardMobile = ({ title, description, serviceImage }) => {
  return (
    <FadeInOnScroll>
      <div className="mx-10 pb-20 flex flex-col items-center">
        <div>
          <img
            src={serviceImage}
            alt="Service photo"
            className="h-72 md:w-full object-fill rounded-md"
          />
        </div>
        <div className="flex flex-col justify-center items-center mt-6">
          <div className="pb-4">
            <p className="text-xl font-medium text-gray-800">{title}</p>
          </div>
          <div>
            <p className="text-sm font-light text-gray-700 text-justify">
              {description}
            </p>
          </div>
        </div>
      </div>
    </FadeInOnScroll>
  );
};

export default ServicesCardMobile;

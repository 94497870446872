import React, { useEffect, useState } from "react";

import { Blurhash } from "react-blurhash";

import { projectsSectorsArr } from "../utilities/constants.js";

// import "./projectcard.css";

const ProjectsCard = ({
  projectImage,
  projectImageBlurHashString,
  projectSectors,
  projectType,
  projectTitle,
  projectBrief,
  projectUrl,
  newTab,
}) => {
  const [cardSectorName, setCardSectorName] = useState([]); //This state variable will add sector names inside the sector tags in the card
  let shortenedProjectBrief = projectBrief.slice(0, 100);

  useEffect(() => {
    {
      let sectorArrList = [];
      let filteredSector = projectsSectorsArr.filter((filterObj) => {
        let sectorMatched = projectSectors.includes(filterObj.id);
        return sectorMatched;
      });

      filteredSector.map((sectorObj) => {
        sectorArrList.push(sectorObj.sectorName);
      });

      setCardSectorName(sectorArrList);
    }
  }, [projectSectors]);

  return (
    <>
      <div className="shadow-lg h-full">
        <div className="flex flex-col justify-between">
          <div className=" max-h-96">
            {/* Image */}
            <div className="mb-2 2xl:mb-3 h-44 relative">
              <div className="absolute top-0 bottom-0 left-0 right-0">
                <Blurhash
                  hash={projectImageBlurHashString}
                  width={`100%`}
                  height={176}
                />
              </div>
              <div className="w-full z-20 absolute">
                <img src={projectImage} className="h-44 w-full object-fill" />
              </div>
            </div>

            {/* Description */}
            <div className="p-6">
              <div className="mt-2 flex flex-row justify-start items-center">
                {cardSectorName
                  ? cardSectorName.map((sectorObj) => (
                      <span className="bg-primary text-white text-[10px] xl:text-xs px-2 py-1 rounded-full mr-2 lg:mr-3">
                        #{sectorObj}
                      </span>
                    ))
                  : null}
                <span className="bg-[#252744] text-white text-[10px] xl:text-xs px-2 py-1 rounded-full">
                  #{projectType}
                </span>
              </div>
              <div className="mt-3 flex justify-start font-semibold text-sm lg:text-base xl:text-lg text-gray-800">
                {projectTitle}
              </div>
              <div className="mt-1 xl:mt-2 flex justify-start font-light xl:font-normal text-xs xl:text-sm h-2/3 text-gray-700">
                <p className="leading-loose">{shortenedProjectBrief}...</p>
              </div>
            </div>
          </div>
          {/* Button */}
          <div className="bg-white">
            <div className="w-3/6 flex justify-center mx-auto pb-6">
              {projectType === "Website" && !projectSectors.includes(4) ? (
                <>
                  <a
                    href={projectUrl}
                    target="_blank"
                    className="mt-3 font-semibold text-[10px] lg:text-xs xl:text-sm text-primary bg-white px-3 py-1 lg:py-2 border border-primary rounded-2xl cursor-pointer"
                  >
                    Visit Site
                  </a>
                </>
              ) : (
                <>
                  <a
                    href={projectUrl}
                    //target="_blank"
                    className="mt-3 font-semibold text-[10px] lg:text-xs xl:text-sm text-primary bg-white px-3 py-1 lg:py-2 border border-primary rounded-2xl cursor-pointer"
                  >
                    View Details
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectsCard;

// flex flex-col justify between

import React from "react";
import OurServicesCardMobileNavbar from "../navbarReusableComponents/OurServicesCardMobileNavbar";

import { AiOutlineMobile } from "react-icons/ai";
import { AiOutlineDesktop } from "react-icons/ai";
import { AiOutlineGlobal } from "react-icons/ai";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { AiOutlineSearch } from "react-icons/ai";
import { AiOutlineRobot } from "react-icons/ai";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { HiOutlinePresentationChartBar } from "react-icons/hi";
import { RiUserVoiceLine } from "react-icons/ri";

import { Link } from "react-router-dom";

const OurServicesMobile = ({
  setOurServicesSidebarOpen,
  setShowMenu,
  onNavClickHandler,
}) => {
  const onServiceClickHandler = () => {
    setOurServicesSidebarOpen(false);
    setShowMenu(false);
    onNavClickHandler();
  };
  return (
    <>
      <div className="bg-secondary shadow-xl">
        <div className="flex flex-row justify-between items-center mb-4  bg-[#493854] p-2">
          <div
            className="flex flex-row items-center  gap-2"
            onClick={() => setOurServicesSidebarOpen(false)}
          >
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-chevron-left text-white"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
                />
              </svg>
            </span>
            <span className="text-white text-base font-semibold">
              Our Services
            </span>
          </div>
        </div>
        <div className="mx-auto px-6 md:px-12">
          {/* <div className="flex flex-row justify-between border-b border-[#E75124]">
            <p className="uppercase text-xs text-white font-medium pb-2 mb-2">
              Key Services
            </p>
            <a
              href="/our-services"
              className="text-xs text-white font-medium pb-2 mb-2 cursor-pointer"
            >
              View All
            </a>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-8 mb-6">
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service1">
                <OurServicesCardMobileNavbar
                  title="Websites / Web Apps / Portals"
                  description="Static | Dynamic | Portals | Responsive Design | CMS | SEO"
                  icon={<AiOutlineDesktop size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service2">
                <OurServicesCardMobileNavbar
                  title="In-Premise Applications"
                  description="Windows | Mac | Linux | Offline apps"
                  icon={<AiOutlineGlobal size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service4">
                <OurServicesCardMobileNavbar
                  title="Mobile Solutions"
                  description="Native | Hybrid | Andriod | IOS | Cross
                                    Platform"
                  icon={<AiOutlineMobile size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service4">
                <OurServicesCardMobileNavbar
                  title="e-Governance Solutions"
                  description="Resource Planning | Records Digitization |
 Workflow automation | Decision support system |
 Information Dissemination | Single window"
                  icon={
                    <HiOutlineBuildingLibrary size={20} color={"#F39174"} />
                  }
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service5">
                <OurServicesCardMobileNavbar
                  title="e-Commerce Solutions"
                  description="Product Catalog | Shopping Cart | Payment Gateway |
Order Management | Analytics & Reporting | User Management"
                  icon={<AiOutlineShoppingCart size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service6">
                <OurServicesCardMobileNavbar
                  title="Software Consultancy"
                  description="UI/UX | Data Visualization | Database |
                                    Technology Adoption | IT Infrastructure"
                  icon={<RiUserVoiceLine size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service7">
                <OurServicesCardMobileNavbar
                  title="Search Engine Optimization (SEO) Consultancy"
                  description="Keyword Research | On-Page Optimization | Technical SEO | Link Building | Content Marketing"
                  icon={<AiOutlineSearch size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service8">
                <OurServicesCardMobileNavbar
                  title="Chatbots"
                  description="Messenger bots | Realtime & Group chat | NLP
                                    | AI | Website Chatbots"
                  icon={<AiOutlineRobot size={20} color={"#F39174"} />}
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services#Service9">
                <OurServicesCardMobileNavbar
                  title="Soft Skills Training"
                  description="Communication | Time management | Interpersonal Skills | Teamwork | People management etc."
                  icon={
                    <HiOutlinePresentationChartBar
                      size={20}
                      color={"#F39174"}
                    />
                  }
                />
              </Link>
            </div>
            <div onClick={() => onServiceClickHandler()}>
              <Link to="/our-services">
                {/* <OurServicesCardMobileNavbar
                  title="See all services"
                  description=""
                /> */}
                <div className="flex justify-center pt-2">
                  <div className="bg-[#8555C9] px-2.5 py-2 text-white text-xs font-medium rounded-full cursor-pointer">
                    See all services
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServicesMobile;

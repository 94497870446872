import React, { useRef, useEffect, useState } from "react";

import { useLogo } from "../context/LogoContext";

import dairybannerphoto from "../Assets/pnibanner.jpg";
import projecthomephoto from "../Assets/pnihome.png";
import projectfirstphoto from "../Assets/pnifirst.png";
import projectsecondphoto from "../Assets/pnisecond.png";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";
import GenericModal from "../reusable-components/modal/GenericModal";

const ProjectSmuPni = () => {
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState("");

  const bannerSmuPniPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSmuPniPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSmuPniPage?.current?.offsetHeight);
    }
  }, [bannerSmuPniPage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerSmuPniPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={dairybannerphoto}
              alt="Contact Us photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10 text-white">
              <div className="flex flex-col justify-center ">
                <Heading classes={`text-white`}>Sikkim Milk Union</Heading>

                <SubHeading classes={"text-white"}>P&I Website</SubHeading>
                <br />
              </div>
              {/* <span className="py-2 md:px-8 bg-primary  text-white   cursor-pointer hover:underline text-center ">
                <a href="https://sikkimilk.coop/" target="_blank">
                  Visit Website
                </a>
              </span> */}
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
                Sikkim Milk Union P&I Website
              </Heading>
            </div>
            {/* <div className="mb-10 text-center underline font-bold text-[12px] md:text-base lg:text-xl text-gray-700">
              We bring you the products from the finest dairy of Sikkim
            </div> */}

            <div className="mt-10 lg:mt-24">
              <p className="mb-4 font-medium text-xs lg:text-base xl:text-[20px] text-gray-800">
                It is the official website for "The Sikkim Co-operative Milk
                Producer's Union Limited". This includes the Procurement and
                Input (P&I) portal that users may access after logging in.
              </p>
              <div className="mb-8 font-normal text-xs lg:text-base xl:text-[20px] text-gray-700">
                <p className="leading-relaxed">
                  Procurement and Input (P&I) is a web-portal which is
                  essentially aimed at automating the milk collection inventory,
                  billing and cooling facilities inventory workflow for Sikkim
                  Milk Cooperative Producer's Union Ltd. The online portal seeks
                  to establish itself as a centralized hub for information to
                  the Sikkim Milk Producers plants in Gangtok & Jorethang
                  regarding daily milk collection, monthly bill generation for
                  society providing the milk, cooling facilities provided to the
                  societies, and many more. It aims to cater to a wide range of
                  stakeholders such as the Plant Administrators, P&I
                  Administrators, Supervisors and Higher Managements of Sikkim
                  Milk Cooperative Producers Union Ltd. Furthermore, it is
                  compatible for viewing in desktops, laptops, tablets & mobile
                  phones. Additionally, this web portal provides its users with
                  all modern reporting capabilities to easily draw inventory or
                  transaction reports in all desired format.
                </p>
              </div>
              <p className="mb-4 font-medium text-xs lg:text-base xl:text-[20px] text-gray-800">
                The Website provides us information regarding:
              </p>
              <ul className="list-disc font-normal text-xs lg:text-base xl:text-[20px] mx-10 mb-8 text-gray-700">
                <li className="mb-4">
                  Profile, Mission, Objectives, Functions, Board of Directors,
                  SMU Executives, Organization Chart and Milestones of Sikkim
                  Milk Union.
                </li>
                <li className="mb-4">
                  Visitors are able to know about the facilities provided to
                  them through Head Office, Regional Plant, Plant and Diary and
                  Co-operative society.
                </li>
                <li className="mb-4">
                  They can also check the product provided to them in products
                  section.
                </li>
                <li className="mb-4">
                  They can contact to the Sikkim Milk Union for further
                  information and queries.
                </li>
              </ul>
              <p className="mb-4 font-medium text-xs lg:text-base xl:text-[20px] text-gray-800">
                A few key features of the P&I portal includes:
              </p>
              <ul className="list-disc font-normal text-xs lg:text-base xl:text-[20px] mx-10 mb-8 text-gray-700">
                <li className="mb-4">
                  User registration for all the stakeholders - System Admin,
                  Society and Members.
                </li>
                <li className="mb-4">
                  Daily Credit/Debit Billing associated with procurement of milk
                  and other products associated with manufacturing from across
                  the state.
                </li>
                <li className="mb-4">
                  Automated billing report generation for a particular time
                  period in diffrent formats as required by the client.
                </li>
                <li className="mb-4">
                  Daily automated SMS notifications in correspondence to the
                  daily operation to all the stakeholders.
                </li>
              </ul>
            </div>

            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              The Website provides us information regarding:
            </SubHeading>

            <ul className="list-disc text-xs lg:text-base 2xl:text-[20px] font-normal mx-10 mb-8 text-gray-700">
              <li className="mb-4">
                Profile, Mission, Objectives, Functions, Board of Directors, SMU
                Executives, Organization Chart and Milestones of Sikkim Milk
                Union.
              </li>
              <li className="mb-4">
                Visitors are able to know about the facilities provided to them
                through Head Office, Regional Plant, Plant and Diary and
                Co-operative society.
              </li>
              <li className="mb-4">
                They can also check the product provided to them in products
                section.
              </li>
              <li className="mb-4">
                They can contact to the Sikkim Milk Union for further
                information and queries.
              </li>
            </ul>

            <SubHeading classes={`mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>

            <ul className="list-disc text-xs lg:text-base 2xl:text-[20px] font-normal mx-10 mb-8 text-gray-700">
              <li className="mb-4">
                User registration for all the stakeholders - System Admin,
                Society and Members.
              </li>
              <li className="mb-4">
                Daily Credit/Debit Billing associated with procurement of milk
                and other products assocuated with manufacturing from across the
                state.
              </li>
              <li className="mb-4">
                Automated Billing Report Generation for a particular time period
                in diffrent formats as required by the client.
              </li>
              <li className="mb-4">
                Daily automated SMS notifications in correspondence to the daily
                operation to all the stakeholders.
              </li>
            </ul>

            <SubHeading classes={`pt-6  text-gray-800]`}>
              Some Images of Sikkim Milk Union P&I
            </SubHeading>

            <div className="my-5 flex flex-col md:flex-row items-center gap-16 pt-5 text-gray-700">
              <div
                className="flex flex-col gap-4 cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setClickedImage("pni_image1");
                }}
              >
                <img
                  src={projectfirstphoto}
                  alt="projectfirstphoto"
                  className="h-56"
                />

                <span className="text-xs text-center italic">
                  P&I Dashboard
                </span>
              </div>

              <div
                className="flex flex-col gap-2 cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setClickedImage("pni_image2");
                }}
              >
                <img
                  src={projectsecondphoto}
                  alt="projectsecondphoto"
                  className="h-56"
                />
                <span className="text-xs text-center italic">
                  Society Configuration of P&I
                </span>
              </div>

              {/* <div
                className="flex flex-col gap-2 cursor-pointer"
                onClick={() => {
                  setShowModal(true);
                  setClickedImage("pni_image3");
                }}
              >
                <img
                  src={projectsecondphoto}
                  alt="projectsecondphoto"
                  className="h-56 "
                />
                <span className="text-xs text-center">projectTHirdphoto</span>
              </div> */}
            </div>

            <SubHeading classes={` mb-5  text-gray-800]`}>
              Technology Used
            </SubHeading>

            <div className="flex flex-row my-10">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  ASP.NET
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Bootstrap
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  MS SQL
                </div>
              </>
            </div>
          </div>

          <GenericModal
            open={showModal}
            setOpen={setShowModal}
            isAdd={true}
            isLarge={true}
          >
            <div className="my-4">
              {clickedImage === "pni_image1" ? (
                <div className="flex  flex-col justify-center items-center">
                  <div className="flex justify-center w-full relative  ">
                    <p className="text-white text-center">P&I Dashboard</p>
                    <p
                      className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                      onClick={() => setShowModal(false)}
                    >
                      X
                    </p>
                  </div>

                  <img
                    src={projectfirstphoto}
                    alt="dashboardHRMS"
                    className="h-auto w-full pt-4 object-cover"
                  />
                </div>
              ) : null}

              {clickedImage === "pni_image2" ? (
                <div className="flex  flex-col justify-center items-center">
                  <div className="flex justify-center w-full relative  ">
                    <p className="text-white text-center">
                      Society Configuration of P&I
                    </p>
                    <p
                      className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                      onClick={() => setShowModal(false)}
                    >
                      X
                    </p>
                  </div>

                  <img
                    src={projectsecondphoto}
                    alt="projectfirstphoto"
                    className="h-auto w-full pt-4 object-cover"
                  />
                </div>
              ) : null}

              {/* {clickedImage === "pni_image3" ? (
                <div className="flex  flex-col justify-center items-center">
                  <div className="flex justify-center w-full relative  ">
                    <p className="text-white text-center">PNI Third Photo</p>
                    <p
                      className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                      onClick={() => setShowModal(false)}
                    >
                      X
                    </p>
                  </div>

                  <img
                    src={projecthomephoto}
                    alt="projecthomephoto"
                    className="h-full pt-4"
                  />
                </div>
              ) : null} */}
            </div>
          </GenericModal>
        </section>
      </div>
      ;
    </>
  );
};

export default ProjectSmuPni;

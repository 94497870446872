import React from "react";

const SubHeading = ({ classes, ...rest }) => {
  return (
    <div>
      <h1
        className={`font-semibold  2xl:text-3xl lg:text-xl text-lg font-sans  ${classes}`}
      >
        {rest.children}
      </h1>
    </div>
  );
};

export default SubHeading;

import React, { useEffect, useState } from "react";
import MegaMenuCardProjects from "../navbarReusableComponents/MegaMenuCardProjects";
import { AiFillAppstore } from "react-icons/ai";
import { NavLink } from "react-router-dom";

import "./ourprojects.css";

const OurProjects = ({
  isNavBarTransparent,
  projectsArr,
  projectsSectorsArr,
  setOpenProjectMegaMenu,
}) => {
  const [projectArray, setProjectArray] = useState();
  const [selectedSectorId, setSelectedSectorId] = useState();

  let sortedProjectArray = projectsArr.sort((a, b) => {
    if (a.id < b.id) {
      return -1;
    }
    if (a.id > b.id) {
      return 1;
    }
    return 0;
  });

  const onSelectSector = (id) => {
    setSelectedSectorId(id);

    if (id === 0) {
      setProjectArray(sortedProjectArray);
      return;
    } else {
      let prjArr = sortedProjectArray.filter((filterObj) =>
        filterObj.sectors.includes(id)
      );

      setProjectArray(prjArr);
    }
  };

  useEffect(() => {
    setProjectArray(sortedProjectArray);
    setSelectedSectorId(0);
  }, []);

  return (
    <section>
      <div
        className={`text-gray-700 w-full mx-auto bg-white p-10 mt-6 rounded-lg shadow-sm  
                                       ${
                                         isNavBarTransparent
                                           ? "scale-95 transition-all ease-in-out duration-500 "
                                           : "scale-100 transition-all ease-in-out duration-500"
                                       }
                          
                          `}
      >
        <p className="uppercase text-sm md:text-base text-gray-500 font-medium pb-2 border-b border-[#E75124] mb-8">
          Key Projects
        </p>

        <div className="grid grid-cols-6">
          <div className="col-span-1 bg-slate-100 rounded-md py-4 pl-2">
            {projectsSectorsArr?.map((sectorObj, index) => (
              <>
                <p
                  id={`sector_id_${[sectorObj.id]}`}
                  key={sectorObj.id}
                  className={`text-xs font-medium hover:text-[#E75124] cursor-pointer mb-4
                        ${
                          sectorObj.id === selectedSectorId
                            ? "text-[#E75124]"
                            : null
                        }
                        `}
                  onClick={() => onSelectSector(sectorObj.id)}
                >
                  {sectorObj?.sectorName}
                </p>
              </>
            ))}
          </div>

          <div className="col-span-5 ml-4">
            <div className="grid grid-cols-4 gap-x-3 gap-y-6">
              {projectArray?.map((projectObj) => (
                <>
                  <MegaMenuCardProjects
                    clientLogo={projectObj?.clientLogo}
                    imageBlurHashString={projectObj?.imageBlurHashString}
                    projectTitle={projectObj?.projectTitle || "test"}
                    siteUrl={projectObj?.siteUrl || "#"}
                    newTab={projectObj?.newTab}
                  />
                </>
              ))}

              <NavLink
                to="/our-projects"
                onClick={() => setOpenProjectMegaMenu(false)}
              >
                <div className="flex flex-col justify-center items-center hover:text-orange-600 gap-8 ">
                  <div className="mt-4 ml-8">
                    <AiFillAppstore size={40} className="ml-4" />
                    <p className="text-xs font-semibold pb-1 text-gray-800 text-center pt-4">
                      See All Projects
                    </p>
                  </div>
                </div>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProjects;

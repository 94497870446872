import React from "react";
import { Link } from "react-router-dom";
import Heading from "../reusable-components/fonts/Heading";

import servicephoto from "../Assets/sample_service.png";
import mobilesolutionsphoto from "../Assets/mobile solution.jpg";
import ServicesLinksHome from "../reusable-components/ServicesLinksHome";

const OurServicesHome = () => {
  return (
    <section className="bg-[#FFE9DC]">
      <Heading
        classes={`flex flex-row justify-center my-5 lg:my-8 pt-5 lg:pt-10 lg:pb-10 text-gray-800`}
      >
        Our Services
      </Heading>
      <div className="hidden lg:block">
        <div className="mx-3 lg:mx-6 xl:mx-9 grid grid-cols-2">
          <div className="flex flex-col items-center">
            <img
              src={mobilesolutionsphoto}
              className="w-2/3 h-5/6 rounded-md mt-2"
            />
          </div>
          <div className="">
            <p className="text-[#E75124] text-lg lg:text-xl 2xl:text-2xl font-medium mb-4">
              Services provided by us:
            </p>
            <p className="mr-24 text-gray-700 text-sm lg:text-[15px] 2xl:text-[20px] font-normal mb-6 text-justify leading-7">
              Our company provides a wide range of services to help businesses
              build and maintain an effective online presence. Here are some of
              the key services we offer:
            </p>
            <div className="grid grid-cols-2">
              <Link to="/our-services#service1">
                <ServicesLinksHome service_name="Websites / Web Apps / Portals" />
              </Link>
              <Link to="/our-services#service2">
                <ServicesLinksHome service_name="In-Premise Applications" />
              </Link>
              <Link to="/our-services#service3">
                <ServicesLinksHome service_name="Mobile Solutions" />
              </Link>
              <Link to="/our-services#service4">
                <ServicesLinksHome service_name="e-Governance Solutions" />
              </Link>
              <Link to="/our-services#service5">
                <ServicesLinksHome service_name="e-Commerce Solutions" />
              </Link>
              <Link to="/our-services#service6">
                <ServicesLinksHome service_name="Software Consultancy" />
              </Link>
              <Link to="/our-services#service7">
                <ServicesLinksHome service_name="SEO Consultancy" />
              </Link>
              <Link to="/our-services#service8">
                <ServicesLinksHome service_name="Chatbots" />
              </Link>
              <Link to="/our-services#service9">
                <ServicesLinksHome service_name="Soft Skills Training" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="block lg:hidden">
        <div className="mx-3 lg:mx-6 xl:mx-9 grid grid-cols-1">
          <div className="flex flex-col items-center">
            <img
              src={mobilesolutionsphoto}
              className="w-2/3 h-5/6 rounded-md mt-2"
            />
          </div>
          <div className="flex flex-col items-center justify-center">
            <p className="text-[#E75124] font-medium text-xl mb-4 justify-center">
              Services provided by us:
            </p>

            <p className="mx-6 md:mx-16 text-gray-800 text-sm font-normal mb-6 text-justify justify-center">
              Our company provides a wide range of services to help businesses
              build and maintain an effective online presence. Here are some of
              the key services we offer:
            </p>

            <div className="grid grid-cols-1 md:grid-cols-2">
              <Link to="/our-services#Service1">
                <ServicesLinksHome service_name="Websites / Web Apps / Portals" />
              </Link>
              <Link to="/our-services#Service2">
                <ServicesLinksHome service_name="In-Premise Applications" />
              </Link>
              <Link to="/our-services#Service3">
                <ServicesLinksHome service_name="Mobile Solutions" />
              </Link>
              <Link to="/our-services#Service4">
                <ServicesLinksHome service_name="e-Governance Solutions" />
              </Link>
              <Link to="/our-services#Service5">
                <ServicesLinksHome service_name="e-Commerce Solutions" />
              </Link>
              <Link to="/our-services#Service6">
                <ServicesLinksHome service_name="Software Consultancy" />
              </Link>
              <Link to="/our-services#Service7">
                <ServicesLinksHome service_name="SEO Consultancy" />
              </Link>
              <Link to="/our-services#Service8">
                <ServicesLinksHome service_name="Chatbots" />
              </Link>
              <Link to="/our-services#Service9">
                <ServicesLinksHome service_name="Soft Skills Training" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServicesHome;

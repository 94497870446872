import { Routes, Route } from "react-router-dom";
import "./App.css";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import FAQ from "./Pages/FAQ";
import Home from "./Pages/Home";
import HowWeWork from "./Pages/HowWeWork";
import ProjectGratitude from "./Pages/ProjectGratitude";
import ProjectRsb from "./Pages/ProjectRsb";
import Projects from "./Pages/Projects";
import ProjectSabcco from "./Pages/ProjectSabcco";
import ProjectSecSikkim from "./Pages/ProjectSecSikkim";
import ProjectSeedCell from "./Pages/ProjectSeedCell";
import ProjectSikkimInvestmentSummit from "./Pages/ProjectSikkimInvestmentSummit";
import ProjectSiscoBank from "./Pages/ProjectSiscoBank";
import ProjectSmuPni from "./Pages/ProjectSmuPni";
import ProjectSikkimMilkHRMS from "./Pages/ProjectSikkimMilkHRMS";
import ProjectSikkimMilkMitraApp from "./Pages/ProjectSikkimMilkMitraApp";
import ProjectSikkimTourism from "./Pages/ProjectSikkimTourism";
import Services from "./Pages/Services";
import PublicLayout from "./PublicLayout/PublicLayout";
import Blog from "./Pages/Blog";
import BlogPage from "./Pages/BlogPage";
import ContactUsv2 from "./Pages/ContactUsv2";

function App() {
  return (
    <div>
      <Routes>
        {/* <Route><Navbar /></Route> */}
        <Route element={<PublicLayout />}>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/our-services" element={<Services />} />
          <Route exact path="/how-we-work" element={<HowWeWork />} />
          <Route exact path="/our-projects" element={<Projects />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/contact-us-v2" element={<ContactUsv2 />} />
          <Route exact path="/frequently-asked-questions" element={<FAQ />} />
          <Route
            exact
            path="/gratitude-view-details"
            element={<ProjectGratitude />}
          />
          <Route
            exact
            path="/sabcco-view-details"
            element={<ProjectSabcco />}
          />
          <Route exact path="/rsb-view-details" element={<ProjectRsb />} />
          <Route
            exact
            path="/sec-sikkim-view-details"
            element={<ProjectSecSikkim />}
          />
          <Route
            exact
            path="/seed-cell-view-details"
            element={<ProjectSeedCell />}
          />
          <Route
            exact
            path="/sikkim-investment-summit-view-details"
            element={<ProjectSikkimInvestmentSummit />}
          />
          <Route
            exact
            path="/sisco-bank-view-details"
            element={<ProjectSiscoBank />}
          />
          <Route
            exact
            path="/sikkim-milk-union-p-and-i-view-details"
            element={<ProjectSmuPni />}
          />
          <Route
            exact
            path="/sikkim-tourism-view-details"
            element={<ProjectSikkimTourism />}
          />
          <Route
            exact
            path="/sikkim-milk-mitra-app-view-details"
            element={<ProjectSikkimMilkMitraApp />}
          />
          <Route
            exact
            path="/sikkim-milk-hrms-view-details"
            element={<ProjectSikkimMilkHRMS />}
          />
          <Route exact path="/blog" element={<Blog />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;

import React, { useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import egovbannerphoto from "../Assets/rsbbanner.png";
import websitephoto from "../Assets/rsbhome.png";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

const ProjectRsb = () => {
  const bannerRsbPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerRsbPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerRsbPage?.current?.offsetHeight);
    }
  }, [bannerRsbPage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerRsbPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={egovbannerphoto}
              alt="Contact Us photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10">
              <Heading classes={`text-white`}>Rajya Sainik Board</Heading>
              <p className=" text-white font-medium text-xl lg:text-2xl">
                Website
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
                Rajya Sainik Board Website
              </Heading>
              {/* <div className="mt-0 md:mt-10 mb-0 md:mb-5 md:absolute md:right-0">
                <a
                  href="/our-projects"
                  className="text-white text-[10px] lg:text-sm bg-[#E75124] border px-2 py-1 cursor-pointer rounded-md hover:underline"
                >
                  &lt; Go Back
                </a>
              </div> */}
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed text-gray-700">
                  This is a dynamic website for the Department of Sainik Welfare
                  (Govt. of Sikkim) that houses all information regarding state
                  Sainik welfare and other army related activities. The website
                  aims to become a centralized platform for information
                  dissemination regarding various information, activities,
                  schemes with respect to the department. It aims to cater to
                  wide range of stakeholders such as Central Government, State
                  Govt., defense personnel, civilians and ex-servicemen. The
                  portal is developed with standards as prescribed by the Govt.
                  of India and is compatible to all modern browsers. It is
                  powered by a customized CMS(content management system) that
                  enables the administrator to easily & efficiently manage its
                  content. Furthermore, it is compatible for viewing in both
                  laptops, mobile phones & tablets and has login capabilities
                  for department administrators to manage content as well as for
                  ex-servicemen to log their grievances.
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="http://www.deswsikkim.nic.in/Public/Index/Home"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-primary text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
                {/* <div className="mt-4 xl:mt-6 flex flex-row md:justify-start lg:justify-end pr-12">
                  <>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      ASP.NET
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Bootstrap
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      MS SQL
                    </div>
                  </>
                </div> */}
              </div>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>
            <div className="mb-10">
              <ul className="list-disc font-normal text-xs lg:text-base 2xl:text-[20px]  mx-10 text-justify text-gray-700">
                <li className="mb-4">
                  It is primarily an informative website providing information
                  related to various aspects like who the actual target audience
                  is, about the organization, its history, the gallantry award
                  winners from Sikkim.
                </li>
                <li className="mb-4">
                  The website also provides information about the various grants
                  and schemes that can be availed by the target audience,
                  recruitment notifications in civil defence, the board
                  meetings, events and activities organized by RSB, the location
                  of sainik houses/boards in the state.
                </li>
                <li className="mb-4">
                  The website also has a CMS (Content Mangement System) with
                  login which helps the system admin in updating contents
                  throughout the website.
                </li>
                <li className="mb-4">
                  The website visitors can submit feedback and queries via the
                  feedback form present in the website.
                </li>
                <li className="mb-4">
                  The website visitors can also register themselves as
                  applicants, if they meet the registration criteria, so as to
                  avail the services provided to them by the board.
                </li>
              </ul>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800]`}>
              Technology Used
            </SubHeading>
            <div className="flex flex-row">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  ASP.NET
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Bootstrap
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  MS SQL
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectRsb;

import React, { useRef, useEffect, useState } from "react";

import { useLogo } from "../context/LogoContext";

import bankingbannerphoto from "../Assets/BankingBanner.jpg";
import checkrates from "../Assets/checkRates_sisco.png";
import findatm from "../Assets/findatm_sisco.png";
import loans from "../Assets/loans_sisco.png";
import websitephoto from "../Assets/siscohome.png";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";
import GenericModal from "../reusable-components/modal/GenericModal";

const ProjectSiscoBank = () => {
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState("");
  const bannerSiscoPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSiscoPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSiscoPage?.current?.offsetHeight);
    }
  }, [bannerSiscoPage?.current?.offsetHeight]);

  return (
    <>
      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        isAdd={true}
        isLarge={true}
      >
        <div className="my-4">
          {clickedImage === "sisco_img1" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center"> SISCO Login</p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={checkrates} alt="checkrates" className="h-full pt-4" />
            </div>
          ) : null}

          {clickedImage === "sisco_img2" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">SISCO- Dashboard</p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={findatm} alt="findatm" className="h-full pt-4" />
            </div>
          ) : null}

          {clickedImage === "sisco_img3" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">SISCO - Dashboard</p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={loans} alt="loans" className="h-full pt-4" />
            </div>
          ) : null}
        </div>
      </GenericModal>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerSiscoPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={bankingbannerphoto}
              alt="Contact Us photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10">
              <Heading classes={`text-white`}>SISCO Bank</Heading>
              <p className=" text-white font-medium text-xl lg:text-2xl">
                Website
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
                SISCO Bank Website
              </Heading>
              {/* <div className="mt-0 md:mt-10 mb-0 md:mb-5 md:absolute md:right-0">
                <a
                  href="/our-projects"
                  className="text-white text-[10px] lg:text-sm bg-[#E75124] border px-2 py-1 cursor-pointer rounded-md hover:underline"
                >
                  &lt; Go Back
                </a>
              </div> */}
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed text-gray-700">
                  This is a website for Sikkim State Co-operative Bank Pvt Ltd
                  built with an aim to provide essential banking information to
                  its customers and an in-house tool for bank employees to
                  efficiently manage their notification workflow. It enables
                  public to send queries or grievances which in-turn will
                  trigger a notification to all relevant bank administrators to
                  take appropriate action. Also the website has provision for
                  general public to send service request like opening account,
                  credit counselling, request for Cheque book etc. The website
                  has sophisticated and flexible admin capabilities to easily
                  manage and scrutinize their content prior to it getting
                  published. The portal has all the facets of a modern website
                  such as screen adaptability for all screen sizes(desktops,
                  laptops, mobile phones & tablets), Google map integration to
                  easily locate all its branches spread across Sikkim and mail
                  server integration to facilitate sending & receiving of mails
                  from the bank's domain.
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="https://siscobank.com/"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-primary text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
                {/* <div className="mt-4 xl:mt-6 flex flex-row md:justify-start lg:justify-end pr-12">
                  <>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      React JS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Tailwind CSS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Strapi CMS
                    </div>
                  </>
                </div> */}
              </div>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>
            <div className="mb-10">
              <ul className="list-disc font-normal text-xs lg:text-base 2xl:text-[20px]  mx-10 text-justify text-gray-700">
                <li className="mb-4">
                  The website provides various information about the bank, its
                  history, achievements, stakeholders, annual reports, photo
                  galleries of various events organised by the bank, ATM(s) and
                  Branch(es) location in and around Sikkim, insurance schemes
                  made available by the bank, etc.
                </li>
                <li className="mb-4">
                  The website also provides information about the latest events
                  and happenings asssociated with the bank and the digital
                  banking products and services provided by the bank.
                </li>
                <li className="mb-4">
                  The visitors can send feedback/grievance via request for
                  service for forms to the bank for various purposes.
                </li>
                <li className="mb-4">
                  The visitors can make payments for electricity bills on the
                  website, via links redirected to the corresponding address.
                </li>
                <li className="mb-4">
                  The visitors can also calculate the interests and returns
                  against various fixed and recurring deposits if they plan to
                  open one. They can also calculate the interest amount for
                  various loans provided by the bank.
                </li>
              </ul>
            </div>

            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Some Images of SISCO Bank Website
            </SubHeading>

            <div className="">
              <div className="my-5 flex flex-col md:flex-row items-center gap-14 pt-5 ">
                <div
                  className=" flex flex-col gap-4 cursor-pointer  "
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("sisco_img1");
                  }}
                >
                  <img
                    src={checkrates}
                    alt="checkrates"
                    className="h-60 border-2"
                  />
                  <span className="text-xs italic text-center ">
                    Check Rates of SISCO{" "}
                  </span>
                </div>

                <div
                  className="flex flex-col gap-4 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("sisco_img2");
                  }}
                >
                  <img src={findatm} alt="findatm" className="h-60  border-2" />
                  <span className="text-xs italic text-center ">
                    Find ATM Page of SISCO
                  </span>
                </div>
                <div
                  className="flex flex-col gap-4 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("sisco_img3");
                  }}
                >
                  <img src={loans} alt="loans" className="h-60 border-2" />
                  <span className="text-xs italic text-center ">
                    {" "}
                    Loan Details Page of SISCO Bank
                  </span>
                </div>
              </div>
            </div>

            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Technology Used
            </SubHeading>
            <div className="flex flex-row my-10">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  React JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Tailwind CSS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Strapi CMS
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectSiscoBank;

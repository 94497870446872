import React from "react";

const MegaMenuCardServices = ({ title, description, icon }) => {
  return (
    <section>
      {/* <div className="hover:bg-slate-200 hover:rounded-md px-3  py-4 flex flex-row  items-start gap-4 cursor-pointer">
        <div>{icon}</div>

        <div className="flex flex-col">
          <p className="text-sm 2xl:text-lg 3xl:text-lg font-bold pb-1 text-gray-800">
            {title}
          </p>

          <p className="text-xs 2xl:text-base  3xl:text-base font-normal pb-1 text-gray-500">
            {description}
          </p>
        </div>
      </div> */}

      <div className="hover:bg-slate-200 hover:rounded-md px-3 py-4 flex flex-row items-start gap-3 cursor-pointer">
        <div>{icon}</div>

        <div className="flex flex-col">
          <p className="text-sm font-bold pb-1 text-gray-800">{title}</p>

          <p className="text-xs font-normal pb-1 text-gray-500">
            {description}
          </p>
        </div>
      </div>
    </section>
  );
};

export default MegaMenuCardServices;

import React from "react";
import Heading from "../reusable-components/fonts/Heading";
import WhyBytesberryCardHome from "../reusable-components/WhyBytesberryCardHome";

const WhyBytesberry = () => {
  return (
    <>
      <Heading
        classes={`flex flex-row justify-center my-5 lg:my-10 lg:pb-10 text-gray-800`}
      >
        Why Bytesberry?
      </Heading>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 mx-3 lg:mx-6 xl:mx-9 items-start">
        <WhyBytesberryCardHome
          title="Experience and expertise"
          description="Our team brings a wealth of experience and expertise to each project, 
          delivering high-quality software solutions that meet our clients' needs."
        >
          <svg
            width="58"
            height="71"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_115_54)">
              <rect width="24" height="24" fill="white" />
              <path
                d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
                fill="#FAE4D2"
              />
              <circle
                cx="12.5"
                cy="5.5"
                r="1.875"
                stroke="#E75125"
                stroke-width="1.25"
              />
              <path
                d="M17 15.75L16.6544 11.0838C16.5673 9.90885 15.5887 9 14.4105 9H10.5895C9.41134 9 8.43268 9.90885 8.34565 11.0838L8 15.75"
                stroke="#E75125"
                stroke-width="1.125"
                stroke-linecap="round"
              />
              <path d="M10 13V15.5" stroke="#E75125" stroke-linecap="round" />
              <path d="M15 13V15.5" stroke="#E75125" stroke-linecap="round" />
              <path
                d="M11.8463 16.6674L12.8293 15L13.7904 16.6062L15.9109 17.2488L14.3623 18.4099L14.749 20.5391L12.7988 19.3029L10.9821 20.4788L11.4142 18.5731L10 17.3073L11.8463 16.6674Z"
                fill="#252744"
              />
              <path
                d="M18.3333 17.2041L19.0432 16L19.7372 17.1599L21.2686 17.6239L20.1502 18.4624L20.4295 20L19.0211 19.1073L17.7092 19.9565L18.0213 18.5803L17 17.6662L18.3333 17.2041Z"
                fill="#252744"
              />
              <path
                d="M5.33333 17.2041L6.04317 16L6.73722 17.1599L8.26856 17.6239L7.1502 18.4624L7.42949 20L6.02113 19.1073L4.70924 19.9565L5.02128 18.5803L4 17.6662L5.33333 17.2041Z"
                fill="#252744"
              />
            </g>
            <defs>
              <clipPath id="clip0_115_54">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </WhyBytesberryCardHome>

        <WhyBytesberryCardHome
          title="Quality assurance"
          description="Our comprehensive quality assurance processes ensure that each software product we deliver is of the highest quality and guarantee customer satisfaction."
        >
          <svg
            width="52"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_5_5)">
              <rect width="24" height="24" fill="white" />
              <circle cx="12" cy="12" r="12" fill="#FAE4D2" />
              <path
                d="M4 7.5V12V13.055C4 17.5939 7.37983 21.4225 11.8837 21.9855L12 22L12.9923 21.876C16.9957 21.3755 20 17.9723 20 13.9377V12V7.5"
                stroke="#E44A1A"
              />
              <path d="M4 7.5L12 3" stroke="#E44A1A" stroke-linecap="round" />
              <path d="M12 3L20 7.5" stroke="#E44A1A" stroke-linecap="round" />
              <path
                d="M7 12.5L9 10.5L11 12L16.4 8L18 9.72L12.5 14L10.5 15.5L7 12.52"
                stroke="#252744"
                stroke-linecap="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_5_5">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </WhyBytesberryCardHome>

        <WhyBytesberryCardHome
          title="Speed and efficiency"
          description="Our agile approach to software development allows us to quickly respond to changing requirements 
          and deliver software products in a timely manner."
        >
          <svg
            width="52"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#FAE4D2" />
            <path
              d="M8.18093 14.3776V21H16.1278V16.1096V14.3776"
              stroke="#E75125"
              stroke-width="0.882989"
              stroke-linecap="round"
            />
            <path
              d="M8.18093 14.3776L5.09046 15.7021H2.88299C2.39533 15.7021 2 15.3067 2 14.8191V14.5668C2 14.0791 2.39533 13.6838 2.88299 13.6838H4.57539L7.66585 12.1701"
              stroke="#E75125"
              stroke-width="0.882989"
              stroke-linecap="round"
            />
            <path
              d="M16.1278 14.3776L18.8697 15.7021H21.5652C22.0528 15.7021 22.4482 15.3067 22.4482 14.8191V14.5668C22.4482 14.0791 22.0528 13.6838 21.5652 13.6838H19.2183L16.5693 12.1701"
              stroke="#E75125"
              stroke-width="0.882989"
              stroke-linecap="round"
            />
            <path
              d="M7.73943 12.1701C9.06392 11.2871 12.6842 10.0509 16.5693 12.1701"
              stroke="#E75125"
              stroke-width="0.882989"
            />
            <path
              d="M14.5682 7C14.5682 8.41837 13.4184 9.56818 12 9.56818C10.5816 9.56818 9.43182 8.41837 9.43182 7C9.43182 5.58163 10.5816 4.43182 12 4.43182C13.4184 4.43182 14.5682 5.58163 14.5682 7Z"
              stroke="#E75125"
              stroke-width="0.863636"
            />
            <path
              d="M7.75 9C7.75 10.5188 6.51878 11.75 5 11.75C3.48122 11.75 2.25 10.5188 2.25 9C2.25 7.48122 3.48122 6.25 5 6.25C6.51878 6.25 7.75 7.48122 7.75 9Z"
              stroke="#252744"
              stroke-width="0.5"
            />
            <path
              d="M5 8V9V9.33333L6 8.66667"
              stroke="#252744"
              stroke-width="0.333333"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M19.6131 8.20189C19.6131 8.09039 19.7035 8 19.815 8H20.2188C20.3303 8 20.4206 8.09039 20.4206 8.20189V8.45466C20.5345 8.48396 20.6435 8.52536 20.7461 8.57741L20.9448 8.35303C21.0188 8.26956 21.1463 8.26181 21.2298 8.33574L21.5321 8.60343C21.6156 8.67735 21.6233 8.80494 21.5494 8.88842L21.3526 9.11065C21.4567 9.26345 21.5353 9.43504 21.5822 9.61928L21.792 9.61716C21.9035 9.61603 21.9948 9.7055 21.9959 9.817L22 10.2208C22.0011 10.3322 21.9116 10.4235 21.8002 10.4247L21.58 10.4269C21.5472 10.5531 21.4995 10.6732 21.4389 10.7854L21.6127 10.9384C21.6964 11.0121 21.7045 11.1396 21.6308 11.2233L21.364 11.5264C21.2903 11.6101 21.1628 11.6182 21.0791 11.5445L20.8899 11.378C20.7473 11.4698 20.5893 11.5397 20.4206 11.5831V11.8359C20.4206 11.9474 20.3303 12.0378 20.2188 12.0378H19.815C19.7035 12.0378 19.6131 11.9474 19.6131 11.8359V11.5831C19.4952 11.5528 19.3826 11.5095 19.2767 11.4548L19.1402 11.609C19.0662 11.6925 18.9387 11.7002 18.8552 11.6263L18.5529 11.3586C18.4694 11.2847 18.4617 11.1571 18.5356 11.0736L18.6743 10.917C18.5811 10.7779 18.5089 10.6236 18.4623 10.4584L18.208 10.461C18.0965 10.4621 18.0052 10.3727 18.0041 10.2612L18 9.85742C17.9989 9.74593 18.0884 9.65463 18.1998 9.6535L18.4438 9.65103C18.4703 9.53732 18.5088 9.42821 18.5579 9.32514L18.3514 9.14333C18.2677 9.06966 18.2596 8.94208 18.3333 8.85839L18.6001 8.55532C18.6737 8.47162 18.8013 8.4635 18.885 8.53718L19.0764 8.70568C19.236 8.59117 19.4174 8.50503 19.6131 8.45466V8.20189Z"
              fill="#252744"
            />
            <path
              d="M20.6225 10.0189C20.6225 10.3534 20.3514 10.6246 20.0169 10.6246C19.6824 10.6246 19.4112 10.3534 19.4112 10.0189C19.4112 9.68439 19.6824 9.41323 20.0169 9.41323C20.3514 9.41323 20.6225 9.68439 20.6225 10.0189Z"
              fill="#FAE4D2"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.2098 5.15142C17.2098 5.06779 17.2776 5 17.3612 5H17.6641C17.7477 5 17.8155 5.06779 17.8155 5.15142V5.341C17.9009 5.36297 17.9826 5.39402 18.0596 5.43306L18.2086 5.26478C18.2641 5.20217 18.3598 5.19636 18.4224 5.2518L18.6491 5.45257C18.7117 5.50801 18.7175 5.60371 18.6621 5.66631L18.5145 5.83299C18.5925 5.94759 18.6515 6.07628 18.6866 6.21446L18.844 6.21287C18.9276 6.21202 18.9961 6.27913 18.9969 6.36275L19 6.66557C19.0008 6.74919 18.9337 6.81766 18.8501 6.81851L18.685 6.82018C18.6604 6.91479 18.6246 7.00491 18.5792 7.08904L18.7095 7.20379C18.7723 7.25905 18.7784 7.35473 18.7231 7.4175L18.523 7.6448C18.4678 7.70757 18.3721 7.71366 18.3093 7.65841L18.1674 7.53348C18.0604 7.60232 17.942 7.65479 17.8155 7.68735V7.87693C17.8155 7.96055 17.7477 8.02834 17.6641 8.02834H17.3612C17.2776 8.02834 17.2098 7.96055 17.2098 7.87693V7.68735C17.1214 7.66459 17.0369 7.63211 16.9575 7.59111L16.8551 7.70676C16.7997 7.76936 16.704 7.77517 16.6414 7.71973L16.4147 7.51896C16.3521 7.46352 16.3463 7.36783 16.4017 7.30522L16.5057 7.18776C16.4358 7.08345 16.3817 6.96766 16.3467 6.84383L16.156 6.84576C16.0724 6.84661 16.0039 6.77951 16.0031 6.69588L16 6.39307C15.9992 6.30944 16.0663 6.24097 16.1499 6.24012L16.3329 6.23827C16.3527 6.15299 16.3816 6.07116 16.4184 5.99385L16.2635 5.8575C16.2008 5.80224 16.1947 5.70656 16.2499 5.64379L16.45 5.41649C16.5053 5.35372 16.601 5.34763 16.6637 5.40288L16.8073 5.52926C16.927 5.44337 17.063 5.37878 17.2098 5.341V5.15142Z"
              fill="#252744"
            />
            <path
              d="M17.9669 6.51417C17.9669 6.76505 17.7635 6.96842 17.5126 6.96842C17.2618 6.96842 17.0584 6.76505 17.0584 6.51417C17.0584 6.2633 17.2618 6.05992 17.5126 6.05992C17.7635 6.05992 17.9669 6.2633 17.9669 6.51417Z"
              fill="#FAE4D2"
            />
          </svg>
        </WhyBytesberryCardHome>

        <WhyBytesberryCardHome
          title="Support and maintenance"
          description="With a focus on long-term success, our team provides ongoing support and maintenance to ensure that our clients' software products 
          continue to meet their evolving needs. "
        >
          <svg
            width="65"
            height="63"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
              fill="#FAE4D2"
            />
            <path d="M5 14H20" stroke="#E75125" />
            <path d="M8 19H17" stroke="#E75125" stroke-linecap="round" />
            <circle
              cx="10.8235"
              cy="5.76471"
              r="1.32353"
              stroke="#252744"
              stroke-width="0.882353"
            />
            <path
              d="M14 13L13.5 11L13.5581 9.90789C13.6064 8.99887 12.8824 8.23529 11.9721 8.23529H9.58824C8.71108 8.23529 8 8.94637 8 9.82353V13"
              stroke="#252744"
              stroke-width="0.794118"
              stroke-linecap="round"
            />
            <path
              d="M11.8824 11L12.5 13"
              stroke="#252744"
              stroke-width="0.705882"
              stroke-linecap="round"
            />
            <path
              d="M19 6C19.5523 6 20 6.44772 20 7V16C20 16.5523 19.5523 17 19 17H6C5.44772 17 5 16.5523 5 16V7C5 6.44771 5.44772 6 6 6H8"
              stroke="#E75125"
              stroke-linecap="round"
            />
            <path d="M19 6H14" stroke="#E75125" stroke-linecap="round" />
            <path d="M15 17V19" stroke="#E75125" stroke-linecap="round" />
            <rect x="16" y="10" width="2" height="3.5" fill="#252744" />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17 11C18.1046 11 19 10.1046 19 9C19 8.25972 18.5978 7.61337 18 7.26756V8L17 9L16 8V7.26756C15.4022 7.61337 15 8.25972 15 9C15 10.1046 15.8954 11 17 11Z"
              fill="#252744"
            />
            <path
              d="M9.5 11V13"
              stroke="#252744"
              stroke-width="0.705882"
              stroke-linecap="round"
            />
            <path d="M10 17V18.5" stroke="#E75125" stroke-linecap="round" />
          </svg>
        </WhyBytesberryCardHome>

        <WhyBytesberryCardHome
          title="Cost-effectiveness"
          description="By leveraging industry-standard processes and some of the 'best-of-breed' technologies, we are able to offer solutions to our clients that not only meet their business needs but also fit their budgets."
        >
          <svg
            width="52"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="12" cy="12" r="12" fill="#FAE4D2" />
            <path
              d="M6.5 14.5C6.83333 13.8333 8.1 12.7 10.5 13.5C10.7971 13.6485 11.5179 13.8501 12.2375 13.6089C12.417 13.5488 12.5901 13.47 12.7697 13.4101L14 13L15.0299 12.7425C15.5226 12.6193 16 12.992 16 13.5V13.5M12.5 15L15.5192 14.1374C15.8038 14.0561 16 13.796 16 13.5V13.5M16 13.5L19 12C19.3333 11.8333 21 11.2 21 12C19.1667 13.8333 14.9 17.4 14.5 17"
              stroke="#E75125"
              stroke-width="1.13"
            />
            <path
              d="M7.5 17.5C7.68486 17.1764 8.24065 16.4322 9.01728 16.8759C10.1637 17.2733 12.5373 17.9412 15 16.8759"
              stroke="#E75125"
              stroke-width="1.13"
            />
            <path d="M9.5 4H14.5" stroke="#252744" stroke-width="1.13" />
            <path d="M9.5 6H14.5" stroke="#252744" stroke-width="1.13" />
            <path
              d="M11 4C13 4.5 14.5 7 11 8L14 11"
              stroke="#252744"
              stroke-width="1.13"
            />
            <path
              d="M7.66893 17.5896L6.33263 13.9147C6.14749 13.4056 5.59057 13.1365 5.07661 13.3078L3.5 13.8333L5.5 19.5L7.10053 18.8598C7.60185 18.6593 7.85345 18.097 7.66893 17.5896Z"
              stroke="#E75125"
              stroke-width="1.13"
            />
          </svg>
        </WhyBytesberryCardHome>

        <WhyBytesberryCardHome
          title="Customer satisfaction"
          description="With a focus on delivering results that meet our clients' needs and exceed their expectations, our team is committed to ensuring 
          customer satisfaction with every project."
        >
          <svg
            width="52"
            height="64"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 18.5485V12.4272C10 11.9084 9.57944 11.4879 9.06065 11.4879H6V19.4879H9.06065C9.57944 19.4879 10 19.0673 10 18.5485ZM10 18.5485C11.5 19.0485 15.7667 20.0001 18.1667 19.6001M10 13.0485L11.5 7.38974C11.6595 6.99529 12.1479 6.93101 12.5951 7.06063C13.0233 7.18477 13.2234 7.63296 13.2708 8.07631C13.3056 8.40133 13.3394 8.73202 13.3394 8.793L13.5 10.2128L13.5798 10.4522C13.8344 11.216 14.5222 11.7527 15.325 11.8139L19.5413 12.1356C20.064 12.1132 20.5 12.5308 20.5 13.0539C20.5 13.5616 20.0885 13.9731 19.5809 13.9731H18.1667M18.2752 17.7305H18.819C19.2566 17.7305 19.6362 17.4283 19.7344 17.0019L19.7643 16.8717C19.8873 16.3374 19.4815 15.8274 18.9331 15.8274H18.2752M18.5471 17.7305L18.0358 17.7305C18.7234 17.7305 19.178 18.4451 18.8866 19.068C18.7321 19.3982 18.4004 19.6092 18.0358 19.6092M19.5667 13.9732L19.608 13.9875C20.0732 14.148 20.3277 14.6481 20.1837 15.1186C20.0859 15.4379 19.8218 15.6784 19.4948 15.7459L19.1 15.8274"
              stroke="#E75125"
              stroke-width="1.08333"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12.8277 2.3208L12.1053 1L11.3799 2.3261L10 2.33115L10.7056 3.55899L10.0542 4.75H11.39L12.1083 6.00001L12.8153 4.75H14.1564L13.4971 3.5446L14.1921 2.3158L12.8277 2.3208Z"
              fill="#252744"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.81056 5.10066L7.09251 4L6.37158 5.10508L5 5.10929L5.70132 6.13249L5.05382 7.125H6.38159L7.09557 8.16667L7.79827 7.125H9.13121L8.47589 6.1205L9.16667 5.0965L7.81056 5.10066Z"
              fill="#252744"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.8106 5.10066L17.0925 4L16.3716 5.10508L15 5.10929L15.7013 6.13249L15.0538 7.125H16.3816L17.0956 8.16667L17.7983 7.125H19.1312L18.4759 6.1205L19.1667 5.0965L17.8106 5.10066Z"
              fill="#252744"
            />
            <path
              d="M24 11.5C24 17.8513 18.6274 23 12 23C5.37258 23 0 17.8513 0 11.5C0 5.14873 5.37258 0 12 0C18.6274 0 24 5.14873 24 11.5Z"
              fill="#F9A44F"
              fill-opacity="0.24"
            />
          </svg>
        </WhyBytesberryCardHome>
      </div>

      <style>
        {`
            svg {
              transition: transform 0.25s ease-in-out;
            }
           .card-region:hover svg {
              transform: scale(1.25, 1.25);
            }
          `}
      </style>
    </>
  );
};

export default WhyBytesberry;

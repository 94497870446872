import React, { useState, useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import loginSikkimTourism from "../Assets/LoginSikkimTourism.jpeg";
import dashboardSikkimTourism from "../Assets/dashboradSikkimTourism.jpeg";
import dataprofiling from "../Assets/dataProfiling.jpeg";
import servicerReg from "../Assets/servicerReg.jpeg";
import registrationForm from "../Assets/serviceRegistration.jpeg";
import travelcardpage from "../Assets/TravelCard.jpeg";
import travelcard from "../Assets/travelCard2.jpeg";
import tourismLogin from "../Assets/tourism_login.png";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import GenericModal from "../reusable-components/modal/GenericModal";

import tourism_banner from "../Assets/tour.jpg";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

export default function ProjectSikkimTourism() {
  const bannerSikkimTourismPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSikkimTourismPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSikkimTourismPage?.current?.offsetHeight);
    }
  }, [bannerSikkimTourismPage?.current?.offsetHeight]);

  const [activeTab, setActiveTab] = useState("cms");
  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState("");
  // bg-[#E75124]
  const activetab = "bg-[#252744] text-white";
  const non_activetab = "bg-[#D9D9D9]";

  const changeTab = (projects) => {
    setActiveTab(projects);
  };

  return (
    <>
      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        // title={"Images of Sikkim Tourism  Content Management  System"}
        isAdd={true}
        isLarge={true}
      >
        <div className="my-4">
          {clickedImage === "tourism_cms_image1" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Login Page for Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={tourismLogin}
                alt="loginSikkimTourism"
                className="h-full pt-4"
              />
            </div>
          ) : null}

          {clickedImage === "tourism_cms_image2" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Dashboard for Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={dashboardSikkimTourism}
                alt="dashboardSikkimTourism"
                className=" pt-4"
              />
            </div>
          ) : null}

          {clickedImage === "tourism_cms_image3" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Data Profiling Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={dataprofiling}
                alt="dataprofiling"
                className="h-full pt-4"
              />
            </div>
          ) : null}
        </div>

        <div className="my-4">
          {clickedImage === "service_registration_image1" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">Profile Listing</p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={servicerReg} alt="servicerReg" className=" pt-4 " />
            </div>
          ) : null}

          {clickedImage === "service_registration_image2" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Registration From for Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={registrationForm}
                alt="registrationForm"
                className="h-full pt-4"
              />
            </div>
          ) : null}
          {/* 
          {clickedImage === "service_registration_image3" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Service Registration for Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={service_registration}
                alt="tourism_cms"
                className="h-full pt-4"
              />
            </div>
          ) : null} */}
        </div>

        <div className="my-4">
          {clickedImage === "travel_card_image1" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">Page for Travel Card</p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={travelcardpage}
                alt="travelcardpage"
                className="h-full pt-4"
              />
            </div>
          ) : null}

          {clickedImage === "travel_card_image2" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Registration form for Travel Card
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={travelcard} alt="travelcard" className="h-full pt-4" />
            </div>
          ) : null}
          {/* 
          {clickedImage === "travel_card_image3" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative  ">
                <p className="text-white text-center">
                  Service Registration for Sikkim Tourism
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={travel_card}
                alt="tourism_cms"
                className="h-full pt-4"
              />
            </div>
          ) : null} */}
        </div>
      </GenericModal>

      <div className="relative" ref={bannerSikkimTourismPage}>
        <img
          src={tourism_banner}
          alt="tourism_banner"
          className=" h-72 lg:h-[500px] w-full object-cover"
        />
        <div className="bg-black h-72 lg:h-[500px] w-full object-cover absolute top-0  bg-opacity-75 "></div>
        <div>
          <p className="absolute top-1/2 -translate-y-2/4 left-10 text-white">
            <Heading>Sikkim Tourism </Heading>
            <SubHeading>Web Portal</SubHeading>
          </p>
          <span className="px-4  py-1 md:px-12 bg-primary text-white absolute bottom-[20%] left-10  lg:bottom-4  cursor-pointer hover:underline  ">
            <a href="https://www.sikkimtourism.gov.in/" target="_blank">
              Visit Website
            </a>
          </span>
        </div>
      </div>

      <div className="flex justify-end">
        <a
          href="/our-projects"
          className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
        >
          View All Projects
        </a>
      </div>

      <div className="w-[75%] mx-auto   ">
        <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
          Sikkim Tourism Web Portal
        </Heading>

        <p className="text-justify md:text-center text-base 2xl:text-[22px] my-8 mx-4 font-medium  text-gray-700 ">
          This is the official Web Portal for the Tourism and Civil Aviation
          department, Government of Sikkim with exemplary online information and
          services. Few of the exclusive feature of the portal includes:
        </p>
      </div>

      <div className="w-[75%] mx-auto text-sm  2xl:text-[20px] pt-4">
        <div className="flex flex-col md:flex-row md:gap-8">
          <span
            className={`${
              activeTab === "cms" ? activetab : non_activetab
            } cursor-pointer px-2 py-2.5 md:px-4 md:py-3 text-center `}
            onClick={() => changeTab("cms")}
          >
            Content Management system
          </span>

          <span
            className={`${
              activeTab === "service registration" ? activetab : non_activetab
            } cursor-pointer px-2 py-2.5 md:px-4 md:py-3 mt-4 md:mt-0 text-center`}
            onClick={() => changeTab("service registration")}
          >
            Service provider registration
          </span>

          <span
            className={`${
              activeTab === "Travel Card" ? activetab : non_activetab
            } cursor-pointer px-2 py-2.5 md:px-4 md:py-3 mt-4  md:mt-0 md:w-[20%] text-center`}
            onClick={() => changeTab("Travel Card")}
          >
            Travel Card
          </span>
        </div>

        {activeTab === "cms" ? (
          <>
            <div className="text-gray-700">
              <h3 className="font-semibold underline mt-10 text-gray-800 ">
                Content Management system to manage the following:
              </h3>
              <ul className="pl-12 list-disc pt-4">
                <li className="leading-7">
                  Destinations – Popular places & attractions
                </li>
                <li className="pt-2 leading-7">
                  Tourism related Notifications & Circulars from the Government.
                </li>
                <li className="pt-2 leading-7">Important links</li>
                <li className="pt-2 leading-7">
                  Things to do – Interests, Activities/Outdoors & Popular
                  itineraries
                </li>
                <li className="pt-2 leading-7">
                  Traveler essentials – Government Affiliations, Entry
                  formalities & Traveller tips
                </li>
                <li className="pt-2 leading-7"> Experience Sikkim</li>
                <li className="pt-2 leading-7">
                  {" "}
                  Service provider details – Entity profiles
                </li>
              </ul>
            </div>
            <p className="pt-8 font-medium underline underline-offset-2 text-gray-800">
              Images of Sikkim Tourism Content Management System
            </p>

            <div>
              <div className="my-5 flex flex-col md:flex-row items-center gap-8 pt-5 ">
                <div
                  className="flex flex-col gap-4 cursor-pointer "
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("tourism_cms_image1");
                  }}
                >
                  <img
                    src={tourismLogin}
                    alt="loginSikkimTourism"
                    className="h-56 border w-[500px] shadow-2xl"
                  />
                  <span className="text-xs text-center italic">
                    Login Page for Sikkim Tourism
                  </span>
                </div>
                <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("tourism_cms_image2");
                  }}
                >
                  <img
                    src={dashboardSikkimTourism}
                    alt="dashboardSikkimTourism"
                    className="h-56   border shadow-2xl"
                  />
                  <span className="text-xs text-center italic">
                    Dashboard for Sikkim Tourism
                  </span>
                </div>
                <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("tourism_cms_image3");
                  }}
                >
                  <img
                    src={dataprofiling}
                    alt="dataprofiling"
                    className="h-56 shadow-2xl"
                  />
                  <span className="text-xs text-center italic">
                    Data Profiling Sikkim Tourism
                  </span>
                </div>{" "}
                *
              </div>
            </div>
          </>
        ) : null}

        {activeTab === "service registration" ? (
          <>
            <div className="text-gray-700">
              <h3 className="font-semibold underline mt-10 text-gray-800">
                Service provider registration:
              </h3>
              <p className="pt-6 text-justify">
                All Tourism units, accommodation units, travel operators and
                service providers will have the option to register their entity
                in the portal. After online registration, they will receive
                login credentials to the email address used while registration.
                They can then login and edit the following details:
              </p>
              <ul className="pl-8 list-disc pt-4">
                <li className="pt-2">Edit entity profile</li>
                <li className="pt-2">Manage inventory</li>
                <li className="pt-2">Update Media gallery</li>
                <li className="pt-2">
                  Manage room information (For Hotels, Home stays & Lodges)
                </li>
                <li className="pt-2">Daily Tour itinerary management.</li>
              </ul>
            </div>
            <p className="pt-4 font-medium underline underline-offset-2 text-gray-800">
              Images of Sikkim Tourism Service Provider Registration
            </p>

            <div>
              <div className="my-5 flex flex-col md:flex-row items-center gap-8 pt-5">
                <div
                  className="flex flex-col gap-4 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("service_registration_image1");
                  }}
                >
                  <img
                    src={servicerReg}
                    alt="servicerReg"
                    className="h-56 w-[300px] border shadow-lg"
                  />
                  <span className="text-xs text-center italic">
                    Profile Listing
                  </span>
                </div>

                <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("service_registration_image2");
                  }}
                >
                  <img
                    src={registrationForm}
                    alt="registrationForm"
                    className="h-56 "
                  />
                  <span className="text-xs text-center italic">
                    Registration Form for Sikkim Tourism
                  </span>
                </div>
                {/* <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("service_registration_image3");
                  }}
                >
                  <img
                    src={service_registration}
                    alt="tourism_cms"
                    className="h-56"
                  />
                  <span>Registration Form for Sikkim Tourism</span>
                </div> */}
              </div>
            </div>
          </>
        ) : null}

        {activeTab === "Travel Card" ? (
          <>
            <div className="text-gray-700">
              <h3 className="font-semibold underline mt-10 text-gray-800">
                Travel Card:
              </h3>
              <p className="pt-6 text-justify">
                The Government wanted to have a central repository of data
                concerning Tourists visiting Sikkim after the COVID-19 pandemic
                and that is how Travel card came into picture. Tourists are
                required to fill in requisite details which are as follows:
              </p>
              <ul className="pl-12 list-disc pt-4">
                <li>Personal details</li>
                <li>Tour details</li>
                <li>Additional traveller details</li>
              </ul>
              <p className="pt-4 text-justify">
                After filling all the above-mentioned sections, tourists will
                get a link in the registered mobile number and email address
                wherein they can download the Travel card which has to be
                presented where ever required. The travel card will come with a
                unique 12-digit code (E.g., TO22A0221780) and a QR for the same
                will also be present in the travel card for convenience.
              </p>
            </div>
            <p className="pt-4 font-medium underline underline-offset-2 text-gray-800">
              Images of Sikkim Tourism Travel Card
            </p>
            <div>
              <div className="my-5 flex flex-col md:flex-row items-center gap-8 pt-5 text-gray-700">
                <div
                  className="flex flex-col gap-4 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("travel_card_image1");
                  }}
                >
                  <img
                    src={travelcardpage}
                    alt="travelcardpage"
                    className="h-56 border shadow-lg "
                  />
                  <span className="text-xs text-center italic">
                    Page for Travel Card
                  </span>
                </div>

                <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("travel_card_image2");
                  }}
                >
                  <img
                    src={travelcard}
                    alt="travelcard"
                    className="h-56 w-3/4 "
                  />
                  <span className="text-xs text-center italic">
                    Registration Form for Travel Card
                  </span>
                </div>

                {/* <div
                  className="flex flex-col gap-2 cursor-pointer"
                  onClick={() => {
                    setShowModal(true);
                    setClickedImage("travel_card_image3");
                  }}
                >
                  <img src={travel_card} alt="tourism_cms" className="h-64" />
                  <span>Registration Form for Sikkim Tourism</span>
                </div> */}
              </div>
            </div>
          </>
        ) : null}

        <SubHeading classes={`  mb-5 text-gray-800]`}>
          Technology Used
        </SubHeading>

        <div className="flex flex-row my-10">
          <>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
            >
              ASP.NET
            </div>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
            >
              Bootstrap
            </div>
            <div
              className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white "
            >
              MS SQL
            </div>
          </>
        </div>
      </div>
    </>
  );
}

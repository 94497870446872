import React from "react";

import bytesberrylogofooter from "../../Assets/bb_logo_footer_new.png";
import bytesberrylogo from "../../Assets/bytesberrylogo.png";
import { Link, NavLink } from "react-router-dom";
import CallToAction from "../../Pages/CallToAction";

export default function Footer() {
  return (
    <>
      {/* Desktop Footer */}

      <div className="bg-secondary hidden lg:block relative">
        <div className="absolute right-0">
          <svg
            width="552"
            height="427"
            viewBox="0 0 552 427"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M342 231.588C248.75 80.7637 74.018 13.2248 0 0H241.737C261.831 28.8076 312.721 118.162 342 231.588Z"
              fill="#493854"
            />
            <path
              d="M552 310.029C399.296 289.608 347.707 246.834 341 228C341 297.786 481.667 389.744 552 427V310.029Z"
              fill="#E75124"
            />
          </svg>
        </div>

        <div className="pt-16 flex justify-center">
          <Link to="/contact-us-v2">
            <div className="bg-[#8555C9] px-5 py-4 text-white font-medium rounded-full cursor-pointer">
              Start Your Project
            </div>
          </Link>
        </div>

        <div className="pt-16 pb-8 flex justify-center">
          <div className="flex items-center border-r border-[#F39174] gap-6">
            <div className="pb-3">
              <a href="/">
                <img
                  src={bytesberrylogofooter}
                  alt="Bytesberry Logo"
                  className="w-auto h-20"
                />
              </a>
            </div>

            <div className="text-base pr-10 xl:text-2xl text-white font-medium text-center">
              <p>Bytesberry</p>
              <p>Technologies</p>
            </div>
          </div>
          <div className="text-white text-sm pl-10 flex flex-col justify-center items-center">
            <p>Software Development</p> <p>& Technology Consulting</p>
          </div>
        </div>

        <div className="w-[65%] mx-auto pt-6 flex justify-between pb-6 border-b border-[#F39174]">
          <div className="p-4 lg:p-5">
            <div className="text-sm lg:text-base xl:text-lg text-[#F39174] font-bold pb-4">
              <span className="pb-2 border-b-2 border-[#F39174]">
                Navigation Links
              </span>
            </div>
            <ul>
              <li className="my-1 lg:my-2">
                <a
                  className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]"
                  href="/"
                >
                  Home
                </a>
              </li>

              <li className="my-1 lg:my-2">
                <a
                  className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]"
                  href="/our-projects"
                >
                  Projects
                </a>
              </li>

              <li className="my-1 lg:my-2">
                <a
                  className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]"
                  href="/contact-us-v2"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="p-4 lg:p-5">
            <div className="text-sm lg:text-base xl:text-lg text-[#F39174] font-bold pb-4">
              <span className="pb-2 border-b-2 border-[#F39174]">
                Our Services
              </span>
            </div>
            <ul>
              <li className="my-1 lg:my-2">
                <Link to="/our-services#service1">
                  <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                    Websites / Web Apps / Portals
                  </p>
                </Link>
              </li>
              <li className="my-1 lg:my-2">
                <Link to="/our-services#service2">
                  <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                    In-Premise Applications
                  </p>
                </Link>
              </li>
              <li className="my-1 lg:my-2">
                <Link to="/our-services#service3">
                  <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                    Mobile Solutions
                  </p>
                </Link>
              </li>
              <li className="my-1 lg:my-2">
                <Link to="/our-services#service4">
                  <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                    e-Governance Solutions
                  </p>
                </Link>
              </li>
              <li className="my-1 lg:my-2">
                <Link to="/our-services#service5">
                  <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                    e-Commerce Solutions
                  </p>
                </Link>
              </li>
              <li className="my-1 lg:my-2">
                <p className="hover:text-slate-300 text-xs lg:text-sm text-[#fcfcfc]">
                  <li className="my-1 lg:my-2">
                    <Link to="/our-services#service5">
                      <p className="hover:text-slate-300 text-xs lg:text-sm xl:text-base text-[#fcfcfc]">
                        Software Consultancy
                      </p>
                    </Link>
                  </li>
                </p>
              </li>
            </ul>
          </div>
          <div className="p-4 lg:p-5 mb-5">
            <div className="text-sm lg:text-base xl:text-lg text-[#F39174] font-bold pb-6">
              <span className="pb-2 border-b-2 border-[#F39174]">
                Stay Connected
              </span>
            </div>
            {/* Social Media Buttons */}
            <div className="lg:w-full flex items-center">
              <a
                href="https://www.facebook.com/bytesberry/"
                type="button"
                className=" hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#3B82F6] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6  lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="facebook-f"
                  className="w-2 xl:w-3 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                >
                  <path
                    fill="currentColor"
                    d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.google.com/search?q=bytesberry+technologies&oq=bytesberry+technologies&aqs=chrome.0.69i59l2j0i546l3j69i60l3.6000j0j7&sourceid=chrome&ie=UTF-8"
                type="button"
                className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#EF4444] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="google"
                  className="w-3 xl:w-4 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 488 512"
                >
                  <path
                    fill="currentColor"
                    d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.instagram.com/bytesberry_technologies"
                type="button"
                className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#F60373] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="instagram"
                  className="w-3 xl:w-4 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.linkedin.com/company/99880651"
                type="button"
                target="_blank"
                className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#2563EB] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                rel="noreferrer"
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fab"
                  data-icon="linkedin-in"
                  className="w-3 xl:w-4 h-full mx-auto"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                >
                  <path
                    fill="currentColor"
                    d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                  ></path>
                </svg>
              </a>
            </div>
          </div>
        </div>

        <div className="py-6 bg-secondary">
          <div className="bg-secondary grid grid-cols-1 lg:grid-cols-3 p-1">
            <div className="flex justify-center items-center py-1">
              {/* <img src={bytesberrylogo} alt="Bytesberry Logo" className="h-6" /> */}
            </div>
            <div className="flex justify-center items-center py-1">
              <p className="text-white text-xs xl:text-sm">
                © Copyright {new Date().getFullYear()}, All Rights Reserved By
                Bytesberry Technologies
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile and Tablets Footer */}

      <div className="bg-secondary block lg:hidden ">
        <div className="relative h-28">
          <div className="pt-20 flex justify-center absolute z-30  translate-x-1/2  right-[50%]  transform">
            <Link to="/contact-us-v2">
              <div className="bg-[#8555C9] px-2.5 py-2 text-white text-xs font-medium rounded-full cursor-pointer  ">
                Start Your Project
              </div>
            </Link>
          </div>

          <div className="absolute z-10 right-0 top-0">
            <svg
              width="230"
              height="180"
              viewBox="0 0 552 427"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              pointerEvents="none"
            >
              <path
                d="M342 231.588C248.75 80.7637 74.018 13.2248 0 0H241.737C261.831 28.8076 312.721 118.162 342 231.588Z"
                fill="#493854"
              />
              <path
                d="M552 310.029C399.296 289.608 347.707 246.834 341 228C341 297.786 481.667 389.744 552 427V310.029Z"
                fill="#E75124"
              />
            </svg>
          </div>
        </div>

        <div className="pt-9">
          <div className="flex justify-center pb-6">
            <div className="flex gap-2 items-center text-center border-r border-[#F39174] pr-2 md:pr-4">
              <div className="pb-3">
                <a href="/" className="flex justify-center">
                  <img
                    src={bytesberrylogofooter}
                    alt="Bytesberry Logo"
                    className="w-auto h-10"
                  />
                </a>
              </div>
              <div class="text-sm text-white font-semibold">
                <p>Bytesberry</p> <p>Technologies</p>
              </div>
            </div>
            <div className="text-white text-xs pl-2 md:pl-4 flex flex-col justify-center items-center">
              <p>Software Development</p> <p>& Technology Consulting</p>
            </div>
          </div>

          <div className="grid grid-cols-2 md:flex md:justify-between w-[85%] md:w-[80%] mx-auto pb-6 border-b border-[#F39174]">
            <div className="py-4">
              <div className="text-xs lg:text-base 2xl:text-lg text-[#F39174] font-bold pb-4">
                <span className="pb-2 border-b-2 border-[#F39174]">
                  Navigation Links
                </span>
              </div>
              <ul>
                <li className="my-1 lg:my-2">
                  <a
                    className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]"
                    href="/"
                  >
                    Home
                  </a>
                </li>

                <li className="my-1 lg:my-2">
                  <a
                    className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]"
                    href="/our-projects"
                  >
                    Projects
                  </a>
                </li>

                <li className="my-1 lg:my-2">
                  <a
                    className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]"
                    href="/contact-us-v2"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="py-4 lg:p-5">
              <div className="text-xs lg:text-base 2xl:text-lg text-[#F39174] font-bold pb-4">
                <span className="pb-2 border-b-2 border-[#F39174]">
                  Our Services
                </span>
              </div>
              <ul>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service1">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      Websites / Portals
                    </p>
                  </Link>
                </li>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service2">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      In-Premise Applications
                    </p>
                  </Link>
                </li>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service3">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      Mobile Solutions
                    </p>
                  </Link>
                </li>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service4">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      e-Governance Solutions
                    </p>
                  </Link>
                </li>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service5">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      e-Commerce Solutions
                    </p>
                  </Link>
                </li>
                <li className="my-1 lg:my-2">
                  <Link to="/our-services#Service6">
                    <p className="hover:text-slate-300 text-xs lg:text-sm 2xl:text-base text-[#fcfcfc]">
                      Software Consultancy
                    </p>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="py-4 lg:p-5 mb-5">
              <div className="text-xs lg:text-base xl:text-lg text-[#F39174] font-bold pb-6">
                <span className="pb-2 border-b-2 border-[#F39174]">
                  Stay Connected
                </span>
              </div>
              {/* Social Media Buttons */}
              <div className="lg:w-full flex items-center">
                <a
                  href="https://www.facebook.com/bytesberry/"
                  type="button"
                  className=" hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#3B82F6] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6  lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="facebook-f"
                    className="w-2 xl:w-3 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                  >
                    <path
                      fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.google.com/search?q=bytesberry+technologies&oq=bytesberry+technologies&aqs=chrome.0.69i59l2j0i546l3j69i60l3.6000j0j7&sourceid=chrome&ie=UTF-8"
                  type="button"
                  className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#EF4444] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="google"
                    className="w-3 xl:w-4 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 488 512"
                  >
                    <path
                      fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.instagram.com/bytesberry_technologies"
                  type="button"
                  className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#F60373] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="instagram"
                    className="w-3 xl:w-4 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                    ></path>
                  </svg>
                </a>

                <a
                  href="https://www.linkedin.com/company/99880651"
                  type="button"
                  className="hover:text-white hover:border-0 rounded-full border lg:border-2 border-white text-white leading-normal uppercase hover:bg-[#2563EB] focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 lg:w-9 lg:h-9 xl:w-11 xl:h-11 m-1"
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    className="w-3 xl:w-4 h-full mx-auto"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="py-3 bg-secondary">
          <div className="bg-secondary grid grid-cols-1 lg:grid-cols-3 p-1">
            <div className="flex justify-center items-center py-1">
              {/* <img src={bytesberrylogo} alt="Bytesberry Logo" className="h-6" /> */}
            </div>
            <div className="flex justify-center items-center py-1">
              <p className="text-white text-center text-xs xl:text-sm">
                © Copyright {new Date().getFullYear()}, All Rights Reserved By
                Bytesberry Technologies
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useRef, useEffect } from "react";
import Iframe from "react-iframe";
import ContactUsBlock from "../reusable-components/ContactUsBlock";
import ContactUsBlockMobile from "../reusable-components/ContactUsBlockMobile";

import { useLogo } from "../context/LogoContext";

import "./FAQ.css";

import contactusphoto from "../../src/Assets/bb_contactus.jpg";
import { useLocation } from "react-router-dom";

const ContactUsv2 = () => {
  const bannerContactUsPage = useRef();

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerContactUsPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerContactUsPage?.current?.offsetHeight);
    }
  }, [bannerContactUsPage?.current?.offsetHeight]);

  return (
    <>
      <div className="bg-[#252744] bg-opacity-80">
        <div className="flex flex-col justify-center items-center">
          <div className="bg-[#FFE9DC] h-[50%] w-full pb-40">
            <div className="relative" ref={bannerContactUsPage}>
              <div className="hidden lg:block">
                <img
                  src={contactusphoto}
                  className="h-[500px] w-full object-cover"
                />
              </div>
              <div className="block lg:hidden">
                <img
                  src={contactusphoto}
                  className="h-[288px] w-full object-left-top object-cover"
                />
              </div>
              <div className="absolute top-1/3 left-[10%] md:left-[15%] lg:left-[35%]">
                <p className="flex justify-center text-3xl lg:text-4xl font-semibold text-white pb-4">
                  Get in Touch
                </p>
                <p className="hidden md:block text-center text-sm lg:text-xl font-normal text-white w-[95%] md:w-[60%] lg:w-[100%] mx-auto">
                  Got a project in mind? We’d love to hear about it.
                </p>
              </div>
            </div>
            <div className="w-full my-28">
              {/* <p className="flex justify-center text-3xl lg:text-4xl font-semibold text-gray-700 pb-4">
                Get in Touch
              </p>
              <p className="text-center text-base lg:text-xl font-medium text-gray-500 w-[80%] lg:w-[60%] mx-auto">
                Got a project in mind? We’d love to hear about it. Take five
                minutes to fill out our project form so that we can get to know
                you and understand your project.
              </p> */}
            </div>
            <div className="block md:hidden -mt-10 mb-10">
              <p className="text-center text-base font-medium text-gray-500 w-[80%] mx-auto">
                Got a project in mind? We’d love to hear about it.
              </p>
            </div>
          </div>
          <div className="bg-white h-[50%] w-full pb-28 flex flex-row justify-center">
            <div className="w-[80%] px-24 hidden xl:block">
              <ContactUsBlock />
            </div>
            <div className="w-[80%] block xl:hidden">
              <ContactUsBlockMobile />
            </div>
          </div>

          {/* Map Large Devices */}
          <div className="iframe-container-large hidden xl:block">
            <div className="overflow-x-scroll lg:overflow-x-hidden">
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d443.0434873413098!2d88.613123!3d27.33359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb211924c5b2dccc!2sBytesberry%20Technology!5e0!3m2!1sen!2sin!4v1672312998215!5m2!1sen!2sin"
                // width="100%"
                // height="100%"
                className="responsive-iframe"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>

          {/* Map Small Devices */}
          <div className="iframe-container-small block xl:hidden">
            <div className="overflow-x-scroll lg:overflow-x-hidden">
              <Iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d443.0434873413098!2d88.613123!3d27.33359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb211924c5b2dccc!2sBytesberry%20Technology!5e0!3m2!1sen!2sin!4v1672312998215!5m2!1sen!2sin"
                // width="100%"
                // height="100%"
                className="responsive-iframe"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUsv2;

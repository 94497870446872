import { useEffect, useState } from "react";
import axios from "axios";

const useAxios = (url) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  // console.log(`${process.env.REACT_APP_ENDPOINT_URL}${url}`);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_ENDPOINT_URL}${url}`
        );
        // console.log("axios res", res);
        setData(res.data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
        // console.error("err", error);
      }
    };
    fetchData();
  }, [url]);

  return { data, error, loading };
};

export default useAxios;

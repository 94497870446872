/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useLogo } from "../context/LogoContext";

import ourservicesphoto from "../../src/Assets/ourprojects.png";
import Heading from "../reusable-components/fonts/Heading";
import ServicesCardImageLeft from "../reusable-components/ServicesCardImageLeft";
import ServicesCardImageRight from "../reusable-components/ServicesCardImageRight";
import ServicesCardMobile from "../reusable-components/ServicesCardMobile";

import websitephoto from "../Assets/web application.jpg";
import inpremiseappphoto from "../Assets/In Premise application.jpg";
import mobilesolutionsphoto from "../Assets/mobile solution.jpg";
import egovphoto from "../Assets/e governance.jpg";
import ecomphoto from "../Assets/e-commerc.jpg";
import softwareconsultancyphoto from "../Assets/consultancy.jpg";
import seoconsultancyphoto from "../Assets/SEO.jpg";
import chatbotsphoto from "../Assets/chatbots.jpg";
import softskillsphoto from "../Assets/softskill.jpg";

const Services = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const bannerOurServicesPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerOurServicesPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerOurServicesPage?.current?.offsetHeight);
    }
  }, [bannerOurServicesPage?.current?.offsetHeight]);

  return (
    <>
      <section className="pb-24 lg:pb-36">
        <div className="relative" ref={bannerOurServicesPage}>
          <div className="bg-black opacity-60  h-72 lg:h-[500px] w-full object-cover absolute"></div>
          <img
            src={ourservicesphoto}
            alt="Our Services photo"
            className="h-72 lg:h-[500px] w-full object-cover"
          />
          <div className="absolute top-1/2 -translate-y-2/4 left-10">
            <Heading classes={`text-white`}>Our Services</Heading>
          </div>
        </div>
        {/* Desktop and Tablet */}
        <div className="hidden md:block mt-20">
          <div id="service1">
            <ServicesCardImageRight
              title="Websites / Web Apps / Portals"
              description="We design and develop custom websites, web apps, and portals to meet your unique business needs. 
              Our team of experts bring expertise and creativity to each project. 
              Let us help you succeed online."
              serviceImage={websitephoto}
            />
          </div>

          <div id="service2">
            <ServicesCardImageLeft
              title="In-Premise Applications"
              description="We build In-Premise software applications that offer tailored & secured solutions. 
              These are built with an ability to control your environment, 
              security and data so that you have complete peace of mind while running your business."
              serviceImage={inpremiseappphoto}
            />
          </div>

          <div id="service3">
            <ServicesCardImageRight
              title="Mobile Solutions"
              description="Mobile solutions from our team help businesses reach customers on-the-go. 
              We design and develop custom mobile apps that provide seamless, user-friendly experiences on any device. 
              Enhance your brand and reach with a mobile solution from us."
              serviceImage={mobilesolutionsphoto}
            />
          </div>

          <div id="service4">
            <ServicesCardImageLeft
              title="e-Governance Solutions"
              description="E-governance solutions from us streamline government processes and improve citizen engagement. 
              We design and develop custom e-governance solutions that increase efficiency, transparency, and accessibility. 
              Partner with us to modernize your government operations and enhance citizen engagement."
              serviceImage={egovphoto}
            />
          </div>

          <div id="service5">
            <ServicesCardImageRight
              title="e-Commerce Solutions"
              description="We design and develop custom e-commerce websites and apps that provide seamless, 
              user-friendly experiences for customers. Grow your business with an e-commerce solution from us."
              serviceImage={ecomphoto}
            />
          </div>

          <div id="service6">
            <ServicesCardImageLeft
              title="Software Consultancy"
              description="Software consultancy from our team helps businesses optimize and innovate. 
              We provide expert advice and guidance on software development, design, and strategy. 
              Partner with us for a competitive edge through innovative software solutions."
              serviceImage={softwareconsultancyphoto}
            />
          </div>

          <div id="service7">
            <ServicesCardImageRight
              title="Search Engine Optimization (SEO) Consultancy"
              description="With SEO solutions, we help you drive traffic and improve online visibility. 
              We provide customized SEO strategies that help businesses rank higher in search engine results and reach more potential customers. 
              Increase your online visibility with SEO solutions from us."
              serviceImage={seoconsultancyphoto}
            />
          </div>

          <div id="service8">
            <ServicesCardImageLeft
              title="Chatbots"
              description="Chatbots help automate customer interactions and improve efficiency. 
              We design and develop custom chatbots that provide quick, seamless, and personalized experiences for customers. 
              Enhance customer engagement and streamline operations with a chatbot from us."
              serviceImage={chatbotsphoto}
            />
          </div>

          <div id="service9">
            <ServicesCardImageRight
              title="Soft Skills Training"
              description="Soft skill training from our team helps individuals and organizations thrive. 
              We provide customized soft skill training programs that improve communication, teamwork, leadership, and more. 
              Enhance your professional growth and success with soft skill training from us."
              serviceImage={softskillsphoto}
            />
          </div>
        </div>

        {/* Mobile */}
        <div className="block md:hidden mt-20">
          <div id="Service1">
            <ServicesCardMobile
              title="Websites / Web Apps / Portals"
              description="We design and develop custom websites, web apps, and portals to meet your unique business needs. 
              Our team of experts bring expertise and creativity to each project. 
              Let us help you succeed online."
              serviceImage={websitephoto}
            />
          </div>
          <div id="Service2">
            <ServicesCardMobile
              title="In-Premise Applications"
              description="We build In-Premise software applications that offer tailored & secured solutions. 
              These are built with an ability to control your environment, 
              security and data so that you have complete peace of mind while running your business."
              serviceImage={inpremiseappphoto}
            />
          </div>
          <div id="Service3">
            <ServicesCardMobile
              title="Mobile Solutions"
              description="Mobile solutions from our team help businesses reach customers on-the-go. 
              We design and develop custom mobile apps that provide seamless, user-friendly experiences on any device. 
              Enhance your brand and reach with a mobile solution from us."
              serviceImage={mobilesolutionsphoto}
            />
          </div>
          <div id="Service4">
            <ServicesCardMobile
              title="e-Governance Solutions"
              description="E-governance solutions from us streamline government processes and improve citizen engagement. 
              We design and develop custom e-governance solutions that increase efficiency, transparency, and accessibility. 
              Partner with us to modernize your government operations and enhance citizen engagement."
              serviceImage={egovphoto}
            />
          </div>
          <div id="Service5">
            <ServicesCardMobile
              title="e-Commerce Solutions"
              description="We design and develop custom e-commerce websites and apps that provide seamless, 
              user-friendly experiences for customers. Grow your business with an e-commerce solution from us."
              serviceImage={ecomphoto}
            />
          </div>
          <div id="Service6">
            <ServicesCardMobile
              title="Software Consultancy"
              description="Software consultancy from our team helps businesses optimize and innovate. 
              We provide expert advice and guidance on software development, design, and strategy. 
              Partner with us for a competitive edge through innovative software solutions."
              serviceImage={softwareconsultancyphoto}
            />
          </div>
          <div id="Service7">
            <ServicesCardMobile
              title="Search Engine Optimization (SEO) Consultancy"
              description="With SEO solutions, we help you drive traffic and improve online visibility. 
              We provide customized SEO strategies that help businesses rank higher in search engine results and reach more potential customers. 
              Increase your online visibility with SEO solutions from us."
              serviceImage={seoconsultancyphoto}
            />
          </div>
          <div id="Service8">
            <ServicesCardMobile
              title="Chatbots"
              description="Chatbots help automate customer interactions and improve efficiency. 
              We design and develop custom chatbots that provide quick, seamless, and personalized experiences for customers. 
              Enhance customer engagement and streamline operations with a chatbot from us."
              serviceImage={chatbotsphoto}
            />
          </div>
          <div id="Service9">
            <ServicesCardMobile
              title="Soft Skills Training"
              description="Soft skill training from our team helps individuals and organizations thrive. 
              We provide customized soft skill training programs that improve communication, teamwork, leadership, and more. 
              Enhance your professional growth and success with soft skill training from us."
              serviceImage={softskillsphoto}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;

import React, { useState, useEffect, useRef } from "react";
import hrms from "../Assets/hrms.png";

import { useLogo } from "../context/LogoContext";

import dashboardHRMS from "../Assets/SMUHRMSDashboard.jpeg";
import configHRMS from "../Assets/HRMSConfig.jpeg";
import SmuHrms1 from "../Assets/SmuHrms_1.PNG";
import SmuHrms2 from "../Assets/SmuHrms_2.PNG";
import SmuHrms3 from "../Assets/SmuHrms_3.PNG";
import SmuHrms4 from "../Assets/SmuHrms_4.PNG";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import GenericModal from "../reusable-components/modal/GenericModal";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

export default function ProjectSikkimMilkHRMS() {
  const bannerSmuHrmsPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSmuHrmsPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSmuHrmsPage?.current?.offsetHeight);
    }
  }, [bannerSmuHrmsPage?.current?.offsetHeight]);

  const [showModal, setShowModal] = useState(false);
  const [clickedImage, setClickedImage] = useState("");
  return (
    <>
      <GenericModal
        open={showModal}
        setOpen={setShowModal}
        isAdd={true}
        isLarge={true}
      >
        <div className="my-4 ">
          {clickedImage === "hrms_image1" ? (
            <div className="flex  flex-col justify-between items-center ">
              <div className="flex justify-center w-full relative">
                <p className="text-white text-center">
                  Sikkim Milk Union HRMS Dashboard
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={SmuHrms1}
                alt="dashboardHRMS"
                className="h-full pt-4 w-[900px] "
              />
            </div>
          ) : null}

          {clickedImage === "hrms_image2" ? (
            <div className="flex  flex-col justify-center items-center">
              <div className="flex justify-center w-full relative">
                <p className="text-white text-center">
                  Sikkim Milk Union HRMS Config
                </p>
                <p
                  className=" cursor-pointer text-white absolute right-0 border px-1.5 py-0.5 text-xs  rounded-full "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img src={SmuHrms2} alt="configHRMS" className="h-full pt-4" />
            </div>
          ) : null}

          {clickedImage === "hrms_image3" ? (
            <div
              className="flex  flex-col justify-center items-center "
              onClick={() => setShowModal(false)}
            >
              <div className="flex justify-center w-full  gap-4 my-3">
                <p className="text-slate-100 text-center font-medium ">
                  Sikkim Milk Union HRMS Config
                </p>
                <p
                  className=" cursor-pointer text-white   border border-white px-1.5 py-0.5 text-xs  rounded-full   "
                  onClick={() => setShowModal(false)}
                >
                  X
                </p>
              </div>

              <img
                src={SmuHrms3}
                alt="login"
                className=" w-full h-full object-cover pt-6"
              />
            </div>
          ) : null}
        </div>
      </GenericModal>

      <div className="relative" ref={bannerSmuHrmsPage}>
        <div className="bg-black bg-opacity-75 h-72 lg:h-[500px] w-full object-cover absolute "></div>
        <img
          src={hrms}
          alt="hrms"
          className="h-72 lg:h-[500px] w-full object-cover"
        />

        <div className="absolute top-[25%]  md:top-[35%] md:left-[5%] flex flex-col justify-center item-center">
          <p className="  text-white font-bold  text-sm md:text-2xl px-4 md:px-0">
            <Heading>Sikkim Milk Union</Heading>
            <SubHeading classes={`text-white`}>
              Human Resource Management System
            </SubHeading>
          </p>
        </div>
      </div>

      <div className="flex justify-end">
        <a
          href="/our-projects"
          className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
        >
          View All Projects
        </a>
      </div>

      <div className="w-[75%] mx-auto  pt-2">
        <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
          Sikkim Milk Union HRMS
        </Heading>

        <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
          <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
            <p className="leading-relaxed text-gray-700">
              This is a Web Portal for the Sikkim Milk Union HRMS with exemplary
              online information and services.The Human Resource Management
              Software of Sikkim Milk Union has following Modules.
            </p>
          </div>
          <div className="flex flex-col">
            <div className="relative flex justify-end shadow-2xl">
              <img src={dashboardHRMS} className="rounded-md h-full" />
            </div>
          </div>
        </div>

        <p className="text-center font-medium text-sm lg:text-base 2xl:text-[20px] pt-4 text-gray-700">
          This is the official Human Resource Management System software for the
          the Sikkim Milk Union HRMS.{" "}
        </p>

        <div className="text-sm lg:text-base 2xl:text-[20px]">
          <p className="pt-6 text-sm lg:text-base 2xl:text-[20px] text-gray-700">
            The Human Resource Management Software of Sikkim Milk Union has the
            following modules.
          </p>
          <h3 className="font-semibold underline underline-offset-1  text-sm lg:text-base 2xl:text-[20px] pt-4 text-gray-800">
            Notifications / Reminders Module:
          </h3>
          <ul className="  text-sm lg:text-base 2xl:text-[20px] pt-4 text-justify text-gray-700">
            <li>
              The system will be able to notify concerned personnel regarding
              important information related with the employee’s career
              progression.
            </li>
            <li className="pt-2">
              For instance, the system will send a notification to the concerned
              personnel if an employee is nearing regularization, if the
              employee is nearing increment, if the employee is nearing
              consolidated pay, if the employee is nearing retirement and other
              vital information regarding the employee’s career progression in
              the organization.
            </li>
          </ul>

          <h3 className="font-semibold underline underline-offset-1 pt-4 text-sm lg:text-base 2xl:text-[20px] text-gray-800">
            Recruitment Module:
          </h3>
          <ul className="  pt-4 text-sm lg:text-base 2xl:text-[20px] text-gray-700">
            <li className="">
              The system will provide a recruitment module where higher
              management can post details regarding various new vacancies
              available in the organisation. Applicants can then fill up the
              recruitment form online itself and submit the same. The
              applications filled can then be viewed and scrutinized by the
              higher management in the system itself.
            </li>
          </ul>

          <h3 className="font-semibold underline underline-offset-1 pt-4 text-gray-800">
            User management Module :
          </h3>
          <ul className="  text-gray-700 ">
            <li className="pt-4">
              The system will provide provisions/flexibility for Sikkim Milk
              Union (SMU) to define and configure user access roles to
              adequately handle important Human Resource functions. The module
              will also enable changes in user access roles in future should
              there be a need to do so..
            </li>
          </ul>

          <h3 className="font-semibold underline underline-offset-1 pt-4 text-gray-800">
            Reports Module:
          </h3>
          <ul className="  pt-1 text-gray-700">
            <li className="pt-1">
              The system will provide various data-driven consolidated reports
              regarding career progression like increments, promotions,
              regularizations, retirements, new recruitments, etc.
            </li>
          </ul>
        </div>

        <p className="font-medium pt-6 italic text-sm lg:text-base 2xl:text-[20px] text-gray-800">
          Images for Sikkim Milk HRMS
        </p>

        <div>
          <div className="my-5 flex flex-col md:flex-row items-center gap-8 pt-5 text-gray-700">
            <div
              className="flex flex-col gap-4 cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setClickedImage("hrms_image1");
              }}
            >
              <img
                src={SmuHrms1}
                alt="dashboardHRMS"
                className="h-40 w-[3/4] "
              />
              <span className="text-sm text-center italic">
                Sikkim Milk Union HRMS Dashboard
              </span>
            </div>

            <div
              className="flex flex-col gap-2 cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setClickedImage("hrms_image2");
              }}
            >
              <img src={SmuHrms2} alt="configHRMS" className="h-44 w-[3/4]" />
              <span className="text-center text-xs italic">
                Sikkim Milk Union HRMS Config
              </span>
            </div>

            <div
              className="flex flex-col gap-2 cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setClickedImage("hrms_image3");
              }}
            >
              <img src={SmuHrms3} alt="configHRMS" className="h-44 w-[3/4]" />
              <span className="text-center text-xs italic">
                Sikkim Milk Union HRMS Reports
              </span>
            </div>

            {/* <div
              className="flex flex-col gap-2 cursor-pointer"
              onClick={() => {
                setShowModal(true);
                setClickedImage("hrms_image3");
              }}
            >
              <img src={smu_hrms} alt="smu_hrms" className="h-44" />
              <span>Sikkim Milk Union HRMS</span>
            </div> */}
          </div>

          <SubHeading classes={`pt-5 mb-5 lg:mb-10 text-gray-800]`}>
            Technology Used
          </SubHeading>

          <div className="flex flex-row my-10">
            <>
              <div
                className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
              >
                React
              </div>
              <div
                className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
              >
                Node
              </div>
              <div
                className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
              >
                Postgres
              </div>
            </>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState, useRef } from "react";
import Typewriter from "typewriter-effect";
import { useLocation } from "react-router-dom";

import { motion, useAnimation } from "framer-motion";

import { useLogo } from "../context/LogoContext";

import { SlArrowDown } from "react-icons/sl";
import "./Home.css";
import FAQ from "./FAQ";
import PeopleWeWorkedWith from "./PeopleWeWorkedWith";
import WhyBytesberry from "./WhyBytesberry";
import OurServicesHome from "./OurServicesHome";
import Heading from "../reusable-components/fonts/Heading";
import CallToAction from "./CallToAction";
import FadeInOnScroll from "../reusable-components/animations/FadeInOnScroll";

// import bannervideo from "../Assets/homepagevideo.mp4";
import contactblockbg from "../Assets/contactblockBG.png";

const Home = () => {
  const [selectedBusinessType, setSelectedBusinessType] = useState(true);

  const activeTab =
    "animate-[btnpulse_2s_infinite] text-[#E75124] p-4 border border-[#E75124] rounded-md";
  const inactiveTab = "text-gray-400 p-4";

  const bgVideoPlayBack = useRef();

  const { setFirstDivHeight } = useLogo();

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  const handleScroll = () => {
    window.scroll({
      top: 900,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (bgVideoPlayBack?.current?.offsetHeight > 0) {
      setFirstDivHeight(bgVideoPlayBack?.current?.offsetHeight);
    }
  }, [bgVideoPlayBack?.current?.offsetHeight]);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <>
      <section className="relative pb-8 lg:pb-16" ref={bgVideoPlayBack}>
        <div className="bg-gray-900 bg-opacity-60">
          <video
            playsInline
            autoPlay
            loop
            muted
            className="object-cover w-full h-screen"
          >
            <source
              src={`https://bytesberry-shared-sss.sgp1.cdn.digitaloceanspaces.com/BytesberryTechnologies/homepagevideo.mp4  `}
              type="video/mp4"
            />
          </video>
        </div>

        <div className="absolute top-0 w-full h-screen">
          <div className="bg-gray-900 bg-opacity-75 w-full h-full  flex justify-center top-0">
            <div className="relative top-1/4 text-white text-xl md:text-2xl lg:text-4xl font-semibold py-2 text-center">
              <div>
                <div className="mb-6 flex items-center gap-8 justify-center">
                  <button
                    className={`font-semibold text-base md:text-lg lg:text-xl cursor-pointer py-2 activeButton ${
                      selectedBusinessType ? activeTab : inactiveTab
                    }`}
                    onClick={() => setSelectedBusinessType(true)}
                  >
                    Enterprises
                  </button>
                  <button
                    className={`font-semibold text-base md:text-lg lg:text-xl cursor-pointer  py-2 activeButton ${
                      !selectedBusinessType ? activeTab : inactiveTab
                    }`}
                    onClick={() => setSelectedBusinessType(false)}
                  >
                    Startups
                  </button>
                </div>
              </div>
              <div className="mb-6">
                {selectedBusinessType ? (
                  <p className="mb-4 text-3xl md:text-5xl lg:text-7xl">
                    We help <span className="">Organizations</span>:
                  </p>
                ) : (
                  <p className="mb-4 text-3xl md:text-5xl lg:text-7xl">
                    We help <span className="">Startups</span>:
                  </p>
                )}
                {selectedBusinessType ? (
                  <>
                    <Typewriter
                      className="text-[#E75124]  text-3xl md:text-5xl lg:text-7xl font-semibold "
                      options={{
                        strings: [
                          "Digitize records",
                          "Reduce cost & increase profits",
                          "Streamline business workflows",
                          "Automate internal processes",
                        ],
                        autoStart: true,
                        loop: true,
                        skipAddStyles: true,
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Typewriter
                      className="text-[#E75124]  text-2xl md:text-4xl lg:text-7xl font-semibold "
                      options={{
                        strings: [
                          "Validate ideas",
                          "Prototype & launch quickly",
                          "Reach out to more customers",
                          "Adopt Technology",
                        ],
                        autoStart: true,
                        loop: true,
                        skipAddStyles: true,
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <span
            className="absolute bottom-9 left-[50%]"
            onClick={() => handleScroll()}
          >
            <SlArrowDown
              // size={44}
              color="white"
              className="cursor-pointer w-10 lg:h-32"
            />
          </span>
        </div>
      </section>

      <div className="pb-8 lg:pb-28">
        <FadeInOnScroll>
          <WhyBytesberry />
        </FadeInOnScroll>
      </div>

      <div className="pb-8 lg:pb-28">
        <FadeInOnScroll>
          <OurServicesHome />
        </FadeInOnScroll>
      </div>

      <div className="pb-8 lg:pb-28">
        <FadeInOnScroll>
          <PeopleWeWorkedWith />
        </FadeInOnScroll>
      </div>

      <div className="pb-24 lg:pb-36">
        <FadeInOnScroll>
          <FAQ />
        </FadeInOnScroll>
      </div>

      {/* <div>
        <img src={contactblockbg} className="w-full" />
      </div> */}
      {/* <div className="">
        <CallToAction />
      </div> */}
    </>
  );
};

export default Home;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import FadeInLeftRight from "../reusable-components/animations/FadeInLeftRight";

// import servicephoto from "../../src/Assets/sample_service.png";

const ServicesCardImageLeft = ({ title, description, serviceImage }) => {
  return (
    <>
      <div className="md:mx-20 lg:mx-32 grid grid-cols-2 md:gap-16 lg:gap-28 pb-40">
        <FadeInLeftRight gridColumn={1}>
          <div>
            <img
              src={serviceImage}
              alt="Service photo"
              className="h-96 md:w-full object-fill rounded-md"
            />
          </div>
        </FadeInLeftRight>

        <FadeInLeftRight gridColumn={2}>
          <div className="flex flex-col justify-center">
            <div className="pb-6">
              <p className="md:text-3xl lg:text-5xl font-medium">{title}</p>
            </div>
            <div>
              <p className="md:text-base lg:text-lg xl:text-[20px] font-normal md:pr-2 lg:pr-8 text-justify">
                {description}
              </p>
            </div>
          </div>
        </FadeInLeftRight>
      </div>
    </>
  );
};

export default ServicesCardImageLeft;

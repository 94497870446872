import React from "react";

import acclogo from "../Assets/acclogo.png";
import ndmulogo from "../Assets/ndmulogo.png";
import rsblogo from "../Assets/rsblogo.png";
import sabccologo from "../Assets/sabccologo.png";
import seclogo from "../Assets/seclogo.png";
import sikkimawardslogo from "../Assets/sikkimawardslogo.png";
import siscobanklogo from "../Assets/siscobanklogo.png";
import sislogo from "../Assets/sislogo.png";
import smulogo from "../Assets/smulogo.png";
import tourismlogo from "../Assets/tourismlogo.png";
import Heading from "../reusable-components/fonts/Heading";

import "./PeopleWeWorkedWith.css";

const PeopleWeWorkedWith = () => {
  return (
    <>
      <Heading
        classes={`flex flex-row justify-center my-5 lg:my-10 lg:pb-10 text-gray-800`}
      >
        People We've Worked With
      </Heading>

      <article className="wrapper">
        <div className="marquee">
          <div className="marquee__group">
            <img src={acclogo} alt="ACC Logo" className="w-auto h-6 md:h-20" />
            <img
              src={ndmulogo}
              alt="NDMU Logo"
              className="w-auto h-10 md:h-20"
            />
            <img src={rsblogo} alt="RSB Logo" className="w-auto h-6 md:h-20" />
            <img
              src={sabccologo}
              alt="SABCCO Logo"
              className="w-auto h-16 md:h-28"
            />
            <img src={seclogo} alt="SEC Logo" className="w-auto h-16 md:h-28" />
            <img
              src={sikkimawardslogo}
              alt="Sikkim Awards Logo"
              className="w-auto h-16 md:h-28"
            />
            <img
              src={siscobanklogo}
              alt="Sisco Bank Logo"
              className="w-auto h-11 md:h-10"
            />
            <img
              src={sislogo}
              alt="Sikkim Investment Summit Logo"
              className="w-auto h-16 md:h-20"
            />
            <img
              src={smulogo}
              alt="Sikkim Milk Union Logo"
              className="w-auto h-16 md:h-20"
            />
            <img
              src={tourismlogo}
              alt="Sikkim Tourism Logo"
              className="w-auto h-16 md:h-28"
            />
          </div>

          <div aria-hidden="true" className="marquee__group ">
            <img src={acclogo} alt="ACC Logo" className="w-auto h-16 md:h-20" />
            <img
              src={ndmulogo}
              alt="NDMU Logo"
              className="w-auto h-16 md:h-20"
            />
            <img src={rsblogo} alt="RSB Logo" className="w-auto h-16 md:h-20" />
            <img
              src={sabccologo}
              alt="SABCCO Logo"
              className="w-auto h-16 md:h-28"
            />
            <img src={seclogo} alt="SEC Logo" className="w-auto h-16 md:h-28" />
            <img
              src={sikkimawardslogo}
              alt="Sikkim Awards Logo"
              className="w-auto h-16 md:h-28"
            />
            <img
              src={siscobanklogo}
              alt="Sisco Bank Logo"
              className="w-auto h-11 md:h-10"
            />
            <img
              src={sislogo}
              alt="Sikkim Investment Summit Logo"
              className="w-auto h-16 md:h-20"
            />
            <img
              src={smulogo}
              alt="Sikkim Milk Union Logo"
              className="w-auto h-16 md:h-20"
            />
            <img
              src={tourismlogo}
              alt="Sikkim Tourism Logo"
              className="w-auto h-16 md:h-28"
            />
          </div>
        </div>

        {/* <div className="marquee marquee--reverse">
          <div className="marquee__group">
            <img
              src={bytesberrylogo}
              alt="Bytesberry Logo"
              className="w-auto h-16"
            />
          </div>

          <div aria-hidden="true" className="marquee__group">
            <img
              src={bytesberrylogo}
              alt="Bytesberry Logo"
              className="w-auto h-16"
            />
          </div>
        </div> */}
      </article>
    </>
  );
};

export default PeopleWeWorkedWith;

import React, { useState } from "react";
import Heading from "../reusable-components/fonts/Heading";
import ViewMoreDetailsLink from "../reusable-components/ViewMoreDetailsLink";

import "./FAQ.css";

const FAQ = () => {
  //flag 1 indicates there is a view details link to a page/section and flag 0 indicates otherwise
  const faq_array = [
    {
      question:
        "Why choose Bytesberry Technologies as a Software Development Service Provider?",
      answer:
        "We are a well reputed software and mobile app development company in Sikkim having a cumulative experience of 25+ years in this field. Numerous organizations have benefited from our vast knowledge and expertise in offering a variety of bespoke software development services with the newest technological standards. To produce the best solution, our software development team focuses on Agile software development methodologies, customer-centric engagement features, rigorous quality controls, in-depth technical and domain expertise, and a knowledge of various software development frameworks, programming languages, and platforms. ",
      detailsLink: 1,
      detailsUrl: "/#whyBB",
    },
    {
      question: "What Software Development Services do you offer?",
      answer:
        "We provide all-inclusive software related services, such as custom software development, web and mobile app creation, software testing, and associated services. Our efficient software development approach ensures that delivery timelines are met thus guaranteeing total client satisfaction. ",
      detailsLink: 1,
      detailsUrl: "/our-services",
    },
    {
      question: "What Technology do you use to develop applications?",
      answer:
        "We provide a wide range of software and web development services that cover a number of technologies, varied frameworks, and programming languages. The complexity and Client requirement will ultimately help us determine which technology stack is ideal for them and what will result in the highest level of business success. However, by examining their company needs, financial situation, anticipated demand patterns, and existing technological stack, we provide suggestions and offer assistance to the organization. Some of the common technology we use include: HTML, CSS, JavaScript, Tailwind CSS, Bootstrap, .NET framework, ReactJS & React Native framework, etc. ",
      detailsLink: 0,
    },
    {
      question: "What is the Cost/Time involved to develop a software?",
      answer:
        "This is determined by the nature & the complexity of the project. These factors typically include:",
      detailsLink: 0,
      bullet_points: [
        `Type of the project whether it is a simple website, dynamic website, web portal or a mobile app and combinations of these if necessary.`,
        `The nature and the volume of the transactions in the solution.`,
        `The targeted platforms (Browser, Tablets, mobile platforms such as Android and IOS).`,
        `Design complexities.`,
        `Nature and access roles of users/stakeholders.`,
        `Complexities within the business rules that determines the underlined users and their access roles.`,
      ],
    },
    {
      question: "How would you ensure Security of the Software?",
      answer:
        "While building software applications, we implement various security measures such as adopting secure coding practices, following industry-standard security guidelines, and performing regular testing to identify and fix vulnerabilities. We implement user authentication and access control mechanisms to ensure that only authorized users can access the application. In terms of hosting, we choose a secure hosting provider and configure the server with adequate security settings and protocols. We also implement regular backups and disaster recovery plans to minimize the risk of data loss and ensure business continuity.",
      detailsLink: 0,
    },
    {
      question: "What Software Development Life Cycle do you adhere to?",
      answer:
        "We often use the Agile software development life cycle, which is an incremental and iterative method of developing software that places an emphasis on adaptability, teamwork, and customer satisfaction.The agile approach typically involves breaking down the development process into small, manageable chunks or iterations, with frequent reviews and feedback from our stakeholders. The focus is on delivering a working product quickly and continuously improving it based on feedback.",
      detailsLink: 0,
    },
    {
      question: "What Soft-Skills Training do you provide?",
      answer:
        "A person's soft skills, as opposed to their technical ones, are a collection of aptitudes relating to people and social circumstances. Soft skills are crucial to the development of a business (Startups, small business houses, cooperatives, departmental stores and many more) and are also directly applicable to some positions inside an organisation. We have distilled our selection of the best soft skills training programmes down to the core components which are as follows:",
      detailsLink: 0,
      bullet_points: [
        `Communication`,
        `Time Management`,
        `Work Ethics`,
        `Teamwork`,
        `Leadership`,
        `Cooperation & Coordination`,
        `Interpersonal Skills`,
        `People management`,
      ],
    },
  ];

  const toggleAccordion = (button_id) => {
    const button_items = document.querySelectorAll(".accordion button");

    //console.log("total buttons", button_items);

    const selected_button = document.getElementById(button_id);

    let buttonToggle = selected_button
      .getAttribute("aria-expanded")
      ?.includes("true")
      ? true
      : false;

    if (buttonToggle === false) {
      buttonToggle = true;
      selected_button.setAttribute("aria-expanded", "true");
    } else {
      buttonToggle = false;
      selected_button.setAttribute("aria-expanded", "false");
    }

    button_items.forEach(function (obj) {
      if (obj.id !== button_id) {
        obj.setAttribute("aria-expanded", "false");
      }
    });
  };

  return (
    <>
      <Heading
        classes={`flex flex-row justify-center my-5 lg:my-10 lg:pb-10 text-gray-800`}
      >
        Frequently Asked Questions
      </Heading>
      <div className="mx-8 lg:mx-72 noSelect">
        {/* <p className="font-medium mb-1 text-sm lg:text-lg xl:text-[20px] text-gray-700">
          Frequently Asked Questions
        </p> */}

        <div className="accordion">
          {faq_array?.map((faqObj, index) => (
            <AccordianItem
              id={index + 1}
              question={faqObj?.question}
              toggleAccordion={toggleAccordion}
              detailsLink={faqObj.detailsLink}
              detailsUrl={faqObj.detailsUrl}
              bullet_points={faqObj.bullet_points}
            >
              {faqObj?.answer}
            </AccordianItem>
          ))}
        </div>
      </div>
    </>
  );
};

export default FAQ;

const AccordianItem = (props) => {
  return (
    <>
      <div className="accordion-item">
        <button
          id={`accordion-button-${props.id}`}
          aria-expanded="false"
          onClick={() => props.toggleAccordion(`accordion-button-${props.id}`)}
        >
          <span className="accordion-title text-sm font-semibold lg:text-lg 2xl:text-[20px] flex">
            {props.question}
          </span>
          <span className="icon" aria-hidden="true"></span>
        </button>
        <div className="accordion-content py-1 lg:py-3">
          <span className="text-gray-500 text-xs lg:text-base 2xl:text-lg">
            {props.children}
          </span>

          {props.bullet_points && (
            <div className="text-gray-900 text-xs lg:text-base 2xl:text-lg">
              <ul
                // style={{ listStyleType: "disc" }}
                className="mt-2 list-decimal pl-6"
              >
                {props.bullet_points.map((mapobj) => (
                  <li className="mb-2">{mapobj}</li>
                ))}
              </ul>
            </div>
          )}
          {props.detailsLink === 1 && (
            <>
              <ViewMoreDetailsLink linkUrl={props.detailsUrl} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

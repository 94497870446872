import React, { useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import egovbannerphoto from "../Assets/egovernancerandom.jpg";
import websitephoto from "../Assets/sishome.png";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

const ProjectSikkimInvestmentSummit = () => {
  const bannerSISPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSISPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSISPage?.current?.offsetHeight);
    }
  }, [bannerSISPage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerSISPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={egovbannerphoto}
              alt="Contact Us photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10">
              <Heading classes={`text-white`}>SIS</Heading>
              <p className=" text-white font-medium text-xl lg:text-2xl">
                Website
              </p>
            </div>
          </div>

          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading
                classes={`mt-10 mb-5 flex justify-center  text-gray-800`}
              >
                Sikkim Investment Summit Website
              </Heading>
              {/* <div className="mt-0 md:mt-10 mb-0 md:mb-5 md:absolute md:right-0">
                <a
                  href="/our-projects"
                  className="text-white text-[10px] lg:text-sm bg-[#E75124] border px-2 py-1 cursor-pointer rounded-md hover:underline"
                >
                  &lt; Go Back
                </a>
              </div> */}
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed text-gray-700">
                  This is the official website for Sikkim Investment Summit. The
                  State Government of Sikkim is hosting the 1st edition of the
                  Sikkim Investment Summit (SIS). Sikkim has a long-standing
                  commitment to sustainability and has won global recognition
                  for its sustainable policies. Sikkim has taken measures to
                  promote environmental, social and governance (ESG) issues and
                  stands committed to resolving the present challenges and
                  preparing for the future. The Sikkim Investment Summit will
                  spotlight expert-led conversations with global leaders on
                  climate change, organic farming and innovation around green
                  sustainability. The website is also compatible to all devices
                  i.e. Desktop, Laptop, Tablets & mobile.
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="https://sikkiminvestmentsummit.com/"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-primary text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
                {/* <div className="mt-4 xl:mt-6 flex flex-row md:justify-start lg:justify-end pr-12">
                  <>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      React JS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Node JS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      PostgreSQL
                    </div>
                  </>
                </div> */}
              </div>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>
            <div className="mb-10">
              <ul className="list-disc font-medium text-xs lg:text-base xl:text-[20px]  mx-10 text-gray-800">
                <li className="mb-4">Modules to be described.</li>
              </ul>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Technology Used
            </SubHeading>
            <div className="flex flex-row">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  React JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Node JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  PostgreSQL
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectSikkimInvestmentSummit;

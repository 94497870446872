import React from "react";
import { Link } from "react-router-dom";

const ViewMoreDetailsLink = ({ linkUrl }) => {
  return (
    <div className="mt-2 text-gray-800 text-xs lg:text-base 2xl:text-lg">
      For more details, click on this {""}
      <Link to={linkUrl} className="text-blue-700">
        link
      </Link>
      .
    </div>
  );
};

export default ViewMoreDetailsLink;

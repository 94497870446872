/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";

import { FiChevronDown } from "react-icons/fi";

import siscobanklogoMM from "../../Assets/ProjectsMMLogos/siscobanklogo_mm.jpg";
import sabccologoMM from "../../Assets/ProjectsMMLogos/sabccologo_mm.jpg";
import tourismlogoMM from "../../Assets/ProjectsMMLogos/tourismlogo_mm.jpg";
import panchaklogoMM from "../../Assets/ProjectsMMLogos/panchaklogo_mm.jpg";
import smulogoMM from "../../Assets/ProjectsMMLogos/smulogo_mm.jpg";
import seclogoMM from "../../Assets/ProjectsMMLogos/seclogo_mm.jpg";
import sislogoMM from "../../Assets/ProjectsMMLogos/sislogo_mm.jpg";
import rsblogoMM from "../../Assets/ProjectsMMLogos/rsblogo_mm.jpg";
import sikkimawardslogoMM from "../../Assets/ProjectsMMLogos/sikkimawardslogo_mm.jpg";
import positudelogoMM from "../../Assets/ProjectsMMLogos/positudelogo_mm.png";
import seedlogoMM from "../../Assets/ProjectsMMLogos/seedlogo_mm.jpg";
import eaglesnestlogoMM from "../../Assets/ProjectsMMLogos/eaglesnestlogo.png";

import OurServices from "./subMenu/OurServices2";

import OurProjects from "./subMenu/OurProjects2";
import { projectsSectorsArr } from "../../utilities/constants";

const ShiftingDropDown = () => {
  return (
    <div className="flex w-full justify-start bg-neutral-950 text-neutral-200 md:justify-center">
      <Tabs />
    </div>
  );
};

export default ShiftingDropDown;

const Tabs = () => {
  const [selected, setSelected] = useState(null);
  const [dir, setDir] = useState(null);

  const handleSetSelected = (val) => {
    if (typeof selected === "number" && typeof val === "number") {
      setDir(selected > val ? "r" : "l");
    } else if (val === null) {
      setDir(null);
    }

    setSelected(val);
  };

  return (
    <div
      onMouseLeave={() => handleSetSelected(null)}
      className="relative flex h-fit gap-2"
    >
      {TABS.map((t) => {
        return (
          <Tab
            key={t.id}
            selected={selected}
            handleSetSelected={handleSetSelected}
            tab={t.id}
          >
            {t.title}
          </Tab>
        );
      })}

      <AnimatePresence>
        {selected && <Content dir={dir} selected={selected} />}
      </AnimatePresence>
    </div>
  );
};

const Tab = ({ children, tab, handleSetSelected, selected }) => {
  return (
    <button
      id={`shift-tab-${tab}`}
      onMouseEnter={() => handleSetSelected(tab)}
      onClick={() => handleSetSelected(tab)}
      className={`font-semibold text-lg flex items-center gap-1 rounded-full px-3 py-1.5 transition-colors ${
        // selected === tab ? " bg-white text-neutral-100" : "text-neutral-400"
        selected === tab ? "text-primary" : "text-white"
      }`}
    >
      <span>{children}</span>
      <FiChevronDown
        className={`transition-transform ${
          selected === tab ? "rotate-180" : ""
        }`}
      />
    </button>
  );
};

const Content = ({ selected, dir }) => {
  return (
    <motion.div
      id="overlay-content"
      initial={{
        opacity: 0,
        y: 8,
      }}
      animate={{
        opacity: 1,
        y: 0,
      }}
      exit={{
        opacity: 0,
        y: 8,
      }}
      // className="absolute left-0 top-[calc(100%_+_24px)] w-96 rounded-lg border border-neutral-600 bg-gradient-to-b from-neutral-900 via-neutral-900 to-neutral-800 p-4"
      className="absolute -right-64 top-[calc(100%_+_24px)] w-[100vh] rounded-lg border border-neutral-500 bg-white"
    >
      <Bridge />
      <Nub selected={selected} />

      {TABS.map((t) => {
        return (
          <div className="overflow-hidden" key={t.id}>
            {selected === t.id && (
              <motion.div
                initial={{
                  opacity: 0,
                  x: dir === "l" ? 100 : dir === "r" ? -100 : 0,
                }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.25, ease: "easeInOut" }}
              >
                <t.Component />
              </motion.div>
            )}
          </div>
        );
      })}
    </motion.div>
  );
};

const Bridge = () => (
  <div className="absolute -top-[24px] left-0 right-0 h-[24px]" />
);

const Nub = ({ selected }) => {
  const [left, setLeft] = useState(0);

  useEffect(() => {
    moveNub();
  }, [selected]);

  const moveNub = () => {
    if (selected) {
      const hoveredTab = document.getElementById(`shift-tab-${selected}`);
      const overlayContent = document.getElementById("overlay-content");

      if (!hoveredTab || !overlayContent) return;

      const tabRect = hoveredTab.getBoundingClientRect();
      const { left: contentLeft } = overlayContent.getBoundingClientRect();

      const tabCenter = tabRect.left + tabRect.width / 2 - contentLeft;

      setLeft(tabCenter);
    }
  };

  return (
    <motion.span
      style={{
        clipPath: "polygon(0 0, 100% 0, 50% 50%, 0% 100%)",
      }}
      animate={{ left }}
      transition={{ duration: 0.25, ease: "easeInOut" }}
      className="absolute left-1/2 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 rounded-tl border border-neutral-600 bg-white"
    />
  );
};

const Services = () => {
  return (
    <div>
      <OurServices />
    </div>
  );
};

const Projects = () => {
  const projectsArr = [
    {
      id: 1,
      projectTitle: "Sikkim Tourism",
      sectors: [2, 4],
      clientLogo: tourismlogoMM,
      imageBlurHashString: "LQQ0XPj]~nofxtazj[j[~oj@9HfQ",
      siteUrl: "/sikkim-tourism-view-details",
      newTab: 0,
    },

    {
      id: 2,
      projectTitle: "SEC, Sikkim",
      sectors: [4],
      clientLogo: seclogoMM,
      imageBlurHashString: "LXQ0XHWB~qt7t7fQayfQ-;j[D%WB",
      siteUrl: "/sec-sikkim-view-details",
      newTab: 0,
    },

    {
      id: 3,
      projectTitle: "SMU Mitra",
      sectors: [3],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-mitra-app-view-details",
      newTab: 0,
    },

    {
      id: 4,
      projectTitle: "SMU P&I",
      sectors: [3],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-union-p-and-i-view-details",
      newTab: 0,
    },

    {
      id: 5,
      projectTitle: "SMU HRMS",
      sectors: [6],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-hrms-view-details",
      newTab: 0,
    },

    {
      id: 6,
      projectTitle: "SABCCO",
      sectors: [1],
      clientLogo: sabccologoMM,
      imageBlurHashString: "LJPZr%t7_3of-;fQM{fQ~qWBayWB",
      siteUrl: "/sabcco-view-details",
      newTab: 0,
    },

    {
      id: 7,
      projectTitle: "Sisco Bank",
      sectors: [1],
      clientLogo: siscobanklogoMM,
      imageBlurHashString: "LLQJl$ay~Wofxuj[ofj[~qj[D%j[",
      siteUrl: "/sisco-bank-view-details",
      newTab: 0,
    },

    {
      id: 8,
      projectTitle: "Rajya Sainik Board",
      sectors: [4],
      clientLogo: rsblogoMM,
      imageBlurHashString: "LhOy^xf7?toLzVjZO?ay~Uj[Ipf6",
      siteUrl: "/rsb-view-details",
      newTab: 0,
    },

    {
      id: 10,
      projectTitle: "SIS",
      sectors: [4],
      clientLogo: sislogoMM,
      imageBlurHashString: "LfL}Wsof-:ae?Gfkt7ay~VafRkj[",
      siteUrl: "/sikkim-investment-summit-view-details",
      newTab: 0,
    },

    {
      id: 11,
      projectTitle: "SEED Cell",
      sectors: [5],
      clientLogo: seedlogoMM,
      imageBlurHashString: "LlOWjBf6%hW:t6j[Rjay?wbHngoL",
      siteUrl: "/seed-cell-view-details",
      newTab: 0,
    },

    {
      id: 12,
      projectTitle: "Sikkim Awards",
      sectors: [5],
      clientLogo: sikkimawardslogoMM,
      imageBlurHashString: "L87^h-~S%0M}WXofRlM}0PE4NHoL",
      siteUrl: "https://sikkimawards.com/",
      newTab: 1,
    },

    {
      id: 13,
      projectTitle: "Positude",
      sectors: [5],
      clientLogo: positudelogoMM,
      imageBlurHashString: "LaMQ*Jj[~qWBM{ayfQj[~qayRjof",
      siteUrl: "https://positude.in/",
      newTab: 1,
    },

    {
      id: 15,
      projectTitle: "Panchak Holidays",
      sectors: [2],
      clientLogo: panchaklogoMM,
      imageBlurHashString: "L27nj}ay00WV4nayt7Rj00f7~qt7",
      siteUrl: "https://panchakholidays.com/",
      newTab: 1,
    },

    {
      id: 16,
      projectTitle: "Eagle's Nest",
      sectors: [2],
      clientLogo: eaglesnestlogoMM,
      imageBlurHashString: "LCRW0bs:_Nozt7j[ofWB_3fk8_f6",
      siteUrl: "https://eaglesnestgangtok.com/",
      newTab: 1,
    },
  ];

  return (
    <div>
      <OurProjects
        projectsArr={projectsArr}
        projectsSectorsArr={projectsSectorsArr}
      />
    </div>
  );
};

const TABS = [
  {
    title: "Our Services",
    Component: Services,
  },

  {
    title: "Our Projects",
    Component: Projects,
  },
].map((n, idx) => ({ ...n, id: idx + 1 }));

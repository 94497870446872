/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

import { Blurhash } from "react-blurhash";

const MegaMenuCardProjects = ({
  clientLogo,
  imageBlurHashString,
  projectTitle,
  siteUrl,
  newTab,
}) => {
  // console.log("newTab", newTab);
  return (
    // <>
    //   {newTab === 0 ? (
    //     <a href={siteUrl}>
    //       <div className="hover:bg-slate-200 hover:rounded-md flex gap-2 flex-col items-center justify-center cursor-pointer relative">
    //         <div className="h-16 w-16">
    //           <div className="mb-1 absolute bottom-6 h-16 w-16">
    //             <Blurhash
    //               hash={imageBlurHashString}
    //               width={`100%`}
    //               height={`100%`}
    //             />
    //           </div>
    //           <div className="mb-1 absolute bottom-6 h-16 w-16 z-20">
    //             <img
    //               src={clientLogo}
    //               className="rounded-md"
    //               alt="Client Logo Photo"
    //             />
    //           </div>
    //         </div>
    //         <div>
    //           <p className="text-xs 2xl:text-[14px] font-semibold pb-1 text-gray-800">
    //             {projectTitle}
    //           </p>
    //         </div>
    //       </div>
    //     </a>
    //   ) : (
    //     <a href={siteUrl} target="_blank">
    //       <div className="hover:bg-slate-200 hover:rounded-md flex gap-2 flex-col items-center justify-center cursor-pointer relative">
    //         <div className="h-16 w-16">
    //           <div className="mb-1 absolute bottom-6 h-16 w-16">
    //             <Blurhash
    //               hash={imageBlurHashString}
    //               width={`100%`}
    //               height={`100%`}
    //             />
    //           </div>
    //           <div className="mb-1 absolute bottom-6 h-16 w-16 z-20">
    //             <img
    //               src={clientLogo}
    //               className="rounded-md"
    //               alt="Client Logo Photo"
    //             />
    //           </div>
    //         </div>
    //         <div>
    //           <p className="text-xs 2xl:text-[14px] font-semibold pb-1 text-gray-800">
    //             {projectTitle}
    //           </p>
    //         </div>
    //       </div>
    //     </a>
    //   )}
    // </>

    <>
      {newTab === 0 ? (
        <a href={siteUrl}>
          <div className="relative hover:bg-slate-200 hover:rounded-md flex gap-2 flex-col items-center justify-center cursor-pointer">
            <div className="h-16 w-16">
              <div className="mb-1 absolute bottom-6 h-16 w-16">
                <Blurhash
                  hash={imageBlurHashString}
                  width={`100%`}
                  height={`100%`}
                />
              </div>

              <div className="mb-1 absolute bottom-6 h-16 w-16 z-20">
                <img
                  src={clientLogo}
                  className="rounded-md"
                  alt="Client Logo Photo"
                />
              </div>
            </div>

            <div>
              <p className="text-xs font-semibold pb-1 text-gray-800">
                {projectTitle}
              </p>
            </div>
          </div>
        </a>
      ) : (
        <a href={siteUrl} target="_blank">
          <div className="hover:bg-slate-200 hover:rounded-md flex gap-2 flex-col items-center justify-center cursor-pointer relative">
            <div className="h-16 w-16">
              <div className="mb-1 absolute bottom-6 h-16 w-16">
                <Blurhash
                  hash={imageBlurHashString}
                  width={`100%`}
                  height={`100%`}
                />
              </div>

              <div className="mb-1 absolute bottom-6 h-16 w-16 z-20">
                <img
                  src={clientLogo}
                  className="rounded-md"
                  alt="Client Logo Photo"
                />
              </div>
            </div>

            <div>
              <p className="text-xs font-semibold pb-1 text-gray-800">
                {projectTitle}
              </p>
            </div>
          </div>
        </a>
      )}
    </>
  );
};

export default MegaMenuCardProjects;

import React, { useState } from "react";
import { useEffect } from "react";
import Iframe from "react-iframe";
import { useLocation } from "react-router-dom";

import contactusphoto from "../../src/Assets/bb_contactus.jpg";
import contactuscompressedphoto from "../../src/Assets/bb_contactus_compressed.jpg";
import Heading from "../reusable-components/fonts/Heading";

import { Blurhash } from "react-blurhash";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <>
      <section className="pb-24 lg:pb-36">
        {/* Banner section for Desktops */}
        <div className="hidden lg:block relative">
          <div className="relative">
            <div>
              <Blurhash
                hash="L24ec,~qxu9F9FD%Rjxu9FIUof-;"
                width={`100%`}
                height={500}
              />
            </div>

            <div className="absolute top-0 left-0 w-full">
              <img
                id="myImage"
                src={contactusphoto}
                alt="Contact Us photo"
                className="h-72 lg:h-[500px] w-full object-cover"
              />
            </div>
          </div>

          <div className="absolute top-1/2 -translate-y-2/4 left-10">
            <Heading classes={`text-white`}>Contact Us</Heading>
          </div>
        </div>
        {/* Banner section for Mobiles and Tablets */}
        <div className="block lg:hidden relative">
          <div className="relative">
            <div>
              <Blurhash
                hash="L24ec,~qxu9F9FD%Rjxu9FIUof-;"
                width={`100%`}
                height={72}
              />
            </div>

            <div className="absolute top-0 left-0 w-full">
              <img
                id="myImage"
                src={contactusphoto}
                alt="Contact Us photo"
                className="h-72 lg:h-[500px] w-full object-cover"
              />
            </div>
          </div>

          <div className="absolute top-5/6 -translate-y-5/6 left-10">
            <Heading classes={`text-white`}>Contact Us</Heading>
          </div>
        </div>
        <div className="mt-64 lg:mt-0 w-[90%] mx-auto">
          <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
            Our Contacts
          </Heading>
          <div className="my-10 flex justify-center font-medium text-gray-800 text-2xl lg:text-5xl">
            We're here to help you
          </div>
          <div className="my-10 lg:mx-72 xl:mx-80 font-light xl:font-normal text-gray-700 text-sm lg:text-lg text-center">
            Got a project in mind? We’d love to hear about it. Take five minutes
            to fill out our project form so that we can get to know you and
            understand your project.
          </div>

          {/* Contact Cards */}
          <section className="text-gray-700">
            <div className="my-24">
              <div className="flex flex-col justify-center items-center lg:flex-row">
                <div className="mb-5 md:mb-8 lg:mr-10 w-[100%] md:w-[60%] lg:w-full shadow-2xl px-1 lg:px-4 pt-6 pb-6 lg:pb-[44px] rounded-lg transform transition duration-500 hover:scale-110 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="text-primary w-12 h-12 mb-3 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M9 6.75V15m6-6v8.25m.503 3.498l4.875-2.437c.381-.19.622-.58.622-1.006V4.82c0-.836-.88-1.38-1.628-1.006l-3.869 1.934c-.317.159-.69.159-1.006 0L9.503 3.252a1.125 1.125 0 00-1.006 0L3.622 5.689C3.24 5.88 3 6.27 3 6.695V19.18c0 .836.88 1.38 1.628 1.006l3.869-1.934c.317-.159.69-.159 1.006 0l4.994 2.497c.317.158.69.158 1.006 0z"
                    />
                  </svg>
                  <p className="title-font font-medium text-sm lg:text-lg xl:text-[20px] text-gray-800 mt-2">
                    Visit Us Daily:
                  </p>
                  <p className="font-normal text-xs lg:text-sm xl:text-base mt-2 text-gray-700">
                    Basnett Building, D.P.H Road, Gangtok, Sikkim.
                  </p>
                </div>

                <div className="mb-5 md:mb-8 lg:mr-10 w-[100%] md:w-[60%] lg:w-full  shadow-2xl px-1 lg:px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="text-primary w-12 h-12 mb-3 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                    />
                  </svg>
                  <p className="title-font font-medium text-sm lg:text-lg xl:text-[20px] text-gray-800 mt-2">
                    Call Us:
                  </p>
                  <p className="font-normal text-xs lg:text-sm xl:text-base mt-2 text-gray-700">
                    +91 78729 69664
                  </p>
                  <p className="font-normal text-xs lg:text-sm xl:text-base text-gray-700">
                    +91 85973 55169
                  </p>
                </div>

                <div className="mb-5 md:mb-8 w-[100%] md:w-[60%] lg:w-full shadow-2xl px-1 lg:px-4 py-6 rounded-lg transform transition duration-500 hover:scale-110 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="text-primary w-12 h-12 mb-3 inline-block"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                    />
                  </svg>
                  <p className="title-font font-medium text-sm lg:text-lg xl:text-[20px] text-gray-800 mt-2">
                    Mail Us 24/7:
                  </p>
                  <p className="font-normal text-xs lg:text-sm xl:text-base mt-2 text-gray-700">
                    enquiry@bytesberry.com
                  </p>
                  <p className="font-normal text-xs lg:text-sm xl:text-base text-gray-700">
                    admin@bytesberry.com
                  </p>
                </div>
              </div>
            </div>
          </section>

          {/* Contact Form and Map */}
          <div
            className=" mt-10 mb-5 font-medium text-gray-800 text-sm lg:text-xl"
            id="contact-page"
          >
            Get In Touch
          </div>
          <div className="mb-10  font-medium text-gray-800 text-lg lg:text-4xl">
            Send Us A Message
          </div>
          <div className="">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
              <div className="lg:pr-10">
                <form
                  action="https://formspree.io/f/mzbqdzwv"
                  method="POST"
                  autocomplete="off"
                  className="p-0 flex flex-col justify-center"
                >
                  <div className="flex flex-col">
                    <label for="name" class="hidden">
                      Full Name
                    </label>
                    <input
                      type="name"
                      name="name"
                      id="name"
                      placeholder="Full Name"
                      className="w-full  mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>

                  <div class="flex flex-col mt-2">
                    <label for="email" class="hidden">
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email ID"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>

                  <div class="flex flex-col mt-2">
                    <label for="tel" class="hidden">
                      Number
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      id="tel"
                      placeholder="Contact Number"
                      className="w-100 mt-2 py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>

                  <div class="flex flex-col mt-2">
                    <label for="message" class="hidden">
                      Message
                    </label>
                    <textarea
                      rows={10}
                      type="text"
                      name="message"
                      id="message"
                      placeholder="Message"
                      className="w-100 mt-2 resize-none py-3 px-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-400 dark:border-gray-700 text-gray-800 font-semibold focus:border-primary focus:outline-none"
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      className="mb-10 md:mb-16 lg:mb-0 w-full bg-[#252744] bg-opacity-90 text-white font-bold py-3 px-6 rounded-lg mt-3 hover:bg-opacity-95 transition ease-in-out duration-300"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>

              <div className="overflow-x-scroll lg:overflow-x-hidden lg:pl-10">
                <Iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d443.0434873413098!2d88.613123!3d27.33359!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xbb211924c5b2dccc!2sBytesberry%20Technology!5e0!3m2!1sen!2sin!4v1672312998215!5m2!1sen!2sin"
                  width="800"
                  height="470"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;

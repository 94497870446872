import React, { useContext, useState } from "react";
const LogoContext = React.createContext();

//Hook to access the Logo Context we have created
export function useLogo() {
  return useContext(LogoContext);
}

export function LogoProvider({ children }) {
  const [firstDivHeight, setFirstDivHeight] = useState(0);

  return (
    <LogoContext.Provider value={{ firstDivHeight, setFirstDivHeight }}>
      {children}
    </LogoContext.Provider>
  );
}

import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import blog from "../Assets/blog.jpg";
import useAxios from "../hook/useAxios";
import Heading from "../reusable-components/fonts/Heading";
import BlogCard from "./BlogCard";
import BlogPage from "./BlogPage";
import moment from "moment";

const GET_BLOG_DETAILS = "getBlogDetail";

export default function Blog() {
  const [isDetailsActive, setIsDetailsActive] = useState(false);
  const [activeBlogDetails, setActiveBlogDetails] = useState();

  const { data, error, loading } = useAxios(GET_BLOG_DETAILS);

  // const parse = require("html-react-parser");

  let blogData = data?.BlogList;

  if (loading)
    return (
      <div className="flex items-center justify-center text-[#0E381D] my-10 font">
        <div
          className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
          role="status"
        >
          <span className="visually-hidden"></span>
        </div>
      </div>
    );
  if (error) return <p>Error :(</p>;

  // let blogData = [
  //   {
  //     id: 1,
  //     blogImage: { blog },
  //     TimetoRead: "2mins",
  //     Date: "March 1,2022",
  //     blogHeading: "Mastering the Language",
  //     blogShortDescription: "Java is not same as Javascript",
  //     blogDescription: `Opening a door to the future  Opening
  //       a door to the future  Opening a door to the future Opening a door
  //        the future Opening a door to the future to the future`,
  //   },

  //   {
  //     id: 2,
  //     blogImage: { blog },
  //     TimetoRead: "3mins",
  //     Date: "July 1,2022",
  //     blogHeading: "Lorem Ipsum2",
  //     blogShortDescription: "lorem ipsum lorem ipsum",
  //     blogDescription:
  //       "Opening a door to the future  Opening a door to the future  Opening a door to the future Opening a door to the future Opening a door to the future ",
  //   },
  //   {
  //     id: 3,
  //     blogImage: { blog },
  //     TimetoRead: "4mins",
  //     Date: "August 12 ,2022",
  //     blogHeading: "Lorem Ipsum3",
  //     blogShortDescription: "lorem ipsum ipsum lorem",
  //     blogDescription: `Opening a door to the future  Opening a door to the future
  //     Opening a door to the future Opening a door to the future Opening a
  //     door to the future uture Opening a door to the future Opening a door to the
  //     futureuture Opening a door to the futur `,
  //   },
  //   {
  //     id: 4,
  //     blogImage: { blog },
  //     TimetoRead: "4mins",
  //     blogHeading: "Lorem Ipsum4",
  //     Date: "Januray 2, 2023",
  //     blogShortDescription: "lorem ipsum ipsum lorem",
  //     blogDescription:
  //       "Opening a door to the future  Opening a door to the future  Opening a door to the future Opening a door to the future Opening a door to the future ",
  //   },
  //   {
  //     id: 5,
  //     blogImage: { blog },
  //     TimetoRead: "4mins",
  //     blogHeading: "Lorem Ipsum5",
  //     Date: "May 11, 2022",
  //     blogShortDescription: "lorem ipsum ipsum lorem",
  //     blogDescription:
  //       "Opening a door to the future  Opening a door to the future  Opening a door to the future Opening a door to the future Opening a door to the future ",
  //   },
  // ];

  return (
    <>
      <div className="relative ">
        <div className="bg-black opacity-80 h-72 lg:h-[500px] w-full object-cover absolute "></div>
        <img
          src={blog}
          alt="blog image for sikim milk"
          className="h-72 lg:h-[500px] w-full object-cover"
        />
        <p className="absolute top-1/2 -translate-y-2/4 left-16 text-white">
          <Heading>Blog</Heading>
          <h2 className="border-b-4 mr-10 pt-2"></h2>
        </p>
      </div>

      <div className="w-[75%] mx-auto my-4 ">
        <Heading classes={`py-8 text-gray-800`}>Blogs</Heading>
      </div>

      {isDetailsActive ? (
        <BlogPage
          data={blogData}
          blogData={activeBlogDetails}
          setIsDetailsActive={setIsDetailsActive}
        />
      ) : (
        <div className="grid lg:grid-cols-4 ">
          {blogData?.map((blogObj) => (
            <>
              <BlogCard
                blogImage={blogObj?.blog_img}
                TimeToRead={blogObj?.blog_timetoread}
                Date={moment(blogData.created_date).format("MMM Do, YYYY")}
                blogHeading={blogObj?.blog_title}
                blogDescription={blogObj?.blog_shortdescription}
                blog={blogObj}
                setActiveBlogDetails={setActiveBlogDetails}
                setIsDetailsActive={setIsDetailsActive}
              />
            </>
          ))}
        </div>
      )}
    </>
  );
}

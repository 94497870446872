import React, { useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import bankingbannerphoto from "../Assets/bankingrandom.jpg";
import websitephoto from "../Assets/sabccohome.png";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

const ProjectSabcco = () => {
  const bannerSabccoPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSabccoPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSabccoPage?.current?.offsetHeight);
    }
  }, [bannerSabccoPage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerSabccoPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={bankingbannerphoto}
              alt="banking_photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10">
              <Heading classes={`text-white`}>SABCCO</Heading>
              <p className=" text-white font-medium text-xl lg:text-2xl">
                Loan Management System
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
                SABCCO Loan Management System
              </Heading>
              {/* <div className="mt-0 md:mt-10 mb-0 md:mb-5 md:absolute md:right-0">
                <a
                  href="/our-projects"
                  className="text-white text-[10px] lg:text-sm bg-[#E75124] border px-2 py-1 cursor-pointer rounded-md hover:underline"
                >
                  &lt; Go Back
                </a>
              </div> */}
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed text-gray-700">
                  An end-to-end online solution in the form of a web application
                  encompassing all aspect of the organization i.e. from public
                  interfaces to automating the entire in-house processes. The
                  portal will have capability to disseminate information and
                  awareness to citizens by providing ‘anytime-anywhere’ access
                  to rich and relevant content about SABCCO’s schemes,
                  objectives, goals and loan related information. The portal
                  shall also have features to enhance, streamline, automate and
                  digitize the back-office processes for increased accuracy,
                  efficiency and remove duplicacy of effort. These include
                  features like applying for loan, login facilities, features to
                  upload images, capabilities for notification and communication
                  etc.
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="https://sikkimsabcco.com/"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-[#E75124] text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
                {/* <div className="mt-4 xl:mt-6 flex flex-row md:justify-start lg:justify-end pr-12">
                  <>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      React JS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Node JS
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      PostgreSQL
                    </div>
                  </>
                </div> */}
              </div>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>
            <div className="mb-10">
              <ul className="list-disc font-medium text-xs lg:text-base 2xl:text-[20px]  mx-10">
                <li className="mb-4 text-gray-700">
                  <span className="font-semibold text-black">
                    Loan Origination:
                  </span>{" "}
                  This feature includes the ability to enter borrower
                  information, loan terms, and loan amount to create a loan
                  application. The system may also support online applications,
                  e-signatures, and document uploads.
                </li>
                <li className="mb-4 text-gray-700">
                  <span className="font-semibold text-black">
                    Loan Servicing:
                  </span>{" "}
                  This feature includes managing loan payments, sending payment
                  reminders, processing late fees, managing escrow accounts, and
                  handling customer service inquiries.
                </li>

                <li className="mb-4 text-gray-700 ">
                  <span className="font-semibold text-black">
                    {" "}
                    Reporting and Analytics:{" "}
                  </span>{" "}
                  This feature includes generating reports and dashboards that
                  provide insight into loan performance, portfolio risk, and
                  profitability. The system should also have the ability to
                  track key performance indicators (KPIs) such as delinquency
                  rates, loan-to-value ratios, and debt-to-income ratios.
                </li>
                <li className="mb-4 text-gray-700">
                  <span className="text-black font-semibold"> Automation:</span>{" "}
                  The system is able to automate routine tasks such as intrest
                  calculation such as simple interest, penal interest ,interest
                  on interest ,one-time-settkement and decree case. And Generte
                  demand Notice.
                </li>
                <li className="mb-4 text-gray-700">
                  <span className="font-semibold text-black">
                    User Experience:
                  </span>{" "}
                  The system have a user-friendly interface, with easy-to-use
                  tools and features that streamline the loan management process
                  for both lenders and borrowers.
                </li>
              </ul>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Technology Used
            </SubHeading>
            <div className="flex flex-row">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  React JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Node JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  PostgreSQL
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectSabcco;

import React, { useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import egovbannerphoto from "../Assets/egovernancerandom.jpg";
import websitephoto from "../Assets/sechome.png";
import Heading from "../reusable-components/fonts/Heading";

const ProjectSecSikkim = () => {
  const bannerSecSikkimPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerSecSikkimPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerSecSikkimPage?.current?.offsetHeight);
    }
  }, [bannerSecSikkimPage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerSecSikkimPage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={egovbannerphoto}
              alt="Contact Us photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-1/2 -translate-y-2/4 left-10">
              <Heading classes={`text-white`}>SEC, Sikkim</Heading>
              <p className=" text-white font-medium text-xl lg:text-2xl">
                Website
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading classes={`mt-10 mb-5 flex justify-center text-gray-800`}>
                State Election Commission, Sikkim-Website
              </Heading>
              {/* <div className="mt-0 md:mt-10 mb-0 md:mb-5 md:absolute md:right-0">
                <a
                  href="/our-projects"
                  className="text-white text-[10px] lg:text-sm bg-[#E75124] border px-2 py-1 cursor-pointer rounded-md hover:underline"
                >
                  &lt; Go Back
                </a>
              </div> */}
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2 text-gray-700">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed">
                  This is a website for the State Election Commission, Gangtok
                  Sikkim built with an aim to provide essential election related
                  information to the public. It enables public to view
                  notification & circulars from the Government. The website has
                  sophisticated and flexible admin capabilities to easily manage
                  and scrutinize their content prior to it getting published. It
                  has all the facets of a modern website such as screen
                  adaptability for all screen sizes(desktops, laptops, mobile
                  phones & tablets).
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="http://sec.sikkim.gov.in/"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-[#E75124] text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
                {/* <div className="mt-4 xl:mt-6 flex flex-row md:justify-start lg:justify-end pr-12">
                  <>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      ASP.NET
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      Bootstrap
                    </div>
                    <div
                      className="rounded-3xl mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                    >
                      MS SQL
                    </div>
                  </>
                </div> */}
              </div>
            </div>
            <div className="mt-16 lg:mt-28 mb-5 lg:mb-10 text-base lg:text-2xl font-semibold text-gray-800">
              Features
            </div>
            <div className="mb-10">
              <ul className="list-disc font-normal text-xs lg:text-base 2xl:text-[20px]  mx-10 text-justify text-gray-700">
                <li className="mb-4">
                  It is an informative website providing information related to
                  various aspects like the organization, the rules and
                  guidelines in correspondence to elections held in the state,
                  the list of voters eligible for a particular election, the
                  Panchayati Raj and Municipalities across the state, etc.
                </li>
                <li className="mb-4">
                  Additionally, the website contains a CMS (Content Management
                  System) with login functionality that aids the system
                  administrator in changing the content throughout the entire
                  website.
                </li>
              </ul>
            </div>
            <div className="mt-16 lg:mt-28 mb-5 lg:mb-10 text-base lg:text-2xl font-semibold text-gray-800">
              Technology Used
            </div>
            <div className="flex flex-row">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  ASP.NET
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Bootstrap
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  MS SQL
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectSecSikkim;

import React from "react";

const Heading = ({ classes, ...rest }) => {
  return (
    <div>
      <h1
        className={`text-center 2xl:text-5xl lg:text-3xl text-2xl font-sans font-semibold  ${classes}`}
      >
        {rest.children}
      </h1>
    </div>
  );
};

export default Heading;

const projectsSectorsArr = [
  {
    id: 0,
    sectorName: "All",
  },

  { id: 1, sectorName: "Banking" },

  {
    id: 2,
    sectorName: "Hospitality",
  },

  {
    id: 3,
    sectorName: "Dairy",
  },

  { id: 4, sectorName: "e-Governance" },

  {
    id: 5,
    sectorName: "Community",
  },

  {
    id: 6,
    sectorName: "Human Resource",
  },

  {
    id: 7,
    sectorName: "e-Commerce",
  },

  {
    id: 8,
    sectorName: "Education",
  },
];

module.exports = {
  projectsSectorsArr,
};

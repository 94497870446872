import React, { useEffect, useRef, useState } from "react";
import { motion, useAnimation } from "framer-motion";

const FadeInLeftRight = ({ children, gridColumn }) => {
  const ref = useRef();
  const controls = useAnimation();
  const [animationTriggered, setAnimationTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const windowHeight = window.innerHeight;

      if (ref.current && !animationTriggered) {
        const elementTop = ref.current.getBoundingClientRect().top;
        const elementBottom = ref.current.getBoundingClientRect().bottom;

        // Adjust this threshold percentage as needed
        const threshold = 0.3; // 30% of the element needs to be visible

        if (
          elementTop + (elementBottom - elementTop) * threshold <
            windowHeight &&
          elementBottom > 0
        ) {
          controls.start("visible");
          setAnimationTriggered(true);

          // Remove the scroll event listener once animation is triggered
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    handleScroll(); // Trigger once on initial load

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [controls, animationTriggered]);

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: gridColumn === 1 ? -50 : 50 },
      }}
      transition={{ duration: 0.6 }}
    >
      {children}
    </motion.div>
  );
};

export default FadeInLeftRight;

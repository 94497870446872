import React from "react";

const OurServicesCardMobileNavbar = ({ title, description, icon }) => {
  return (
    <>
      <div className="hover:bg-slate-200 hover:rounded-md py-2 flex flex-row items-start gap-4 my-1 cursor-pointer">
        <div>{icon}</div>
        <div className="flex flex-col">
          <p className="text-sm font-semibold pb-1 text-white">{title}</p>
          {/* <p className="text-xs font-normal pb-1 text-gray-300">
            {description}
          </p> */}
        </div>
      </div>
    </>
  );
};

export default OurServicesCardMobileNavbar;

import React, { useState, useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import ourprojectsphoto from "../../src/Assets/ourproj.jpg";
import SectorTab from "../reusable-components/SectorTab";

import siscobankSS from "../Assets/ProjectCardScreenshots/SiscoWebsiteHome.PNG";
import sabccoSS from "../Assets/ProjectCardScreenshots/SabccoWebsiteHome.PNG";
import sisSS from "../Assets/ProjectCardScreenshots/SISWebsiteHome.PNG";
import secSS from "../Assets/ProjectCardScreenshots/SECWebsiteHome.PNG";
import seedSS from "../Assets/ProjectCardScreenshots/seedhome.png";
import smumitraSS from "../Assets/ProjectCardScreenshots/mitra app.jpg";
import smuhrmsSS from "../Assets/ProjectCardScreenshots/SmuHrms.jpeg";
import mystichimalayanSS from "../Assets/ProjectCardScreenshots/MysticHimalayanWebsiteHome.PNG";
import tourismSS from "../Assets/ProjectCardScreenshots/TourismWebsiteHome.PNG";
import panchakSS from "../Assets/ProjectCardScreenshots/PanchakWebsiteHome.PNG";
import aashitatSS from "../Assets/ProjectCardScreenshots/aashitat_home.jpeg";
import eaglesNestSS from "../Assets/ProjectCardScreenshots/eaglesnesthome.PNG";
import positudeSS from "../Assets/ProjectCardScreenshots/PositudeWebsiteHome.PNG";
import sikkimawardsSS from "../Assets/ProjectCardScreenshots/SikkimAwardsWebsiteHome.PNG";
import smupniSS from "../Assets/ProjectCardScreenshots/SmuPniWebsiteHome.PNG";
import rsbSS from "../Assets/ProjectCardScreenshots/RSBWebsiteHome.PNG";
import gratitudeSS from "../Assets/ProjectCardScreenshots/gratitude_home.jpeg";
import nitSS from "../Assets/ProjectCardScreenshots/nit_home.jpeg";

import { projectsSectorsArr } from "../utilities/constants.js";
import ProjectsCard from "../reusable-components/ProjectsCard";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

// Sectors defined in src -> utilities -> constants.js

const Projects = () => {
  const bannerOurProjectsPage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerOurProjectsPage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerOurProjectsPage?.current?.offsetHeight);
    }
  }, [bannerOurProjectsPage?.current?.offsetHeight]);

  const [activeProjectCategorizer, setActiveProjectCategorizer] = useState(1);
  const [activeSectorTab, setActiveSectorTab] = useState(0);
  const [filteredSectorWiseArray, setFilteredSectorWiseArray] = useState([]);

  const projectsArray = [
    {
      id: 1,
      projectTitle: "Sikkim Tourism",
      projectSectors: [2, 4],
      projectImage: tourismSS,
      projectImageBlurHashString: "LcF~pzRh-;WA_NX9RkWnx]xvRQjZ",
      projectType: "Portal",
      projectUrl: "/sikkim-tourism-view-details",
      projectBrief:
        "This is the official web portal for the Tourism and Civil Aviation Deparment, Government of Sikkim, which features first-rate online resources and services. Included among the portal's unique features are: Content Management system, Service provider registration, Travel Card.",
      projectUrl: "/sikkim-tourism-view-details",
      newTab: 0,
    },
    {
      id: 2,
      projectTitle: "State Election Commission, Sikkim",
      projectSectors: [4],
      projectImage: secSS,
      projectImageBlurHashString: "LIJ[hm.ADjInMcNet6s9o#Vrxtxu",
      projectType: "Website",
      projectBrief:
        "This is the offical website for the State Election Commission, Government of Sikkim. It enables the general public to access government notices and circulars pertaining to elections. The website contains comprehensive and adaptable admin features that make it simple to control and carefully review material before it is published. It features all the characteristics of a contemporary website, including screen adaptation for various screen sizes (desktops, laptops, mobile phones & tablets).",
      projectUrl: "/sec-sikkim-view-details",
      newTab: 0,
    },
    {
      id: 3,
      projectTitle: "SMU Mitra",
      projectSectors: [3],
      projectImage: smumitraSS,
      projectImageBlurHashString: "L5SY~#9W00-:$+xHxb-q~T9e-lM}",
      projectType: "Portal",
      projectUrl: "/sikkim-milk-mitra-app-view-details",
      projectBrief:
        "This is the official Sikkim Milk Union mobile app that allows registered agents, Army units, and institutions to regularly order items.",
      projectUrl: "/sikkim-milk-mitra-app-view-details",
      newTab: 0,
    },

    {
      id: 4,
      projectTitle: "SABCCO",
      projectSectors: [1],
      projectImage: sabccoSS,
      projectImageBlurHashString: "L130Ne+R?].l%f$,%zn94TM{TEHZ",
      projectType: "Portal",
      projectBrief:
        "The Web Portal will have the potential to disseminate information and awareness to individuals by offering 'anytime, anywhere' access to rich and relevant content on SABCCO's schemes, aims, goals, and loan-related information. With features like loan application, login options, picture upload options, notification and communication capabilities, etc., the portal will also include features to improve, simplify, automate, and digitise the back-office procedures for enhanced accuracy and efficiency.",
      projectUrl: "/sabcco-view-details",
      newTab: 0,
    },
    {
      id: 5,
      projectTitle: "Sisco Bank",
      projectSectors: [1],
      projectImage: siscobankSS,
      projectImageBlurHashString: "LcH-lXAsR5t6~Wf+ozaypw$PNGV[",
      projectType: "Portal",
      projectBrief:
        "This is the official website for Sikkim State Co-operative Bank Pvt Ltd, which was created with the goal of giving its customers access to essential banking information. The website offers a section for the general public to make service requests, such as those for opening an account, credit counselling, requesting a chequebook, etc. The website contains comprehensive and adaptable admin features that make it simple to control and carefully review material before it is published.",
      projectUrl: "/sisco-bank-view-details",
      newTab: 0,
    },
    {
      id: 6,
      projectTitle: "Rajya Sainik Board",
      projectSectors: [4],
      projectImage: rsbSS,
      projectImageBlurHashString: "LWKnSIni?wt6^+S#M{o0TzRQi^kC",
      projectType: "Portal",
      projectBrief:
        "This is a dynamic website for the Department of Sainik Welfare (Government of Sikkim), which contains all information on state Sainik welfare and other activities linked to the armed forces. The website seeks to establish itself as a focal point for the transmission of information about numerous initiatives, programmes, and activities pertaining to the department. It's driven by a customised CMS (content management system), which gives the administrator easy and effective control over how its content is managed.",
      projectUrl: "/rsb-view-details",
      newTab: 0,
    },
    {
      id: 7,
      projectTitle: "Namgyal Institute of Tibetology",
      projectSectors: [8],
      projectImage: nitSS,
      projectImageBlurHashString: "LHR2i1~qE0x]00%NM{t7M=D%xvV@",
      projectType: "Website",
      projectBrief: "Educational Institute",
      projectUrl: "https://namgyalinstitutesikkim.org/",
      newTab: 1,
    },
    {
      id: 8,
      projectTitle: "Sikkim Investment Summit",
      projectSectors: [4],
      projectImage: sisSS,
      projectImageBlurHashString: "LVN^h-t7?Gaet5R*M{R%?wjuIoWV",
      projectType: "Portal",
      projectBrief:
        "This is the Sikkim Investment Summit's official website, which offers details on the event.",
      projectUrl: "/sikkim-investment-summit-view-details",
      newTab: 0,
    },
    {
      id: 9,
      projectTitle: "SEED Cell",
      projectSectors: [5],
      projectImage: seedSS,
      projectImageBlurHashString: "LbIF#[oc%MWr00aeNHae~Va}RjoI",
      projectType: "Portal",
      projectBrief:
        "It is the official website for the Sikkim Entrepreneurship & Economic Development Society, whose goal is to create a thorough database of every entrepreneur in Sikkim. The site will aid in the development of a solid digital ecosystem by providing a platform for entrepreneurs to keep up with their online reputations, which will aid in their efforts to expand their customer base and build networks with other fellow entrepreneurs.",
      projectUrl: "/seed-cell-view-details",
      newTab: 0,
    },
    {
      id: 10,
      projectTitle: "Sikkim Awards",
      projectSectors: [5],
      projectImage: sikkimawardsSS,
      projectImageBlurHashString: "L67nI0_NRPD%ROIVe.j[004n%M%M",
      projectType: "Portal",
      projectBrief:
        "It is a website that advertises Sikkim Awards 2022, which was held in Sikkim for the first time. The website is also accessible on all platforms, including desktop, laptop, tablet, and mobile.",
      projectUrl: "https://sikkimawards.com/",
      newTab: 1,
    },
    {
      id: 11,
      projectTitle: "Positude",
      projectSectors: [5],
      projectImage: positudeSS,
      projectImageBlurHashString: "L48qKN?[+vEMR8n6?H%g7L-VPA^k",
      projectType: "Portal",
      projectBrief:
        "This is Positude's official website, which provides details about the business and details on recent and forthcoming events.",
      projectUrl: "https://positude.in/",
      newTab: 1,
    },
    {
      id: 12,
      projectTitle: "Mystic Himalayan",
      projectSectors: [2],
      projectImage: mystichimalayanSS,
      projectImageBlurHashString: "LfFi}jahIVt6_4oLR*of-;oKoyf6",
      projectType: "Website",
      projectBrief:
        "This website serves as a marketing tool for Mystic Himalayan World Travels by exhibiting the services and amenities it provides, as well as information about its tours and treks and its locations. All devices, including desktop, laptop, tablet, and mobile, can access the website.",
      projectUrl: "https://mystichimalayan.com/",
      newTab: 1,
    },
    {
      id: 13,
      projectTitle: "Panchak Holidays",
      projectSectors: [2],
      projectImage: panchakSS,
      projectImageBlurHashString: "LB7-+,sm4:j]yZoeskkC9ZWA%MoL",
      projectType: "Website",
      projectBrief:
        "This is the official website for Panchak Holidays, which provides details about the business and the services it offers.",
      projectUrl: "https://panchakholidays.com/",
      newTab: 1,
    },
    {
      id: 14,
      projectTitle: "Eagles Nest Gangtok",
      projectSectors: [2],
      projectImage: eaglesNestSS,
      projectImageBlurHashString: "LB8O0T%MM{j]00InoJof~q%2t7of",
      projectType: "Website",
      projectBrief:
        "This is the official website for Eagles Nest Gangtok, which provides details about the business and the services it offers.",
      projectUrl: "https://eaglesnestgangtok.com/",
      newTab: 1,
    },
    {
      id: 15,
      projectTitle: "Gratitude - Forever Grateful",
      projectSectors: [7],
      projectImage: gratitudeSS,
      projectImageBlurHashString: "LdL}1?M{RjWB~qRP%May-;t7IUof",
      projectType: "Portal",
      projectBrief:
        "An E-Commerce website developed for a Local Entrepreneur from Sikkim which is an exclusive platform catering to a wide range of customers with their unique apparels made in Sikkim.",
      projectUrl: "/gratitude-view-details",
      newTab: 1,
    },
    {
      id: 16,
      projectTitle: "Aashi Tours and Travels",
      projectSectors: [2],
      projectImage: aashitatSS,
      projectImageBlurHashString: "L?L4slj[WBja~qayaya|Wnayfka|",
      projectType: "Website",
      projectBrief:
        "This is the official website for Aashi Tours and Travels, which provides details about the business and the services it offers.",
      projectUrl: "https://aashitoursandtravels.com/",
      newTab: 1,
    },
    {
      id: 17,
      projectTitle: "SMU HRMS",
      projectSectors: [6],
      projectImage: smuhrmsSS,
      projectImageBlurHashString: "L8Q0dd00000007N5xv^%~n-.t8R;",
      projectType: "Portal",
      projectUrl: "/sikkim-milk-hrms-view-details",
      projectBrief:
        "This is the official Human Resource Management software through which the administrator will be able to manage all data pertaining to employees of Sikkim Milk Union.",
      projectUrl: "/sikkim-milk-hrms-view-details",
      newTab: 0,
    },
    {
      id: 18,
      projectTitle: "SMU P&I",
      projectSectors: [3],
      projectImage: smupniSS,
      projectImageBlurHashString: "LTPGv,t6~qWBI[t6IUM{o}M_Rj%M",
      projectType: "Portal",
      projectBrief:
        "P&I web portal is a web application integrated with Sikkim Milk official website which is responsible for managing and keeping track of daily milk collection from various milk societies across Sikkim.",
      projectUrl: "/sikkim-milk-union-p-and-i-view-details",
      newTab: 0,
    },
  ];

  const onActiveSectorChangeHandler = () => {
    let sectorWiseArr = projectsArray.filter((filterObj) => {
      if (activeSectorTab === 0) {
        return true;
      } else return filterObj?.projectSectors?.includes(activeSectorTab);
    });

    setFilteredSectorWiseArray(sectorWiseArr);
  };

  const filteredPortalArray = projectsArray.filter(
    (filterObj) => filterObj?.projectType === "Portal"
  );

  const filteredWebsiteArray = projectsArray.filter(
    (filterObj) => filterObj?.projectType === "Website"
  );

  const onProjectCategoryClickHandler = (categoryID) => {
    setActiveProjectCategorizer(categoryID);
  };

  useEffect(() => {
    onActiveSectorChangeHandler();
  }, [activeSectorTab]);

  return (
    <>
      <section className="pb-24 lg:pb-36">
        <div className="relative" ref={bannerOurProjectsPage}>
          <div className="bg-black opacity-75 h-72 lg:h-[500px] w-full object-cover absolute "></div>
          <img
            src={ourprojectsphoto}
            alt="Contact Us photo"
            className="h-72 lg:h-[500px] w-full object-cover"
          />
          <div className="absolute top-1/2 -translate-y-2/4 left-10">
            <Heading classes={`text-white`}>Our Projects</Heading>
          </div>
        </div>

        <div className="w-[90%] mx-auto">
          {/* <div className="mt-10 mb-5 flex justify-center font-semibold mx-4 lg:mx-64 2xl:mx-96 text-xl lg:text-3xl xl:text-4xl">
            Here are some of our favorite projects we have done. Feel free to
            check them out.
          </div> */}
          <div className="text-gray-800 text-center text-base md:text-lg xl:text-2xl 2xl:text-4xl font-semibold my-10 md:my-16  w-3/4 md:w-2/4 mx-auto">
            Here are some of our favorite projects we have done.
          </div>
          <div className="flex flex-col items-center mx-auto">
            <div className="flex flex-row">
              <span
                id="1"
                className={`mr-4 lg:mr-8 font-bold text-xs lg:text-base xl:text-lg cursor-pointer hover:text-[#E75124] ${
                  activeProjectCategorizer === 1
                    ? "text-primary px-4  py-1 border-2 border-primary rounded-md"
                    : "text-gray-800 px-4 py-1"
                }`}
                onClick={() => onProjectCategoryClickHandler(1)}
              >
                By Sectors
              </span>

              <span
                id="2"
                className={`mr-4 lg:mr-8 font-bold text-xs lg:text-base xl:text-lg cursor-pointer hover:text-[#E75124] ${
                  activeProjectCategorizer === 2
                    ? "text-primary px-4  py-1 border-2 border-primary rounded-md"
                    : "text-gray-800 px-4  py-1"
                }`}
                onClick={() => onProjectCategoryClickHandler(2)}
              >
                Portals
              </span>
              <span
                id="3"
                className={`mr-4 lg:mr-8 font-bold text-xs lg:text-base xl:text-lg cursor-pointer hover:text-[#E75124] ${
                  activeProjectCategorizer === 3
                    ? "text-primary px-4  py-1 border-2 border-primary rounded-md"
                    : "text-gray-800 px-4  py-1"
                }`}
                onClick={() => onProjectCategoryClickHandler(3)}
              >
                Websites
              </span>
            </div>
            {/* activeProjectCategorizer: 1[Sector], 2[Portal], 3[Website] */}
            {activeProjectCategorizer === 1 && (
              <div>
                <div className="mt-8 mb-10 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-x-3 gap-y-3">
                  {activeProjectCategorizer === 1 ? (
                    <>
                      {projectsSectorsArr.map((sectorObj) => (
                        <>
                          <SectorTab
                            sectorName={sectorObj?.sectorName}
                            sectorID={sectorObj?.id}
                            setActiveSectorTab={setActiveSectorTab}
                            activeSectorTab={activeSectorTab}
                          />
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <div className="flex gap-3 items-center text-white">
                        No sub-division!
                      </div>
                    </>
                  )}
                </div>

                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {filteredSectorWiseArray?.map((mapObj) => (
                      <div className="my-10 mx-6">
                        <ProjectsCard
                          projectImage={mapObj?.projectImage}
                          projectImageBlurHashString={
                            mapObj?.projectImageBlurHashString
                          }
                          projectSectors={mapObj?.projectSectors}
                          projectType={mapObj?.projectType}
                          projectTitle={mapObj?.projectTitle}
                          projectBrief={mapObj?.projectBrief}
                          projectUrl={mapObj?.projectUrl}
                          newTab={mapObj?.newTab}
                        />
                      </div>
                    ))}
                  </div>
                </>
              </div>
            )}
            <div className="mt-6">
              {activeProjectCategorizer === 2 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {filteredPortalArray.map((mapObj) => (
                    <div className="m-10">
                      <ProjectsCard
                        projectImage={mapObj?.projectImage}
                        projectImageBlurHashString={
                          mapObj?.projectImageBlurHashString
                        }
                        projectSectors={mapObj?.projectSectors}
                        projectType={mapObj?.projectType}
                        projectTitle={mapObj?.projectTitle}
                        projectBrief={mapObj?.projectBrief}
                        projectUrl={mapObj?.projectUrl}
                        newTab={mapObj?.newTab}
                      />
                    </div>
                  ))}
                </div>
              )}
              {activeProjectCategorizer === 3 && (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                  {filteredWebsiteArray.map((mapObj) => (
                    <div className="m-10">
                      <ProjectsCard
                        projectImage={mapObj?.projectImage}
                        projectImageBlurHashString={
                          mapObj?.projectImageBlurHashString
                        }
                        projectSectors={mapObj?.projectSectors}
                        projectType={mapObj?.projectType}
                        projectTitle={mapObj?.projectTitle}
                        projectBrief={mapObj?.projectBrief}
                        projectUrl={mapObj?.projectUrl}
                        newTab={mapObj?.newTab}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Projects;

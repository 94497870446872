import React, { useRef, useEffect } from "react";

import { useLogo } from "../context/LogoContext";

import ecommercebannerphoto from "../Assets/e-commerc.jpg";
import websitephoto from "../Assets/gratitude_home.jpeg";
import websitephoto2 from "../Assets/gratitude_products_section_filtered.jpeg";
import websitephoto3 from "../Assets/gratitude_view_products_filtered.jpeg";
import Heading from "../reusable-components/fonts/Heading";
import SubHeading from "../reusable-components/fonts/SubHeading";

const ProjectGratitude = () => {
  const bannerGratitudePage = useRef();

  const { setFirstDivHeight } = useLogo();

  useEffect(() => {
    if (bannerGratitudePage?.current?.offsetHeight > 0) {
      setFirstDivHeight(bannerGratitudePage?.current?.offsetHeight);
    }
  }, [bannerGratitudePage?.current?.offsetHeight]);

  return (
    <>
      <div>
        <section className="pb-24 lg:pb-36">
          <div className="relative" ref={bannerGratitudePage}>
            <div className="bg-black opacity-70 h-72 lg:h-[500px] w-full object-cover absolute"></div>
            <img
              src={ecommercebannerphoto}
              alt="e-Commerce photo"
              className="h-72 lg:h-[500px] w-full object-cover"
            />
            <div className="absolute top-[50%] lg:top-[40%] -translate-y-2/4 left-10">
              <Heading classes={`text-white -ml-3`}>Gratitude</Heading>
              <p className="text-white font-medium text-xl lg:text-2xl ">
                e-Commerce
              </p>
            </div>
          </div>
          <div className="flex justify-end">
            <a
              href="/our-projects"
              className=" text-white text-sm bg-primary  px-4 py-2 cursor-pointer hover:underline"
            >
              View All Projects
            </a>
          </div>
          <div className="w-[80%] mx-auto">
            <div className="md:relative flex flex-col md:flex-row justify-center items-center">
              <Heading
                classes={`mt-10 mb-5 flex justify-center font-medium text-gray-800`}
              >
                Gratitude Official Website
              </Heading>
            </div>
            <div className="mt-10 lg:mt-16 mb-5 lg:mb-10 grid grid-cols-1 lg:grid-cols-2">
              <div className="text-xs lg:text-base 2xl:text-[20px] font-normal mb-10 lg:mb-0 lg:pr-8 text-justify">
                <p className="leading-relaxed text-gray-700">
                  An E-Commerce website developed for a Local Entrepreneur from
                  Sikkim which is an exclusive platform catering to a wide range
                  of customers with their unique apparels made in Sikkim.
                </p>
              </div>
              <div className="flex flex-col">
                <div className="relative flex justify-end shadow-2xl">
                  <img src={websitephoto} className="rounded-md h-full" />
                  <a
                    href="https://gratitudefg.com/"
                    target="_blank"
                    className="absolute bottom-4 right-6 bg-[#E75124] text-white cursor-pointer px-2 md:px-4 py-1 text-[10px] md:text-sm"
                  >
                    Visit site
                  </a>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-y-10">
              <SubHeading classes={`mt-16 lg:mt-28 text-gray-800`}>
                Snapshots
              </SubHeading>
              <img src={websitephoto2} className="rounded-md border" />
              <img src={websitephoto3} className="rounded-md border" />
            </div>

            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Features
            </SubHeading>
            <div className="mb-10">
              <ul className="list-disc font-normal text-xs lg:text-base 2xl:text-[20px]  mx-10 text-justify text-gray-700">
                <li className="mb-4">User-Friendly Interface</li>
                <li className="mb-4">Online Ordering</li>
                <li className="mb-4">Customizable Storefronts</li>
                <li className="mb-4">Secure Payment Gateway</li>
                <li className="mb-4">Inventory Management</li>
                <li className="mb-4">Marketing and SEO Tools</li>
                <li className="mb-4">Device Responsiveness</li>
              </ul>
            </div>
            <SubHeading classes={`mt-16 lg:mt-28 mb-5 lg:mb-10 text-gray-800`}>
              Technology Used
            </SubHeading>
            <div className="flex flex-row">
              <>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  React JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  Node JS
                </div>
                <div
                  className="mr-2 lg:mr-4 mb-2 lg:mb-4 font-medium px-1 lg:px-3 py-1 w-[85px] lg:w-40 xl:w-44 text-center text-[10px] lg:text-sm xl:text-lg
                   bg-[#252744] text-white"
                >
                  PostgreSQL
                </div>
              </>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ProjectGratitude;

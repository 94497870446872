import React from "react";

const WhyBytesberryCardHome = (props) => {
  return (
    <>
      <div
        className="card-region flex flex-col justify-center items-center transition duration-500
       ease-in-out hover:shadow-2xl hover:shadow-[#FAE4D2] px-4 py-4 md:px-9 lg:px-4 lg:py-8 mx-2 lg:mx-12 mb-2 lg:mb-4 2xl:mx-16 2xl:mb-6"
      >
        <div className="mb-2">{props.children}</div>
        <div className="mb-2">
          <p className="text-gray-700 text-lg lg:text-xl font-medium text-center">
            {props.title}
          </p>
        </div>
        <div className="">
          <p className="text-gray-700 text-sm lg:text-[15px] 2xl:text-[17px] font-normal text-center md:text-justify lg:text-center">
            {props.description}
          </p>
        </div>
      </div>
    </>
  );
};

export default WhyBytesberryCardHome;

/* eslint-disable react/jsx-no-target-blank */
import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import "./Navbar.css";

import ShiftingDropDown from "./ShiftingDropDown";

import { useLogo } from "../../context/LogoContext";

import bytesberrylogo_white from "../../Assets/bb_logo_new_white.png";
import bytesberrylogo_black from "../../Assets/bb_logo_new_black.png";

import siscobanklogoMM from "../../Assets/ProjectsMMLogos/siscobanklogo_mm.jpg";
import sabccologoMM from "../../Assets/ProjectsMMLogos/sabccologo_mm.jpg";
import tourismlogoMM from "../../Assets/ProjectsMMLogos/tourismlogo_mm.jpg";
import panchaklogoMM from "../../Assets/ProjectsMMLogos/panchaklogo_mm.jpg";
import smulogoMM from "../../Assets/ProjectsMMLogos/smulogo_mm.jpg";
import seclogoMM from "../../Assets/ProjectsMMLogos/seclogo_mm.jpg";
import sislogoMM from "../../Assets/ProjectsMMLogos/sislogo_mm.jpg";
import rsblogoMM from "../../Assets/ProjectsMMLogos/rsblogo_mm.jpg";
import sikkimawardslogoMM from "../../Assets/ProjectsMMLogos/sikkimawardslogo_mm.jpg";
import positudelogoMM from "../../Assets/ProjectsMMLogos/positudelogo_mm.png";
import seedlogoMM from "../../Assets/ProjectsMMLogos/seedlogo_mm.jpg";
import eaglesnestlogoMM from "../../Assets/ProjectsMMLogos/eaglesnestlogo.png";

import OurProjects from "./subMenu/OurProjects";
import OurServices from "./subMenu/OurServices";
import { projectsSectorsArr } from "../../utilities/constants";
import OurServicesMobile from "./subMenu/OurServicesMobile";
import OurProjectsMobile from "./subMenu/OurProjectsMobile";

export default function Navbar() {
  return (
    <>
      <NavbarSection />
    </>
  );
}

function NavbarSection() {
  const [showMenu, setShowMenu] = useState(false);
  const [isNavBarTransparent, setIsNavBarTransparent] = useState(false);
  const [open, setOpen] = useState(false); //opening and closing of a particular menu inside of mobile navbar
  const [ourServicesSidebarOpen, setOurServicesSidebarOpen] = useState(false);
  const [ourProjectsSidebarOpen, setOurProjectsSidebarOpen] = useState(false);
  const [openProjectMegaMenu, setOpenProjectMegaMenu] = useState(false);
  const [openServiceMegaMenu, setOpenServiceMegaMenu] = useState(false);

  const [isFirstDiv, setIsFirstDiv] = useState(true);
  const [companyLogo, setCompanyLogo] = useState(bytesberrylogo_white);

  const { firstDivHeight } = useLogo();

  const location = useLocation();

  useEffect(() => {
    const updateNavbarcolor = () => {
      if (window.scrollY >= 30) {
        setIsNavBarTransparent(false);
      } else {
        setIsNavBarTransparent(true);
      }

      if (window.scrollY >= firstDivHeight) {
        // console.log(firstDivHeight, "inside use effect");
        setIsFirstDiv(false);
        setCompanyLogo(bytesberrylogo_black);
      } else {
        setIsFirstDiv(true);
        setCompanyLogo(bytesberrylogo_white);
      }
    };

    window.addEventListener("scroll", updateNavbarcolor);

    return () => window.removeEventListener("scroll", updateNavbarcolor);
  }, [firstDivHeight]);

  const navbarAnimationTrue =
    "scale-100 transition-all ease-in-out duration-500 ";
  const navbarAnimationFalse =
    "scale-95 transition-all ease-in-out duration-500 ";

  const projectsArr = [
    {
      id: 1,
      projectTitle: "Sikkim Tourism",
      sectors: [2, 4],
      clientLogo: tourismlogoMM,
      imageBlurHashString: "LQQ0XPj]~nofxtazj[j[~oj@9HfQ",
      siteUrl: "/sikkim-tourism-view-details",
      newTab: 0,
    },

    {
      id: 2,
      projectTitle: "SEC, Sikkim",
      sectors: [4],
      clientLogo: seclogoMM,
      imageBlurHashString: "LXQ0XHWB~qt7t7fQayfQ-;j[D%WB",
      siteUrl: "/sec-sikkim-view-details",
      newTab: 0,
    },

    {
      id: 3,
      projectTitle: "SMU Mitra",
      sectors: [3],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-mitra-app-view-details",
      newTab: 0,
    },

    {
      id: 4,
      projectTitle: "SMU P&I",
      sectors: [3],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-union-p-and-i-view-details",
      newTab: 0,
    },

    {
      id: 5,
      projectTitle: "SMU HRMS",
      sectors: [6],
      clientLogo: smulogoMM,
      imageBlurHashString: "LTOW$@az~ooft6azofj[~pj[IVay",
      siteUrl: "/sikkim-milk-hrms-view-details",
      newTab: 0,
    },

    {
      id: 6,
      projectTitle: "SABCCO",
      sectors: [1],
      clientLogo: sabccologoMM,
      imageBlurHashString: "LJPZr%t7_3of-;fQM{fQ~qWBayWB",
      siteUrl: "/sabcco-view-details",
      newTab: 0,
    },

    {
      id: 7,
      projectTitle: "Sisco Bank",
      sectors: [1],
      clientLogo: siscobanklogoMM,
      imageBlurHashString: "LLQJl$ay~Wofxuj[ofj[~qj[D%j[",
      siteUrl: "/sisco-bank-view-details",
      newTab: 0,
    },

    {
      id: 8,
      projectTitle: "Rajya Sainik Board",
      sectors: [4],
      clientLogo: rsblogoMM,
      imageBlurHashString: "LhOy^xf7?toLzVjZO?ay~Uj[Ipf6",
      siteUrl: "/rsb-view-details",
      newTab: 0,
    },

    {
      id: 10,
      projectTitle: "Sikkim Investment Summit",
      sectors: [4],
      clientLogo: sislogoMM,
      imageBlurHashString: "LfL}Wsof-:ae?Gfkt7ay~VafRkj[",
      siteUrl: "/sikkim-investment-summit-view-details",
      newTab: 0,
    },

    {
      id: 11,
      projectTitle: "SEED Cell",
      sectors: [5],
      clientLogo: seedlogoMM,
      imageBlurHashString: "LlOWjBf6%hW:t6j[Rjay?wbHngoL",
      siteUrl: "/seed-cell-view-details",
      newTab: 0,
    },

    {
      id: 12,
      projectTitle: "Sikkim Awards",
      sectors: [5],
      clientLogo: sikkimawardslogoMM,
      imageBlurHashString: "L87^h-~S%0M}WXofRlM}0PE4NHoL",
      siteUrl: "https://sikkimawards.com/",
      newTab: 1,
    },

    {
      id: 13,
      projectTitle: "Positude",
      sectors: [5],
      clientLogo: positudelogoMM,
      imageBlurHashString: "LaMQ*Jj[~qWBM{ayfQj[~qayRjof",
      siteUrl: "https://positude.in/",
      newTab: 1,
    },

    {
      id: 15,
      projectTitle: "Panchak Holidays",
      sectors: [2],
      clientLogo: panchaklogoMM,
      imageBlurHashString: "L27nj}ay00WV4nayt7Rj00f7~qt7",
      siteUrl: "https://panchakholidays.com/",
      newTab: 1,
    },

    {
      id: 16,
      projectTitle: "Eagle's Nest Gangtok",
      sectors: [2],
      clientLogo: eaglesnestlogoMM,
      imageBlurHashString: "LCRW0bs:_Nozt7j[ofWB_3fk8_f6",
      siteUrl: "https://eaglesnestgangtok.com/",
      newTab: 1,
    },
  ];

  const onMenuClickHandler = () => {
    setShowMenu(!showMenu);
    setOurServicesSidebarOpen(false);
    setOurProjectsSidebarOpen(false);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  //if we click on any link inside of the toggle, the toggle must close when that particular navlink gets rendered

  //the following nav click handler is for navlinks without submenu
  const onNavClickHandler = () => {
    //setShowMenu(false);
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`open`);
  };

  //the following nav click handler is for navlinks with submenu
  const onNavClickHandlerForCaret = () => {
    const btn = document.getElementById("menu-btn");
    btn.classList.toggle(`close`);
  };

  //options inside of the toogle
  const itemVariants = {
    closed: {
      opacity: 0,
    },
    open: { opacity: 1 },
  };

  const sideVariants = {
    closed: {
      transition: {
        staggerChildren: 0.05,
        staggerDirection: -1,
      },
    },
    open: {
      transition: {
        staggerChildren: 0.1,
        staggerDirection: 1,
      },
    },
  };

  const onOurServicesClickHandler = () => {
    setOurServicesSidebarOpen(true);
  };

  const onOurProjectsClickHandler = () => {
    setOurProjectsSidebarOpen(true);
  };

  return (
    <>
      {/* -------------------------------------------------------MOBILE NAVBAR START----------------------------------------------------------  */}
      <div className="lg:hidden fixed z-50">
        <div
          className={`flex py-4 px-3 w-screen ${
            showMenu
              ? "bg-secondary"
              : "bg-transparent bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-50"
          }`}
        >
          {showMenu ? (
            <>
              <a href="/">
                <img
                  // src={bytesberrylogo_black}
                  // src={isFirstDiv ? bytesberrylogo_white : bytesberrylogo_black}
                  src={bytesberrylogo_white}
                  className="transition-all ease-in-out"
                  alt="Bytesberry Logo"
                  // height={3}
                  width={180}
                />
              </a>
            </>
          ) : (
            <>
              <a href="/">
                <img
                  // src={bytesberrylogo_black}
                  // src={isFirstDiv ? bytesberrylogo_white : bytesberrylogo_black}
                  src={companyLogo}
                  alt="Bytesberry Logo"
                  // height={3}
                  width={180}
                />
              </a>
            </>
          )}
        </div>
        <section className="sticky-top">
          <div className="lg:hidden z-50">
            <span className="sr-only">Open menu</span>

            <button
              id="menu-btn"
              className="cursor-pointer  absolute top-8 left-[85%]  md:top-7 md:left-[90%] "
              onClick={onMenuClickHandler}
            >
              <span className="hamtop"></span>
              <span className="hammiddle"></span>
              <span className="hambottom"></span>
            </button>
          </div>
          <div className="z-50">
            <AnimatePresence>
              {showMenu && (
                <motion.div
                  className="bg-secondary shadow-xl z-20 absolute"
                  initial={{ width: 0, height: "100%" }}
                  animate={{
                    width: "100%",
                    transition: { duration: 0.5 },
                  }}
                  exit={{
                    width: 0,
                    height: "screen",
                    transition: { delay: 0.1, duration: 0.3 },
                  }}
                >
                  <motion.div
                    className="bg-secondary grid grid-cols-1 text-sm"
                    initial="closed"
                    animate="open"
                    exit="closed"
                    variants={sideVariants}
                  >
                    <NavLink to="/">
                      <motion.p
                        variants={itemVariants}
                        className={`font-medium text-xl pt-10 pb-3 px-3 ${
                          location.pathname === "/"
                            ? "text-[#F39174]"
                            : "text-white"
                        }`}
                        onClick={() => {
                          setOpen(false);
                          onNavClickHandler();
                          setShowMenu(false);
                        }}
                      >
                        Home
                      </motion.p>
                    </NavLink>

                    <div>
                      <NavLink to="">
                        <motion.p
                          variants={itemVariants}
                          className={`font-medium text-xl py-3 px-3 flex flex-row items-center justify-between ${
                            location.pathname === "/our-services" ||
                            location.pathname === "/our-services#Service1" ||
                            location.pathname === "/our-services#Service2" ||
                            location.pathname === "/our-services#Service3" ||
                            location.pathname === "/our-services#Service4" ||
                            location.pathname === "/our-services#Service5" ||
                            location.pathname === "/our-services#Service6" ||
                            location.pathname === "/our-services#Service7" ||
                            location.pathname === "/our-services#Service8" ||
                            location.pathname === "/our-services#Service9"
                              ? "text-[#F39174]"
                              : "text-white"
                          }`}
                          onClick={() => {
                            setOpen(false);
                            onNavClickHandlerForCaret();
                            onOurServicesClickHandler();
                            setShowMenu(true);
                          }}
                        >
                          Our Services
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-chevron-right"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
                              />
                            </svg>
                          </>
                        </motion.p>
                      </NavLink>
                      <div
                        className={`transition-all duration-500 ease-in-out flex flex-col absolute z-40 right-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-full lg:w-20 2xl:!w-64 shrink-0 bg-secondary ${
                          ourServicesSidebarOpen
                            ? "translate-x-0"
                            : "translate-x-full opacity-0"
                        }`}
                      >
                        <OurServicesMobile
                          setOurServicesSidebarOpen={setOurServicesSidebarOpen}
                          setShowMenu={setShowMenu}
                          onNavClickHandler={onNavClickHandler}
                        />
                      </div>
                    </div>

                    <NavLink to="/our-projects">
                      <motion.p
                        variants={itemVariants}
                        className={`font-medium text-xl py-3 px-3 ${
                          location.pathname === "/our-projects"
                            ? "text-[#F39174]"
                            : "text-white"
                        }`}
                        onClick={() => {
                          setOpen(false);
                          onNavClickHandler();
                          setShowMenu(false);
                        }}
                      >
                        Our Projects
                      </motion.p>
                    </NavLink>

                    <NavLink to="/contact-us-v2">
                      <motion.p
                        variants={itemVariants}
                        className={`font-medium text-xl pt-3 pb-[100vh] overflow-y-hidden px-3 ${
                          location.pathname === "/contact-us-v2"
                            ? "text-[#F39174]"
                            : "text-white"
                        }`}
                        onClick={() => {
                          setOpen(false);
                          onNavClickHandler();
                          setShowMenu(false);
                        }}
                      >
                        Contact Us
                      </motion.p>
                    </NavLink>

                    {/* You may notice that I have given a pb-[in vh] for the Contact Us Navlink.
                    This is to have the same feature provided by the h-screen Tailwind class. On integrating the 
                    glasss effect for mobile navbar, we cannot apply h-screen as the navbar has the classes fixed z-50 defined,
                    when h-screen is added the div covers the entire page, hence disabling other elements with click properties.
                    The clickable links no longer work because the div with h-screen is an overlay over them. So, to keep the h-screen
                    functionality intact, I have implicitly given a padding bottom to the last navlink. Now, we can have both
                    the sticky navbar with glass effect as well as h-screen together. */}
                  </motion.div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </section>
      </div>
      {/* ---------------------------------------------------------MOBILE NAVBAR END----------------------------------------------------------  */}

      {/* -------------------------------------------------------DESKTOP NAVBAR START---------------------------------------------------------  */}

      <section
        className={`fixed z-50 w-full mx-auto hidden lg:block ${
          isNavBarTransparent ? "bg-transparent" : "bg-[#0E0E0E] bg-opacity-90"
        }`}
      >
        <section className="relative w-[90%] mx-auto p-2">
          <div
            className={`absolute left-0 z-20 ${
              isNavBarTransparent
                ? "scale-105 transition-all ease-in-out duration-500 "
                : "scale-100 -translate-x-2 transition-all ease-in-out duration-500"
            }`}
          >
            <a href="/">
              <img
                src={bytesberrylogo_white}
                alt="Bytesberry Logo"
                width={180}
              />
            </a>
          </div>

          <div
            className={`lg:flex justify-center hidden  ${
              isNavBarTransparent ? navbarAnimationTrue : navbarAnimationFalse
            }`}
          >
            <div className="sticky-top">
              <nav className="hidden lg:flex lg:items-center py-2 text-xs 2xl:text-base gap-3">
                <NavLink to="/">
                  <div className="flex items-center justify-center text-white nav-link hover:text-red-400 px-4">
                    <p className="font-medium text-sm xl:text-lg">Home</p>
                  </div>
                </NavLink>

                {/* OUR SERVICES start */}
                {/* <div
                  className="flex items-center justify-center text-white megaMenuANI
                               cursor-pointer  hover:text-red-400 px-4"
                  onMouseEnter={() => setOpenServiceMegaMenu(true)}
                  onMouseLeave={() => setOpenServiceMegaMenu(false)}
                >
                  <NavLink to="/our-services">
                    <p
                      className="font-medium text-sm xl:text-lg pr-1 z-40  "
                      onClick={() => {
                        setOpenServiceMegaMenu(false);
                      }}
                    >
                      Our Services
                    </p>
                  </NavLink>
                  <span>
                    <svg
                      className="dd_flyer fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </span>

                  <div className="megaMenuChildANI opacity-10 absolute w-fit  top-4 left-9   ">
                    <OurServices
                      isNavBarTransparent={isNavBarTransparent}
                      setOpenServiceMegaMenu={setOpenServiceMegaMenu}
                      openServiceMegaMenu={openServiceMegaMenu}
                    />
                  </div>
                </div> */}
                {/* OUR SERVICES end */}

                {/* OUR PROJECTS start */}
                {/* <div
                  className="flex items-center justify-center text-white nav-link  megaMenuANI
                 px-4 menuwithdd cursor-pointer  hover:text-red-400 "
                  onMouseOver={() => setOpenProjectMegaMenu(true)}
                  onMouseLeave={() => setOpenProjectMegaMenu(false)}
                >
                  <NavLink to="/our-projects">
                    <p
                      className=" font-medium text-sm xl:text-lg pr-1"
                      onClick={() => setOpenProjectMegaMenu(false)}
                    >
                      Our Projects
                    </p>
                  </NavLink>

                  <span>
                    <svg
                      className="dd_flyer fill-current h-4 w-4 "
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />{" "}
                    </svg>
                  </span>

                  <div className="megaMenuChildANI opacity-0 absolute w-fit top-4 left-9">
                    <OurProjects
                      projectsArr={projectsArr}
                      projectsSectorsArr={projectsSectorsArr}
                      isNavBarTransparent={isNavBarTransparent}
                      setOpenProjectMegaMenu={setOpenProjectMegaMenu}
                      openProjectMegaMenu={openProjectMegaMenu}
                    />
                  </div>
                </div> */}
                {/* OUR PROJECTS end */}

                <div>
                  <ShiftingDropDown />
                </div>

                <NavLink to="/contact-us-v2">
                  <div className="flex items-center justify-center text-white nav-link hover:text-red-400 px-4">
                    <p className="font-medium text-sm xl:text-lg">Contact Us</p>
                  </div>
                </NavLink>
              </nav>
            </div>
          </div>

          <div className="cursor-pointer text-white font-bold text-center absolute top-3 right-0">
            <a href="https://app.bytesberry.com/" target="_blank">
              <div className="text-sm py-1 px-6 border border-primary rounded-md bg-primary">
                Login
              </div>
            </a>
          </div>
        </section>
      </section>

      {/* -------------------------------------------------------DESKTOP NAVBAR END-----------------------------------------------------------  */}
    </>
  );
}

const NavbarCaret = ({ state }) => {
  return (
    <svg
      className={
        state === true
          ? "transform rotate-180  h-5 w-5 text-green group-hover:text-green-200 transition ease-out duration-200"
          : "transform rotate-0 transition ease-out duration-200  h-5 w-5 text-green group-hover:text-green-200"
      }
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

import React from "react";

const ServicesLinksHome = ({ service_name }) => {
  return (
    <>
      <div className="flex flex-row items-center gap-3 cursor-pointer my-3 lg:my-1.5 md:pl-6 lg:pl-0">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="38"
            height="30"
            fill="currentColor"
            class="bi bi-arrow-right-circle-fill"
            viewBox="0 0 16 16"
            color="orange"
          >
            <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
          </svg>
        </div>
        <div className="text-[#252744] mb-1 text-base lg:text-base 2xl:text-xl font-medium hover:underline">
          {service_name}
        </div>
      </div>
    </>
  );
};

export default ServicesLinksHome;

import React from "react";

export default function BlogCard({
  blogImage,
  TimeToRead,
  blogHeading,
  blogDescription,
  blog,
  Date,
  setActiveBlogDetails,
  setIsDetailsActive,
}) {
  const onClickReadMore = (blog) => {
    // console.log("Active-blog", blog);
    setIsDetailsActive(true);
    setActiveBlogDetails(blog);
  };

  return (
    <div className="my-8">
      <div class="overflow-hidden shadow-lg rounded-lg  w-80 md:w-80 cursor-pointer m-auto bg-slate-50 ">
        <div class="w-full block h-full">
          <img
            alt="blog photo"
            src={blogImage}
            className="w-full object-cover h-24 "
          />
          <div class="w-full p-4">
            <div className="flex justify-start">
              <span class=" py-1  text-gray-600  text-sm font-medium">
                {TimeToRead} mins read
              </span>
              <p className="px-2 text-2xl align-middle relative bottom-2">.</p>

              <span class="mr-2 py-1 px-2 text-gray-600  text-sm font-medium">
                {Date}
              </span>
            </div>

            <p class="text-gray-800 dark:text-white text-xl font-semibold mb-2 pt-3">
              {blogHeading}
            </p>
            <p class="text-gray-600 dark:text-gray-600 font-medium text-sm">
              {blogDescription}
            </p>

            <div className="py-4">
              <button
                className="text-gray-800 font-medium   bg-tertiary   rounded-lg px-4 py-1 text-sm"
                onClick={() => onClickReadMore(blog)}
              >
                Read more
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
